import useForm from "../../../form/useForm";
import React, {memo} from "react";
import ValidatedInput from "../../../form/Components/ValidatedInput/ValidatedInput";
import ValidatedSubmit from "../../../form/Components/ValidatedSubmit/ValidatedSubmit";
import {useTranslation} from "react-i18next";
import ContentBox from "../../../../Components/ContentBox/ContentBox";
import Section from "../../../form/Components/Section/Section";
import {ProcedureRecord, ProcedureSchema} from "../../Data/Procedure";
import ValidatedRichTextEditor from "../../../form/Components/ValidatedRichTextEditor/ValidatedRichTextEditor";
import ValidatedRadioGroup from "../../../form/Components/ValidatedRadio/ValidatedRadioGroup";
import ValidatedRadio from "../../../form/Components/ValidatedRadio/ValidatedRadio";
import debug from "../../../debug";

export default memo(({id, record, path, view, api}) => {
    const {t} = useTranslation("procedures");

    const form = useForm({
        recordType: ProcedureRecord,
        recordSchema: ProcedureSchema,
        namespace: 'procedures',
        record
    });

    debug("RENDER", "useForm", () => [" -> Form_Of_Procedures", id, record, path, view]);

    return (
        <ContentBox path={path} form={form}>

            <Section title={t("procedure")}>
                <ValidatedInput name="name"/>
                <ValidatedRadioGroup name="type">
                    <ValidatedRadio name="type" value="ACP"/>
                    <ValidatedRadio name="type" value="PEP"/>
                </ValidatedRadioGroup>
                <ValidatedRichTextEditor name={"procedure"} showLabel={t("procedure")} codeEditor={true}/>
                <ValidatedSubmit view={view} api={api}/>
            </Section>
        </ContentBox>
    );
})

// procedure:
//     - step:
// when:
//     one-of:
// - principal-is: 'something'
// then:
//     grant: [ 'read' ]
