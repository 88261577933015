import React from "react";
import List from "./List";
import {useRoutes} from "hookrouter";
import {addFacility, removeFacility, updateFacility, useFacility} from "../../Api/facilities";
import FacilityUnits from "./FacilityUnits/FacilityUnits";
import {FacilityRecord, FacilitySchema} from "../../Data/facility";
import Form from "./Form";
import Crud from "../../Lib/Crud";

export default () => useRoutes(subRoutes);

const subRoutes = {
    "/": () => <Crud config={crudConfig} view="list"/>,
    "/add": () => <Crud config={crudConfig} view="add"/>,
    "/:id/edit": ({id}) => <Crud config={crudConfig} view="edit" id={id}/>,
    "/:id/remove": ({id}) => <Crud config={crudConfig} view="remove" id={id}/>,
    "/:id/facility-units*": ({id}) => <Crud config={crudConfig} OptComponent={FacilityUnits}
                                            optUrlSectionNLabel='facility-units' view="other" id={id}/>,
};

const crudConfig = {
    compName: 'Facilities',
    namespace: 'facilities',
    url: '/facilities',
    labelField: 'name',
    Record: FacilityRecord,
    Schema: FacilitySchema,
    ListComponent: List,
    FormComponent: Form,
    api: {
        use: useFacility,
        add: addFacility,
        edit: updateFacility,
        remove: removeFacility
    }
};
