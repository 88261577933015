import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../../Lib/form/useForm";
import ContentBox from "../../../Components/ContentBox/ContentBox";
import Section from "../../../Lib/form/Components/Section/Section";
import ValidatedSubmit from "../../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import debug from "../../../Lib/debug";
import ValidatedSelect from "../../../Lib/form/Components/ValidatedSelect/ValidatedSelect";
import {useFacilityUnits} from "../../../Api/facility-units";
import {TaskFacilityUnitRecord, TaskFacilityUnitSchema} from "../../../Data/task-facility-unit";
import ValidatedRadioGroup from "../../../Lib/form/Components/ValidatedCheckbox/ValidatedCheckboxGroup";
import ValidatedCheckbox from "../../../Lib/form/Components/ValidatedCheckbox/ValidatedCheckbox";

export default memo(({id, record, path, view, api}) => {
    const {t} = useTranslation("taskFacilityUnits");

    const form = useForm({
        recordType: TaskFacilityUnitRecord,
        recordSchema: TaskFacilityUnitSchema,
        namespace: 'taskFacilityUnits',
        record
    });

    debug("RENDER", "useForm", () => [" -> Form_Of_Task_Facility_Unit", view, id, path, record]);

    return (
        <ContentBox path={path} form={form}>

            <Section title={t('taskFacilityUnit')}>
                {/*<ValidatedSelect name="task" api={useTasks}/>*/}
                <ValidatedSelect name="facilityUnit" api={useFacilityUnits}/>
                <ValidatedRadioGroup name="finished">
                    <ValidatedCheckbox name='finished'/>
                </ValidatedRadioGroup>
                <ValidatedRadioGroup name="approved">
                    <ValidatedCheckbox name='approved'/>
                </ValidatedRadioGroup>
            </Section>

            <ValidatedSubmit view={view} api={api}/>

        </ContentBox>
    );
})