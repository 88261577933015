import baseFetch, {useFetchMany, useFetchOne} from "../../baseFetch";
import {PrincipalRecord, PrincipalSchema} from "../Data/Principal";


export function usePrincipals(filter) {
    return useFetchMany('/principals', filter, PrincipalRecord);
}

export function usePrincipal(id) {
    return useFetchOne('/principals', id, PrincipalRecord, PrincipalSchema);
}

export function addPrincipal(principal, onSuccess, onError) {
    baseFetch('POST', '/principals', principal,
        data => {
            if (onSuccess) onSuccess(new PrincipalRecord(data))
        },
        onError
    );
}

export function getPrincipals(filter, onSuccess, onError) {
    baseFetch('GET', '/principals', filter,
        data => {
            if (onSuccess) onSuccess(data.map(r => new PrincipalRecord(r)))
        },
        onError
    );
}

export function getPrincipal(id, onSuccess, onError) {
    baseFetch('GET', '/principals/' + id, undefined,
        data => {
            if (onSuccess) onSuccess(new PrincipalRecord(data))
        },
        onError
    );
}

export function updatePrincipal(principal, onSuccess, onError) {
    baseFetch('PATCH', '/principals/' + principal.get('id'), principal, onSuccess, onError);
}

export function removePrincipal(principal, onSuccess, onError) {
    baseFetch('DELETE', '/principals/' + principal.get('id'), undefined, onSuccess, onError);
}
