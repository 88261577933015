export default {
  "mobileClients": "Mobil kliensek",
  "mobileClient": "Mobil kliens",
  "mobile": "Mobiltelefon",
  "serial": "Széria",
  "serial-invalid": "A széria érvénytelen. Minimum 2 és maximum 100 karakter lehet.",
  "user": "Felhasználó",
  "add": "Mobil kliens hozzáadása",
  "back": "Vissza a listához",
  "save": "Mobil kliens mentése",
  "action": "Műveletek",
  "id": "Azonosító",
  "userName": "Felhasználó",
};