export const types = {
    'INIT_OPTIONS': 'INIT_OPTIONS',
    'LOAD_OPTIONS_SUCCESS_SELECT': 'LOAD_OPTIONS_SUCCESS_SELECT',
    'LOAD_OPTIONS_SUCCESS_TEXT': 'LOAD_OPTIONS_SUCCESS_TEXT',
    'ADD_CONDITION_SELECT': 'ADD_CONDITION_SELECT',
    'ADD_MULTIPLE_CONDITION_SELECT': 'ADD_MULTIPLE_CONDITION_SELECT',
    'ADD_CONDITION_TEXT': 'ADD_CONDITION_TEXT',
    'ADD_MULTIPLE_CONDITION_TEXT': 'ADD_MULTIPLE_TEXT_SELECT',
    'REMOVE_CONDITION': 'REMOVE_CONDITION',
    'RESET_CONDITIONS': 'RESET_CONDITIONS',
};

export const actions = {

    initOptions: (data) => {
        return {
            type: 'INIT_OPTIONS',
            data: data
        }
    },
    loadOptionsSuccessSelect: (optionName, data, urlData) => {
        return {
            type: 'LOAD_OPTIONS_SUCCESS_SELECT',
            optionName: optionName,
            data: data,
            urlData: urlData,
        }
    },
    loadOptionsSuccessText: (optionName, data, urlData) => {
        return {
            type: 'LOAD_OPTIONS_SUCCESS_TEXT',
            optionName: optionName,
            data: data,
            urlData: urlData,
        }
    },
    addConditionSelect: (optionName, currentTargetId) => {
        return {
            type: 'ADD_CONDITION_SELECT',
            optionName: optionName,
            currentTargetId: currentTargetId,
        }
    },
    addConditionText: (optionName, text) => {
        return {
            type: 'ADD_CONDITION_TEXT',
            optionName: optionName,
            text: text,
        }
    },
    removeCondition: (optionName, currentTargetId) => {
        return {
            type: 'REMOVE_CONDITION',
            optionName: optionName,
            currentTargetId: currentTargetId
        }
    },
    resetConditions: () => {
        return {
            type: 'RESET_CONDITIONS',
        }
    }
};