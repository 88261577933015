export default {
  "inventoryArea": "Leltárkörzet",
  "inventoryAreas": "Leltárkörzetek",
  "add": "Leltárkörzet hozzáadaása",
  "edit": "Leltárkörzet szerkesztése",
  "remove": "Leltárkörzet törlése",
  "name" : "Név",
  "name-invalid": "Érvénytelen mező, legalább 2, legfeljebb 120 karakternek kell lennie.",
  "facility" : "Létesítmény",
  "person" : "Fiók",
  "status": "Státusz",
  "translStatus": "Státusz",
  "ACTIVE": "Aktív",
  "INACTIVE": "Inaktív",
  "id": "Azonosító",
  "action": "Műveletek",
  "back": "Vissza a listához",
  "save": "Leltárkörzet mentése",
  "erpId": "Forrás.net azonosító",
  "entries": "Bejegyzések",
};