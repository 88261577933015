import makeStyles from "@material-ui/styles/makeStyles";
import React from "react";
import Select from "@material-ui/core/Select";
import {useInventoryAreas} from "../../../Api/inventory-areas";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import {useTranslation} from "react-i18next";
import {dangerColor, successColor} from "../../../Lib/material-kit/assets/jss/material-kit-pro-react";


const useStyles = makeStyles(theme => ({
    container: {
        marginBottom: '1rem',
        marginTop: '1rem',
        display: "flex",
        flexDirection: "row",
        alignItems: 'center'
    },
    labelRootError: {
        color: dangerColor[0] + " !important"
    },
    labelRootSuccess: {
        color: successColor[0] + " !important"
    },
}));

export default function SelectedInventoryArea(props) {

    const classes = useStyles();
    const {t} = useTranslation();

    const inventoryAreas = useInventoryAreas().data;

    return (
        <div className={classes.container} style={{background: successColor[0]}}>
            <InputLabel style={{padding: "0.3rem", color: "white"}}
                        shrink={true}>{t("inventoryItems:facility")}</InputLabel>       { /*FIXME Currently, the inventory area is the same as the (facility) => 'létesítmény'!!!!!!!!!! */}
            <Select style={{minWidth: '15rem', color: "white"}}
                // disabled={!!parent}
                    autoWidth={true}
                    value={props.area}
                    onChange={(event) => props.inventoryArea(Number(event.target.value))}>
                <MenuItem
                    classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                    }}
                    key={"0000"}
                    value={0}>
                    {t("inventoryItems:**unknown**")}
                </MenuItem>
                {inventoryAreas.map(area =>
                    <MenuItem
                        classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                        }}
                        key={area.id}
                        value={area.id}>
                        {area.name}
                    </MenuItem>)}
            </Select>
        </div>
    );
}