import baseFetch, {useFetchMany, useFetchOne} from "../Lib/baseFetch";
import {InventoryTicketRecord, InventoryTicketSchema, TicketDTOForListRecord} from "../Data/inventory-ticket";

export function useInventoryTickets(parent) {
    return useFetchMany('/tasks/' + parent + '/find-all-ticket-for-task', undefined, TicketDTOForListRecord);
}

export function useForeignItems(parent) {
    return useFetchMany('/tasks/' + parent + '/found-inventory-tickets', undefined, TicketDTOForListRecord);
}

export function useInventoryTicket(parent, id) {
    return useFetchOne('/tasks/' + parent + '/inventory-tickets', id, InventoryTicketRecord, InventoryTicketSchema);
}

export function addInventoryTicket(inventoryTicket, onSuccess, onError) {
    baseFetch('POST', '/tasks/' + inventoryTicket.get('task') + '/inventory-tickets', inventoryTicket,
        data => {
            if (onSuccess) onSuccess(new InventoryTicketRecord(data))
        },
        onError
    );
}

export function updateInventoryTicket(inventoryTicket, onSuccess, onError) {
    baseFetch('PATCH', '/tasks/' + inventoryTicket.get('task') + '/inventory-tickets/' + inventoryTicket.get('id'), inventoryTicket, onSuccess, onError);
}

export function removeInventoryTicket(inventoryTicket, onSuccess, onError) {
    baseFetch('DELETE', '/tasks/' + inventoryTicket.get('task') + '/inventory-tickets/' + inventoryTicket.get('id'), undefined, onSuccess, onError);
}
