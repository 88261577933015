import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import Accounts from "../Views/Account/i18n";
import Facilities from "../Views/Facilities/i18n"
import FacilityTypes from "../Views/MasterData/FacilityTypes/i18n";
import FacilityUnits from "../Views/Facilities/FacilityUnits/i18n";
import Tags from "../Views/MasterData/RfidTags/i18n";
import MobileClients from "../Views/MasterData/MobileClients/i18n";
import InventoryItems from "../Views/Inventory/InventoryItems/i18n";
import InventoryAreas from "../Views/Inventory/InventoryAreas/i18n";
import InventoryItemGroups from "../Views/Inventory/InventoryItemGroups/i18n";
import Tasks from "../Views/Tasks/i18n";
import ItemOwners from "../Views/MasterData/ItemOwners/i18n";
import {RoleHu} from "../Lib/auth/Views/Roles/i18n";
import {MembershipHu} from "../Lib/auth/Views/Groups/Memberships/i18n";
import {PrincipalHu} from "../Lib/auth/Views/Principals/i18n";
import {GroupHu} from "../Lib/auth/Views/Groups/i18n";
import {ProcedureHu} from "../Lib/auth/Views/Procedures/i18n";
import {GrantHu} from "../Lib/auth/Views/Roles/Grants/i18n";
import {CreationRuleHu} from "../Lib/auth/Views/CreationRules/i18n";
import ItemUsers from "../Views/MasterData/ItemUsers/i18n";
import InventoryItemGroupEntries from "../Views/Inventory/InventoryItemGroups/InventoryItemGroupEntries/i18n";
import inventoryTickets from "../Views/Tasks/InventoryTickets/i18n";
import RfidTagHistoryEntries from "../Views/MasterData/RfidTags/RfidTagHistoryEntries/i18n";
import InventoryAreaEntries from "../Views/Inventory/InventoryAreas/InventoryAreaEntries/i18n";
import InventoryItemHistoryEntries from "../Views/Inventory/InventoryItems/InventoryItemHistoryEntires/i18n";
import FacilityAccess from "../Views/Account/FacilityAccess/i18n";
import NskForrasJobs from "../Views/NskForrasJob/i18n";
import ItemCategories from "../Views/MasterData/ItemCategories/i18n";
import TaskFacilityUnits from "../Views/Tasks/TaskFacilityUnits/i18n";
import {FileUploadHu} from "../Lib/form/Components/ValidatedFileUpload/i18n";
import ViewsList from "../Views/ViewsList/i18n";
import SystemSettings from "../Lib/auth/Views/SystemSettings/i18n";
import AboutProject from "../Views/Dashboard/i18n";
import Transfers from "../Views/Transfers/i18n";
import NskForrasJobRfids from "../Views/NskForrasJobRfid/i18n";

const resources = {
    hu: {
        general: {
            "actions": "Műveletek",
            "name": "Név",
            "status": "Státusz",
            "description": "Leírás",
            "identifier": "Azonosító",
            "save.success": "Sikeres mentés.",
            "save.error": "Nem sikerült a mentés!",
            "edit": "Szerkeszt",
            "editShortHand": "Szerk.",
            "delete": "Törlés",
            "alreadyExistsException": "Ez a rekord már létezik az adatbázisban.",
            "search": "Keresés",
            "yes": 'Igen',
            "no": "Nem",
            "areYouSure": "Az oldal nem mentett adatokat tartalmazhat. Biztosan abba szeretné hagyni?",
            'back': "Mégse",
            "changeImage": "Kép módosítása",
            "textOnDashboard": "Nemzeti Sportközpontok - RFID leltározó rendszer",
            "pictures": "Képek",
            "close": "Bezárás",
            "details": "Részletek"
        },

        header: {
            "login": "Belépés",
            "logout": "Kijelentkezés",
            "dashboard": "Kezdőlap",
            "landing": "Kezdőlap",
            "account": "Fiók",
            "inventory": "Leltár",
            "masterDataManagement": "Törzsadatok",
            "administration": "Adminisztráció",
            "devTool": "Fejlesztői eszközök",
        },

        catchException: {
            'not-acceptable': 'A müvelet sikertelen - a szerver nem tud választ előállítani',
            'unauthorized': 'Nincs jogosultsága a művelet elvégzéséhez.',
            'expired-session': 'Lejárt a munkamenet, kérjük lépjen be újra a Belépés menüpontban.',
            "already-exists": "Ez a rekord már létezik.",
            'bad-request': 'A kérés nem végrehajtható, mert rossz a rekord szintaxisa',
            'not-found': ' A kért rekord nem található.',
            'gateway-timeout': 'A müvelet sikertelen - a szerver nem kapott választ az eredeti szervertől időben.',
            "hasNoSiteMemberRole": "A web-es alkalmazást csak ’site-member’ (web-es felhasználó) jogosultsággal lehet használni!"
        },

        fileUpload: FileUploadHu,

        taskFacilityUnits: TaskFacilityUnits,

        itemCategories: ItemCategories,

        facilitiesAccesses: FacilityAccess,

        accounts: Accounts,

        facilityTypes: FacilityTypes,

        tags: Tags,

        tagHistoryEntries: RfidTagHistoryEntries,

        mobileClients: MobileClients,

        inventoryAreas: InventoryAreas,

        facilities: Facilities,

        viewslist: ViewsList,

        inventoryItems: InventoryItems,

        nskForrasJobs: NskForrasJobs,

        tasks: Tasks,

        facilityUnits: FacilityUnits,

        inventoryItemGroups: InventoryItemGroups,

        inventoryItemGroupEntries: InventoryItemGroupEntries,

        itemOwners: ItemOwners,

        roles: RoleHu,

        memberships: MembershipHu,

        principals: PrincipalHu,

        groups: GroupHu,

        procedures: ProcedureHu,

        grants: GrantHu,

        creationRules: CreationRuleHu,

        itemUsers: ItemUsers,

        inventoryTickets: inventoryTickets,

        inventoryAreaEntries: InventoryAreaEntries,

        inventoryItemHistoryEntries: InventoryItemHistoryEntries,

        systemSettings: SystemSettings,

        aboutProject: AboutProject,

        transfers: Transfers,

        nskForrasJobRfids: NskForrasJobRfids,

        translation: {
            "Dashboard": "Kezdőlap",
            "logout": "Kijelentkezés",
            "Notification": "Értesítések",
            "Profile": "Profil",
            "Settings": "Beállítások",
            "Logout": "Kilépés",
            "logoText": "NSK Leltár",
            "BaseData": "Alapadatok",
            "save.success": "Sikeres mentés!",
            "save.error": "Hiba! Nem sikerült lementeni a rekordot.",
            "loading": "betöltés",
            "communication.error": "Kommunikációs hiba, kérjük probálkozz később.",
            "Login": "Belépés",
            "Contact": "Kapcsolat",
            "erpId": "Forrás.net azonosító",
        },

        search: {
            "search": "Keresés",
            "inventoryItems": "Eszközök",
            "inventoryAreas": "Leltár körzetek",
            "itemUsers": "Eszköz használok",
            "facilityUnits": "Létesítmény egységek",
            "startSearch": "Keresés indítása",
            "searchConditions": "Keresési feltételek",
            "selectedConditions": "Kiválasztott feltételek",
            "resetConditions": "Feltételek törlése",
            "entries": "Bejegyzések"
        },

        session: {
            "expired.message": "Lejárt a munkamenet, kérjük lépjen be újra a Belépés menüpontban.",
            "expired.title": "Lejárt munkamenet"
        },

        blindReads: {
            "blindReads": "Vak olvasások",
            "rfidValue": "RFID #"
        }
    },

    en: {
        general: {
            "edit": "Szerkesztés"
        }
    }

};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "hu",

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;