export function throwsExceptionMessage(error) {

    switch (error.status) {
        case 400:
            return 'bad-request';
        case 401:
            return 'unauthorized';
        case 403:
            return 'unauthorized';
        case 404:
            return 'not-found';
        case 406:
            return 'not-acceptable';
        case 409:
            return 'expired-session';
        case 410:
            return 'expired-session';
        case 417:
            return 'expired-session';
        case 422:
            return 'already-exists';
        case 500:
            return 'not-acceptable';
        case 504:
            return 'gateway-timeout';
        default:
            return error.status ? error.status.toString() : "";
    }

}