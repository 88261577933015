import {Record} from 'immutable';
import {boolean, number, object, string} from "yup";

export const GroupRecord = Record({
    id: 0,
    name: '',
    description: '',
    memberCount: 0,
    locked: false

});

export const GroupSchema = object().shape({
    id: number().required().min(0).default(0),
    name: string().required().min(1).max(100).default(''),
    description: string().min(1).max(10000).default(''),
    memberCount: number().nullable().default(0),
    locked: boolean().default(false),
});