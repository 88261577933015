
const searchBuilderStyle = {
    sbWrapper: {
        backgroundColor: 'rgba(0,0,0,0)',
        borderColor: 'rgba(0,0,0,0)',
        boxShadow: 'none',
    },
    sbContainer: {
        marginTop: 0,
        marginBottom: '.3125rem',
    },
    conditionsCardBody: {
        minHeight: '7rem',
    },
    conditionsCardActions: {
        justifyContent: 'space-between'
    },
    sbChip: {
        margin: 8,
        background:'linear-gradient(60deg, #26c6da, #00acc1)',
    }
};

export default searchBuilderStyle;