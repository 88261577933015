export const RoleHu = {
    "role": "Jogosultság",
    "roles": "Jogosultságok",
    "id": "Id",
    "name": "Név",
    "name-invalid": "Érvénytelen mező, legalább 1, legfeljebb 100 karakternek kell lennie.",
    "grantCount": "Jogosultak száma",
    "description": "Leírás",
    "description-invalid": "Érvénytelen mező, legalább 1, legfeljebb 10000 karakternek kell lennie.",
    "locked": "Lezárt",
    "action": "Művelet",
    "back": "Vissza a listához",
    "save": "Jogosultság mentése",
    "add": "Jogosultság hozzáadása",
    "grants": "Jogosultsággal rendelkezők",
    "fullName": "Név",
    "toAddHelpText": "Jogosultság hozzáadása a felhasználóhoz!",
    "toRemoveHelpText": "Jogosultság elvétele a felhasználótól!",
    "successfulCreation": "Sikeresen hozzá lett adva a jogosultság a felhasználóhoz.",
    "successfulRemoval": "Sikeresen törölve lett a jogosultság a felhasználótól.",
    "site-member": "Web-es hozzáférés",
    "business-admin": "Adminisztrátor",
    "security-officer": "Simplexion",
    "developer": "Fejlesztő",
    "task-admin": "Leltár felelős",
    "task-responsible": "Leltározó",
    "task-authenticator": "Hitelesítő"
};

export const RoleEn = {
    "role": "Role",
    "roles": "Roles",
    "id": "Id",
    "name": "Name",
    "numberOfGranted": "Number of granted",
    "description": "Description",
    "locked": "Locked",
    "actions": "Actions",
    "action": "Action",
    "fullName": "Full name",

};