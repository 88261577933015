import React from "react";
import {useRoutes} from 'hookrouter';
import List from "./List";
import {addMembership, removeMembership, useMembership} from "../../../Api/groups";
import Crud from "../../../../Crud";
import {MembershipRecord, MembershipSchema} from "../../../Data/Membership";
import Form from "./Form";


export default (props) => useRoutes(subRoutes)(props);

const subRoutes = {
    "/": () => (props) => <Crud config={crudConfig(props)} view="list" {...props}/>,
    "/add": () => (props) => <Crud config={crudConfig(props)} view="add" {...props}/>,
    "/:id/remove": ({id}) => (props) => <Crud config={crudConfig(props)} view="remove" id={id} {...props}/>
};

const crudConfig = (props) => ({

    compName: 'Memberships',
    namespace: 'memberships',
    Record: MembershipRecord,
    Schema: MembershipSchema,
    api: {
        use: useMembership,
        add: addMembership,
        remove: removeMembership
    },
    url: '/admin/groups/' + props.parentId + '/memberships',
    labelField: 'principalName',
    ListComponent: List,
    FormComponent: Form,
    setOptRecField: "groupId",
    parentId: props.parentId,

});


