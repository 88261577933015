import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../../Lib/form/useForm";
import ContentBox from "../../../Components/ContentBox/ContentBox";
import Section from "../../../Lib/form/Components/Section/Section";
import ValidatedSubmit from "../../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import debug from "../../../Lib/debug"
import {useFacilities} from "../../../Api/facilities";
import ValidatedCheckbox from "../../../Lib/form/Components/ValidatedCheckbox/ValidatedCheckbox";
import {FacilityAccessRecord, FacilityAccessSchema} from "../../../Data/facility-access";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    container: {
        height: "50vh",
        overflow: 'auto',
        width: '100%',
        display: 'grid',
        justifyContent: "center",
        alignItems: "center",
        gridTemplateColumns: 'repeat(1, 1fr)',
        gridTemplateRows: "auto",
        gridAutoColumns: 'auto',
        gridRowGap: '.5em',
        gridColumnGap: '1em',
        gridArea: 'auto',
        "@media (min-width: 500px)": {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        "@media (min-width: 850px)": {
            gridTemplateColumns: 'repeat(3, 1fr)',
        },
        "@media (min-width: 1200px)": {
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        "@media (min-width: 1600px)": {
            gridTemplateColumns: 'repeat(5, 1fr)',
        },
        "@media (min-height: 700px)": {
            height: "70vh"
        },
    },

}));

export default memo(({id, record, path, view, api}) => {
    record.merge({"accountId": parseInt(id, 10)});
    const {t} = useTranslation("facilitiesAccesses");

    const facilities = useFacilities().data;
    const classes = useStyles();

    const form = useForm({
        recordType: FacilityAccessRecord,
        recordSchema: FacilityAccessSchema,
        namespace: 'facilitiesAccesses',
        record
    });

    debug("RENDER", "useForm", () => [" -> Form_Of_facility_Access", view, id, path, record]);


    return (
        <ContentBox path={path} form={form} disabled>

            <Section title={t('listOfTheFacilities')}>
                <div className={classes.container}>
                    {facilities.map(f =>
                        <div style={{padding: '0.5rem'}}>
                                <ValidatedCheckbox name="facilities" key={f.id} value={f.id}
                                                   label={f.name} multi/>
                        </div>
                    )}
                </div>
            </Section>

            <ValidatedSubmit view={view} api={api}/>

        </ContentBox>
    );
});