import React, {memo, useState} from "react";
import {useGrants} from "../../../Api/roles";
import ContentBox from "../../../../../Components/ContentBox/ContentBox";
import {SimplexionTable} from "../../../../simplexion-table/SimplexionTable";
import {baseTableStyles} from "../../../../simplexion-table/baseStyle";
import {makeStyles} from "@material-ui/core";
import {customActions} from "../../../../simplexion-table/templates/action-buttons";
import {makeTableStyles} from "../../../../../Components/Table/tableStyles";
import {updatableCheckBoxStyle} from "../../../../simplexion-table/templates/checkbox-input";
import {inputFieldStyles} from "../../../../simplexion-table/templates/text-input";
import debug from "../../../../debug";
import {FilterField} from "../../../../../Components/Table/FilterField";
import LinearProgress from "@material-ui/core/LinearProgress";

const useTableStyles = makeStyles(makeTableStyles(baseTableStyles, updatableCheckBoxStyle, inputFieldStyles));

const List = memo((props) => {

    debug("RENDER", "useForm", () => [" -> List_Of_Role_Grants", props]);

    const {path, parentId, record} = props;

    const tableClasses = useTableStyles();

    if (!path[2].url.includes("/role-name=" + record.name.toUpperCase())) {
        path[2].url = path[2].url + "/role-name=" + record.name.toUpperCase()
    }

    const [state, setState] = useState('');

    const {loadingStatus, data} = parentId ? useGrants(parentId) : [];

    const handleFilter = val => setState(val);

    const tableConfig = {
        id: 'role-grants-table',
        nameSpace: 'grants',
        stickyHeader: true,
        sortable: true,
        styles: tableClasses,
        columns: [
            {
                accessor: 'id',
            },
            {
                accessor: 'principalName',
            },
            {
                accessor: 'fullName',
            },
            {
                accessor: 'emailAddress',
            },
            {
                accessor: 'action',
                renderer: (...args) => customActions(false, true, path[2].url + args[1].roleName.toUpperCase() + "/", ...args),
                sortable: false,
            },
        ],
    };

    return (
        <ContentBox path={path} add={path[2].url + "/" + "add"}>
            <FilterField handler={handleFilter}/>
            {loadingStatus > 0 ? <LinearProgress/> : null}
            <SimplexionTable tableConfig={tableConfig} data={data} loadingStatus={loadingStatus} filterValue={state}/>
        </ContentBox>
    );
});

export default List;