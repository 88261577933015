import {Record} from 'immutable';
import {mixed, number, object, string} from "yup";
import {RfidTagStatus} from "./RfidTagHistoryType";

export const RfidTagHistoryEntryRecord = Record({
    id: 0,
    tag: 0,
    status: 'UNASSIGNED',
    task: 0,
    tagSerial: "",
    taskName: "",
    inventoryItem: 0,
    inventoryItemName: "",
    facilityUnit: 0,
    facilityUnitName: "",
    translStatus: "",
    notes: ""

});


export const RfidTagHistoryEntrySchema = object().shape({
    id: number().required().min(0).default(0),
    tag: number().required().min(1).default(0),
    status: mixed().required().oneOf(RfidTagStatus).default("UNASSIGNED"),
    task: number().nullable().default(0),
    tagSerial: string().nullable().default(""),
    taskName: string().nullable().default(""),
    inventoryItem: number().nullable().default(0),
    facilityUnit: number().nullable().default(0),
    inventoryItemName: string().nullable().default(""),
    facilityUnitName: string().nullable().default(""),
    translStatus: string().nullable().default(""),
    notes: string().nullable().default("")

});

