export default {
    "nskForrasJobRfids": "Eszköz RFID adatok átadása Forrásnak",
    "menuName": "RFID bejegyzések átadása",
    "id": "Belső #",
    "createdAt": "Indítás",
    "createdBy": "Inditó",
    "count": "RFID bejegyzések száma",
    "errors": "Hibák",
    "transmission": "Átküldés",
    "status": "Állapot"

};