import React, {memo, useContext, useState} from "react";
import cx from "classnames";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/styles";
import SidebarItem from "./SidebarItem";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {Icon, Typography} from "@material-ui/core";
import DrawerBg from "../../Lib/material-dashboard/assets/img/lalityaron190408_2.jpg";
import {AppContext} from "../../Main/AppContext";
import sidebarStyle from "../../Lib/material-dashboard/assets/jss/components/sidebarStyle";
import Button from "../../Lib/material-dashboard/components/CustomButtons/Button";
import Menu from "@material-ui/icons/Menu";
import AboutProject from "../../Views/Dashboard/AboutProject";
import logo from "../../Assets/img/simplexion-icon.ico"


const useStyles = makeStyles(sidebarStyle);

const Sidebar = memo((props) => {

    const {handleDrawerToggle, sidebarMinimize, open, mini} = props;

    const image = DrawerBg;
    const bgColor = "info";
    const logoText = "NSK LELTÁR";
    const mobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i) !== null;
    const [popup, setPopup] = useState(false)

    const classes = useStyles();
    const {t} = useTranslation();

    const appContext = useContext(AppContext);
    const navTree = appContext.getIn(['appStore', 'navTree']);
    const userName = appContext.getIn(['appStore', 'user']).displayName;
    const accountId = appContext.getIn(['appStore', 'user']).accountId;

    const logoNormal = cx(classes.logoNormal, {[classes.logoNormalSidebarMini]: mini});
    const logoClasses = cx(classes.logo, {[classes.whiteAfter]: bgColor === "white"});

    const accountHref = "/accounts/" + accountId + "/edit";

    const handleClose = () => {
        setPopup(false)
    };

    const handleOpen = () => {
        setPopup(true)
    };

    const brand = (
        <div className={logoClasses}>
            <div hidden={mobile}>
                {mini ?
                    <Icon className={classes.logoMini} onClick={sidebarMinimize}>
                        <ChevronRightIcon/>
                    </Icon> :
                    <Icon className={classes.logoMini} onClick={sidebarMinimize}>
                        <ChevronLeftIcon/>
                    </Icon>
                }
            </div>


            <div className={logoNormal}>
                <a href={"/dashboard"} className={classes.logoText}>{t(logoText)}</a>
                <br/>
                <a href={accountHref} className={classes.logoText}><Typography
                    style={{fontSize: '70%'}}>{userName ? userName : ""} </Typography></a>
            </div>
        </div>
    );

    const drawerPaper = cx(classes.drawerPaper, {[classes.drawerPaperMini]: mini});
    const sidebarWrapper = cx(classes.sidebarWrapper, {[classes.drawerPaperMini]: mini});

    return (
        <div>
            <Hidden mdUp implementation="css">
                <Button
                    className={classes.sidebarButton}
                    color="transparent"
                    justIcon
                    aria-label="open drawer"
                    onClick={handleDrawerToggle}
                >
                    <Menu/>
                </Button>
                <Hidden mdUp>
                    <Drawer
                        anchor={"right"}
                        open={open}
                        classes={{
                            paper: drawerPaper + " " + classes[bgColor + "Background"]
                        }}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true // Better open performance on mobile.
                        }}
                    >
                        {brand}

                        <div className={sidebarWrapper}>
                            {navTree.map(n => <SidebarItem key={n.name} route={n} mini={mini}
                                                           handleDrawerToggle={handleDrawerToggle}/>)}
                        </div>
                        <div id="presentsVersionOfProject" className={classes.presentsVersionOfProject}>
                            <AboutProject closeModal={handleClose} open={popup}/>
                            <div onClick={() => handleOpen()}>
                                {mini ? <img className={classes.presentsVersionOfProjectIco} src={logo} alt="logo"/> :
                                    <p style={{marginLeft: "3vh"}}>by SIMPLEXION</p>}
                            </div>
                        </div>
                        
                        {image !== undefined ? (
                            <div
                                className={classes.background}
                                // style={{backgroundImage: "url(" + image + ")"}}
                            />
                        ) : null}

                    </Drawer>
                </Hidden>
            </Hidden>
            <Hidden smDown>
                <Drawer
                    // onMouseOver={() => sidebarMinimize()} // FIXME
                    // onMouseOut={() => sidebarMinimize()}
                    anchor={"left"}
                    variant="permanent"
                    open
                    classes={{
                        paper: drawerPaper + " " + classes[bgColor + "Background"]
                    }}
                >
                    {brand}
                    <div className={sidebarWrapper}>
                        {navTree.map(n => <SidebarItem key={n.name} route={n} mini={mini}/>)}
                    </div>
                    <div id="presentsVersionOfProject" className={classes.presentsVersionOfProject}>
                        <AboutProject closeModal={handleClose} open={popup}/>
                        <div onClick={() => handleOpen()}>
                            {mini ? <img className={classes.presentsVersionOfProjectIco} src={logo} alt="logo"/> :
                                <p style={{marginLeft: "3vh"}}>by SIMPLEXION</p>}
                        </div>
                    </div>
                    {image !== undefined ? (
                        <div
                            className={classes.background}
                            // style={{backgroundImage: "url(" + image + ")"}}
                        />
                    ) : null}

                </Drawer>
            </Hidden>
        </div>
    );
});


export default Sidebar;
