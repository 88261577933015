import React from "react";
import {useRoutes} from 'hookrouter';
import List from "./ListOfPermits";
import {addRole, removeRole, updateRole, useRole} from "../../Api/roles";
import Crud from "../../../Crud";
import {RoleRecord, RoleSchema} from "../../Data/Role";
import Form from "./Form";
import Grants from "./Grants/Grants";


export default () => useRoutes(subRoutes);

const subRoutes = {
    "/": () => <Crud config={crudConfig} view="list"/>,
    "/add": () => <Crud config={crudConfig} view="add"/>,
    "/:id/edit": ({id}) => <Crud config={crudConfig} view="edit" id={id}/>,
    "/:id/remove": ({id}) => <Crud config={crudConfig} view="remove" id={id}/>,
    "/:id/grants/role-name=:parentName*": ({id}) => <Crud config={crudConfig} OptComponent={Grants}
                                                          optUrlSectionNLabel={'grants'}
                                                          view="other"
                                                          id={id}/>,

};

const crudConfig = {
    compName: 'Roles',
    namespace: 'roles',
    url: '/admin/roles',
    labelField: 'name',
    Record: RoleRecord,
    Schema: RoleSchema,
    ListComponent: List,
    FormComponent: Form,
    api: {
        use: useRole,
        add: addRole,
        edit: updateRole,
        remove: removeRole,
    }
};

