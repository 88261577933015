import React, {useEffect, useReducer} from "react";
import searchBuilderStyle from "./styles/searchBuilderStyle";
import {searchInitialState, searchReducer} from "../Data/SearchStore";
import {actions} from "../Data/Actions";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/styles";
import {SearchContext} from "../Context/SearchContext";

const useStyles = makeStyles(searchBuilderStyle);

function SearchBuilder(props) {

    const classes = useStyles();

    const {children, optionData} = props;

    const [store, dispatch] = useReducer(searchReducer, searchInitialState);

    // Initiating 'options' in store with empty 'data' parameter and 'loading' true
    useEffect(() => dispatch(actions.initOptions(optionData)), [optionData]);

    return (
        <Box className={classes.sbWrapper}>
            <SearchContext.Provider value={{
                store: store,
                dispatch: dispatch,
            }}>
                {children}
            </SearchContext.Provider>
        </Box>
    )
}

export default SearchBuilder;
