import * as React from "react";
import {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import {useFormField} from "../Lib/form/useFormField";
import ValidatedFileUpload from "../Lib/form/Components/ValidatedFileUpload/ValidatedFileUpload";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import {dangerColor, successColor} from "../Lib/material-dashboard/assets/jss/material-dashboard-pro-react";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import IconButton from "@material-ui/core/IconButton";
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles(theme => ({
    root: {
        width: 'auto',
        height: 'auto',
        overflow: 'hidden',
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    title: {
        color: dangerColor[0],
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },

}));

export const Gallery = (props) => {
    const {name, disabled, view} = props;
    const [number, setNumber] = useState(0)

    const classes = useStyles();
    const ff = useFormField(props);
    let fileIds = ff.value ? ff.value : [];

    const deleteImage = (e) => {
        let imageId = e.currentTarget.getAttribute("label").split(/(\d+)/)[1];
        let filtered = fileIds.filter(id => id !== imageId);
        ff.onChange(filtered);
        e.preventDefault()
        setNumber(number => number + 1)

    };

    const showsImage = (e) => {
        window.open(e._targetInst.memoizedProps.src);
    };

    const onSuccess = id => {
        setNumber(number => number + 1)
    };

    const onAbort = imageId => {
        let filtered = fileIds.filter(id => id !== imageId);
        ff.onChange(filtered);
        setNumber(number => number + 1)
    };

    useEffect(() => {
    }, [number]);

    return (
        <Grid container direction="column" justify="center">
            <Grid item={true}>
                <InputLabel
                    style={{
                        padding: "0.3rem",
                        color: (ff.status === "valid" ? successColor[0] : dangerColor[0]),
                        fontWeight: "500",
                        fontSize: "1rem"
                    }}
                    shrink={true}>{ff.t(name)}</InputLabel>
                {ff.helperText && <FormHelperText>{ff.helperText}</FormHelperText>}
            </Grid>
            {disabled ? null : <Grid item={true}>
                <ValidatedFileUpload name={name} apiPath="/pictures" sizeValidation={true} maxFileSize={'1MB'}
                                     acceptedFileTypes={['image/*']} multiple={true}
                                     onAbort={onAbort}
                                     onSuccess={onSuccess}/>
            </Grid>}
            <Grid item={true} className={classes.root}>
                <GridList cellHeight={250} spacing={7} className={classes.gridList} cols={3}>
                    {fileIds.map(image => (
                        <GridListTile key={image}>
                            <img src={'/api/1.0.0/images/' + image} alt={image} onClick={showsImage}/>
                            <GridListTileBar
                                classes={{
                                    root: classes.titleBar,
                                    title: classes.title,
                                }}
                                actionIcon={
                                    ((view === "edit" && !disabled) ?
                                            <IconButton label={`delete ${image}`} onClick={deleteImage}>
                                                <DeleteForeverIcon className={classes.title}/>
                                            </IconButton> : null
                                    )
                                }
                            />
                        </GridListTile>
                    ))}
                </GridList>
            </Grid>
        </Grid>
    );
}