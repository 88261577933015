import React from "react";
import {useRoutes} from 'hookrouter';
import List from "./List";
import {addProcedure, removeProcedure, updateProcedure} from "../../../../Lib/auth/Api/procedures";
import Crud from "../../../Crud";
import {ProcedureRecord, ProcedureSchema} from "../../Data/Procedure";
import {useProcedure} from "../../Api/procedures";
import Form from "./Form";

export default () => useRoutes(subRoutes);

const subRoutes = {
    "/": () => <Crud config={crudConfig} view="list"/>,
    "/add": () => <Crud config={crudConfig} view="add"/>,
    "/:id/edit": ({id}) => <Crud config={crudConfig} view="edit" id={id}/>,
    "/:id/remove": ({id}) => <Crud config={crudConfig} view="remove" id={id}/>
};

const crudConfig = {
    compName: 'Procedures',
    namespace: 'procedures',
    url: '/admin/procedures',
    labelField: 'name',
    Record: ProcedureRecord,
    Schema: ProcedureSchema,
    ListComponent: List,
    FormComponent: Form,
    api: {
        use: useProcedure,
        add: addProcedure,
        edit: updateProcedure,
        remove: removeProcedure,
    }
};


