import {Record} from 'immutable';
import {number, object, string} from "yup";

export const GrantRecord = Record({
    id: 0,
    roleId: 0,
    principalId: 0,
    principalName: null,
    fullName: null,
    emailAddress: null,
    roleName: null
});

export const GrantSchema = object().shape({
    id: number().required().min(0).default(0),
    roleId: number().required().min(1).default(0),
    principalId: number().required().min(1).default(0),
    principalName: string().nullable().default(null),
    fullName: string().nullable().default(null),
    emailAddress: string().email().nullable().default(null),
    roleName: string().nullable().default(null)

});