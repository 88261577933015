import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {useDownloadApk, useVersion} from "../../Api/accounts";
import CloseIcon from '@material-ui/icons/Close';
import pjson from "../../../package.json"
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import IconButton from '@material-ui/core/IconButton';
import {useSnackbar} from "notistack";
import {throwsExceptionMessage} from "../../Api/catchException";
import {closeSnack} from "../../Main/util";

const AboutProject = (props) => {
    const {t} = useTranslation("aboutProject");
    const [allPath, setAllPath] = useState([])
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const aboutProject = useVersion().data;

    useEffect(() => {
        if (aboutProject && aboutProject.androidApkPaths) {
            setAllPath(aboutProject.androidApkPaths)
        }
    }, [aboutProject])

    const openWindow = (url) => {

        const success = (response) => {
            if (response.ok) {
                const link = document.createElement('a');
                link.href = response.url;
                link.download = response.url.substring(response.url.lastIndexOf("\\") + 1, response.url.length)
                link.dispatchEvent(new MouseEvent('click'));
                enqueueSnackbar(t('successfulDownload'), {
                    variant: 'success',
                    autoHideDuration: 4500,
                    action: (key) => closeSnack(t, closeSnackbar, key)
                });
            }
        }

        const error = (error) => {
            enqueueSnackbar(t('catchException:' + throwsExceptionMessage(error)), {
                variant: 'error',
                autoHideDuration: 5000,
                action: (key) => closeSnack(t, closeSnackbar, key)
            });
        };

        // eslint-disable-next-line react-hooks/rules-of-hooks
        useDownloadApk(url, success, error)
    }

    return (
        <Dialog variant="outlined" color="primary" open={props.open}>
            <MuiDialogTitle disableTypography>
                <Typography variant="h5" style={{textAlign: "center", fontWeight: 700}}>{t("projectName")}</Typography>
                <IconButton aria-label="close" style={{position: "absolute", right: '1rem', top: '0.5rem'}}
                            onClick={() => props.closeModal()}>
                    <CloseIcon/>
                </IconButton>
            </MuiDialogTitle>
            <DialogContent>
                <p>{t("projectSummary")}</p>
                <Grid direction="column" container justify="space-around" alignItems="center"
                      style={{paddingBottom: "1rem"}}>
                    <Grid item style={{textAlign: "center", fontWeight: 700, padding: '1rem', fontSize: "medium"}}>
                        {t("version")}
                    </Grid>
                    <Grid container item sm justify="space-evenly" alignItems="center" style={{padding: '0.5rem'}}>
                        <Grid item sm style={{textAlign: "center", fontWeight: 600}}>DB-LIQUIBASE:</Grid>
                        <Grid item sm
                              style={{textAlign: "center"}}>{aboutProject ? t(aboutProject.dbVersion) : t("noVersion")}</Grid>
                    </Grid>
                    <Grid container item sm justify="space-evenly" alignItems="center" style={{padding: '0.5rem'}}>
                        <Grid item sm style={{textAlign: "center", fontWeight: 600}}>API:</Grid>
                        <Grid item sm
                              style={{textAlign: "center"}}>{aboutProject ? t(aboutProject.apiVersion) : t("noVersion")} </Grid>
                    </Grid>
                    <Grid container item sm justify="space-evenly" alignItems="center" style={{padding: '0.5rem'}}>
                        <Grid item sm style={{textAlign: "center", fontWeight: 600}}>BUILD:</Grid>
                        <Grid item sm
                              style={{textAlign: "center"}}> {pjson.version ? t(pjson.version) : t("noVersion")} </Grid>
                    </Grid>
                    <Grid container item sm justify="space-evenly" alignItems="center" style={{padding: '0.5rem'}}>
                        <Grid item sm style={{textAlign: "center", fontWeight: 600}}>ANDROID:</Grid>
                        <Grid justify="center" container item sm style={{maxHeight: "9rem", overflowY: "auto"}}>
                            {(allPath && allPath.length > 0) ? allPath.map((fileName, index) =>
                                    <div key={fileName}
                                         style={{padding: "0.2rem", paddingLeft: "2rem", cursor: "pointer"}}
                                         onClick={() => openWindow(fileName)}>
                                        <div style={{margin: "auto", display: "flex"}}><span style={{padding: "0.5rem"}}
                                                                                             key={fileName + index}
                                                                                             className="material-icons">android</span>
                                            <span style={{
                                                paddingLeft: "0.8rem",
                                                paddingTop: "0.6rem",
                                                fontSize: "medium",
                                                fontWeight: 600
                                            }}>{fileName}</span>
                                        </div>
                                    </div>) :
                                <div style={{margin: "auto", display: "flex"}}><span
                                    style={{padding: "0.5rem", paddingLeft: "1.3rem"}}
                                    className="material-icons">report_problem</span>
                                    <span style={{
                                        paddingTop: "0.7rem",
                                        fontSize: "medium",
                                        fontWeight: 600
                                    }}>{t("notFound")}</span>
                                </div>}
                        </Grid>

                    </Grid>
                    <Grid container item sm justify="space-evenly" alignItems="center"
                          style={{padding: '0.5rem', paddingTop: "1rem"}}>
                        <Grid item sm style={{textAlign: "center", fontWeight: 500}}>{t("name")}:</Grid>
                        <Grid item sm style={{textAlign: "center"}}>{t("simplexionName")}</Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid container item sm justify="space-evenly" alignItems="center"
                              style={{padding: '0.5rem'}}>
                            <Grid item sm style={{textAlign: "center", fontWeight: 500}}>{t("address")}:</Grid>
                            <Grid item sm style={{textAlign: "center"}}>{t("simplexionAddress")}</Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default AboutProject;
