import React from "react";
import List from "./List";
import {useRoutes} from "hookrouter";
import Form from "./Form";
import Crud from "../../../Lib/Crud";
import {TaskFacilityUnitRecord, TaskFacilityUnitSchema} from "../../../Data/task-facility-unit";
import {
    addTaskFacilityUnit,
    removeTaskFacilityUnit,
    updateTaskFacilityUnit,
    useTaskFacilityUnit
} from "../../../Api/task-facility-units";

export default (props) => useRoutes(subRoutes)(props);

const subRoutes = {
    "/": () => (props) => <Crud config={crudConfig(props)} view="list" {...props}/>,
    "/add": () => (props) => <Crud config={crudConfig(props)} view="add" {...props}/>,
    "/:id/edit": ({id}) => (props) => <Crud config={crudConfig(props)} view="edit" id={id} {...props}/>,
    "/:id/remove": ({id}) => (props) => <Crud config={crudConfig(props)} view="remove" id={id} {...props}/>

};

const crudConfig = (props) => ({

    compName: 'taskFacilityUnits',
    namespace: 'taskFacilityUnits',
    Record: TaskFacilityUnitRecord,
    Schema: TaskFacilityUnitSchema,
    api: {
        use: useTaskFacilityUnit,
        add: addTaskFacilityUnit,
        edit: updateTaskFacilityUnit,
        remove: removeTaskFacilityUnit
    },
    url: props.path[0].url + "/" + props.parentId + '/facility-units',
    labelField: 'id',
    ListComponent: List,
    FormComponent: Form,
    setOptRecField: "task",
    parentId: props.parentId,
});


