import React, {useEffect, useState} from "react";
import ContentBox from "../../Components/ContentBox/ContentBox";
import {SimplexionTable} from "../../Lib/simplexion-table/SimplexionTable";
import {baseTableStyles} from "../../Lib/simplexion-table/baseStyle";
import {makeStyles} from "@material-ui/core";
import {makeTableStyles, tableStyles} from "../../Components/Table/tableStyles"
import {FilterField} from "../../Components/Table/FilterField";
import {inputFieldStyles} from "../../Lib/simplexion-table/templates/text-input";
import {updatableCheckBoxStyle} from "../../Lib/simplexion-table/templates/checkbox-input";
import {useTranslation} from "react-i18next";
import Button from "@material-ui/core/Button";
import Section from "../../Lib/form/Components/Section/Section";
import LinearProgress from "@material-ui/core/LinearProgress";
import {addNskForrasJobRfid, useNskForrasJobRfids} from "../../Api/nsk-forras-jobs-rfid";

const useTableStyles = makeStyles(makeTableStyles(baseTableStyles, updatableCheckBoxStyle, inputFieldStyles));

const useStylesFilterField = makeStyles(tableStyles);

export default () => {

    const t = useTranslation("nskForrasJobRfids").t;

    const tableClasses = useTableStyles();

    const classes = useStylesFilterField();

    const [revision, setRevision] = useState(0);

    const [state, setState] = useState('');

    const {loadingStatus, data} = useNskForrasJobRfids(undefined, revision);

    useEffect(() => {
    }, [revision])

    const handleFilter = val => setState(val);

    const path = [{label: t('nskForrasJobRfids'), url: "/nsk-forras-job-rfids"}];

    const tableConfig = {
        id: 'nsk-forras-job-rfids-table',
        nameSpace: 'nskForrasJobRfids',
        stickyHeader: true,
        sortable: true,
        styles: tableClasses,
        columns: [
            {accessor: 'id'},
            {accessor: 'createdAt'},
            {accessor: 'createdBy'},
            {accessor: 'status'},
            {accessor: 'count'},
        ],
    };

    const startRFIDJob = () => {
        addNskForrasJobRfid();
        setRevision(revision + 1);
    };

    return (
        <ContentBox path={path}>
            <Section>
                <FilterField
                    handler={handleFilter}
                    buttons={
                        <Button onClick={startRFIDJob} variant="contained" color="primary" size='medium'
                                className={classes.button}>{t('transmission')}</Button>}/>
                {loadingStatus > 0 ? <LinearProgress/> : null}
                <SimplexionTable tableConfig={tableConfig} data={data} loadingStatus={loadingStatus}
                                 filterValue={state}/>
            </Section>
        </ContentBox>
    );
};