import baseFetch, {useFetchMany, useFetchOne} from "../Lib/baseFetch";
import {InventoryItemRecord} from "../Data/inventory-item";
import {AreaEntryRecord, AreaEntrySchema} from "../Data/area-entry";


export function createOrDeleteEntries(inventoryAreaEntry, onSuccess, onError) {
    baseFetch('PUT', '/inventory-areas/' + inventoryAreaEntry.get("inventoryArea") + '/entries', inventoryAreaEntry, onSuccess, onError);
}

export function useInventoryAreaEntry(parentId) {
    return useFetchOne('/inventory-areas/' + parentId + '/entries', 0, AreaEntryRecord, AreaEntrySchema);
}

export function useSearchForItemsInTheArea(filter) {
    return useFetchMany('/inventory-ares-items', filter, InventoryItemRecord);
}