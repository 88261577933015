import useForm from "../../../../form/useForm";
import React, {memo} from "react";
import ValidatedSubmit from "../../../../form/Components/ValidatedSubmit/ValidatedSubmit";
import {useTranslation} from "react-i18next";
import ContentBox from "../../../../../Components/ContentBox/ContentBox";
import Section from "../../../../form/Components/Section/Section";
import {usePrincipals} from "../../../Api/principals";
import ValidatedSelect from "../../../../form/Components/ValidatedSelect/ValidatedSelect";
import {MembershipRecord, MembershipSchema} from "../../../Data/Membership";
import debug from "../../../../debug";

export default memo(({id, record, path, view, api}) => {
    const {t} = useTranslation("memberships");

    const form = useForm({
        recordType: MembershipRecord,
        recordSchema: MembershipSchema,
        namespace: 'memberships',
        record: record

    });

    debug("RENDER", "useForm", () => [" -> Form_Of_Memberships", view, id, path, record]);

    return (
        <ContentBox path={path} form={form}>
            <Section title={t("memberships")}>
                <ValidatedSelect name="principalId" recordFieldName={"fullName"} api={usePrincipals}/>
                <ValidatedSubmit view={view} api={api}/>
            </Section>
        </ContentBox>
    );
})