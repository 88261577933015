import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../../Lib/form/useForm";
import ContentBox from "../../../Components/ContentBox/ContentBox";
import Section from "../../../Lib/form/Components/Section/Section";
import ValidatedInput from "../../../Lib/form/Components/ValidatedInput/ValidatedInput";
import {RfidTagRecord, RfidTagSchema} from "../../../Data/rfid-tag";
import debug from "../../../Lib/debug"
import {Navigation} from "./Navigation";
import ValidatedSubmit from "../../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import ValidatedRadioGroup from "../../../Lib/form/Components/ValidatedRadio/ValidatedRadioGroup";
import Grid from "@material-ui/core/Grid";
import {RfidTagStatus} from "../../../Data/RfidTagHistoryType";
import ValidatedRadio from "../../../Lib/form/Components/ValidatedRadio/ValidatedRadio";


export default memo(({id, record, path, view, api}) => {
    const {t} = useTranslation('tags');

    const form = useForm({
        recordType: RfidTagRecord,
        recordSchema: RfidTagSchema,
        namespace: 'tags',
        record: record
    });

    debug("RENDER", "useForm", () => [" -> Form_Of_Rfid_Tags", id, record, path, view]);

    return (
        <ContentBox path={path} form={form}>
            {view === "add" ? null : <Navigation parentId={id} path={path}/>}

            <Section title={t('tag')}>
                <ValidatedInput name="serial" disabled={true}/>
                <ValidatedRadioGroup name="status">
                    <Grid container>
                        {view === "add" ? RfidTagStatus.map(a => <ValidatedRadio name="status" value={a} key={a}/>) :
                            <ValidatedRadio name="status" value={record.status}/>}
                    </Grid>
                </ValidatedRadioGroup>
            </Section>

            <ValidatedSubmit view={view} api={api} hideSave={true}/>


        </ContentBox>
    );
})