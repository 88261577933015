import React, {Fragment, useCallback, useContext, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Button from "../../../material-dashboard/components/CustomButtons/Button";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import {FormContext} from "../../useForm";
import debug from "../../../debug";
import {throwsExceptionMessage} from "../../../../Api/catchException";
import {navigate} from "hookrouter";
import {Button as ButtonOfSnackbar} from "@material-ui/core";


function _label(view, t, submitLabel) {
    if (submitLabel) return [submitLabel, "warning"];

    if (!view) return [t('save'), "warning"];

    switch (view) {
        case "add" :
            return [t('add'), "success"];
        case "edit" :
            return [t('save'), "success"];
        case "remove" :
            return [t('delete'), "danger"];
        default :
            return [t('save'), "warning"];
    }
}

function ValidatedSubmit(props) {
    const {view, api, hideCancel, submitLabel, onSuccess, onError, validationInfo, hideSave} = props;

    const {
        recordType,
        fieldValues,
        namespace,
        validationStatuses,
        validationCallbacks
    } = useContext(FormContext);

    const t = useTranslation(namespace).t;

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const [label, color] = _label(view, t, submitLabel);

    const [disableButton, setDisableButton] = useState(false)

    const [state, setState] = useState({
        busy: false,
        revision: 0
    });

    const doSubmit = () => {
        setDisableButton(true)
        const record = new recordType(fieldValues.current);
        debug("DATA", "submit", () => [fieldValues.current, record.toJSON()]);

        api(
            record,
            (response) => {
                setState({...state, busy: false});
                if (onSuccess) {
                    onSuccess(response);
                    // enqueueSnackbar(t('general:save.success'), {variant: 'success'});
                } else {
                    enqueueSnackbar(t('general:save.success'), {variant: 'success'});
                    navigate(view === "add" ? "./" : "../");
                }
            },
            (error, skipSnack) => {
                if (error !== "wth") {         //TODO FIX ME
                    setState({...state, busy: false});
                    if (onError) {
                        onError(error);
                        // enqueueSnackbar(t('catchException:' + throwsExceptionMessage(error)), {
                        //     variant: 'error',
                        //     autoHideDuration: 2000
                        // });
                    } else {
                        enqueueSnackbar(t('catchException:' + throwsExceptionMessage(error)), {
                            variant: 'error',
                            autoHideDuration: 2000
                        });
                        if (!skipSnack) enqueueSnackbar(t('general:save.error'), {variant: 'error'});
                    }
                }
            }
        );
        setState({...state, busy: true});
        setDisableButton(false)
    };

    const callback = useCallback(() => {
        setState((old) => ({...old, revision: old.revision + 1}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validationStatuses]);

    useEffect(() => {
        let cba = validationCallbacks.current;

        if (cba.indexOf(callback) === -1) {
            cba.push(callback);
        }
        return () => {
            let index = cba.indexOf(callback);
            if (index !== -1) cba.splice(index, 1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callback]);

    const recordStatus = Object.values(validationStatuses.current).find(s => s === "invalid" || s === "default-invalid");

    let disabled = (state.busy || (recordStatus !== undefined));

    const VI = validationInfo; // to have component class name capitalized

    const action = key => (
        <Fragment>
            <ButtonOfSnackbar onClick={() => {
                setDisableButton(false)
                closeSnackbar(key);
                navigate(view === "add" ? "./" : "../");
            }}>
                {t('general:yes')}
            </ButtonOfSnackbar>
            <ButtonOfSnackbar onClick={() => {
                closeSnackbar(key)
                setDisableButton(false)
            }}>
                {t('general:no')}
            </ButtonOfSnackbar>
        </Fragment>
    );

    return (
        <Grid container spacing={2}>
            {!validationInfo ? null :
                <Grid item xs={12}>
                    <VI statuses={validationStatuses.current}/>
                </Grid>
            }
            {hideCancel ? null :
                <Grid item>
                    <Button disabled={disableButton}
                            style={{backgroundColor: "rgb(9, 50, 90, 1)"}}
                            onClick={() => {
                                setDisableButton(true)
                                if (hideSave) navigate(view === "add" ? "./" : "../");
                                else enqueueSnackbar(t('general:areYouSure'), {
                                    variant: 'warning',
                                    autoHideDuration: 5000,
                                    action
                                })
                            }}
                    >
                        {t('back')}
                    </Button>
                </Grid>
            }
            {hideSave ? null : <Grid item>
                <Button
                    disabled={disabled ? disabled : disableButton}
                    color={color}
                    onClick={doSubmit}
                >
                    {label}
                </Button>
            </Grid>}
        </Grid>
    );
}

export default ValidatedSubmit