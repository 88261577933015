import {Record} from 'immutable';
import {array, number, object, string} from "yup";

export const FacilityAccessRecord = Record({
    id: 0,
    principalId: 0,
    accountId: 0,
    facilities: [],
    principalName: ""
});

export const FacilityAccessSchema = object().shape({
    id: number().required().min(0).default(0),
    principalId: number().nullable().default(0),
    accountId: number().required().min(1).default(0),
    facilities: array().of(number().integer()).default([]),
    principalName: string().nullable().default("")
});
