import React from "react";
import {useRoutes} from "hookrouter";
import Crud from "../../../Lib/Crud";
import Form from "./Form";
import {addTask, removeTask, updateTask, useTask} from "../../../Api/tasks";
import {InventoryTicketRecord, InventoryTicketSchema} from "../../../Data/inventory-ticket";
import ForeignList from "./List.jsx"


export default (props) => useRoutes(subRoutes)(props);

const subRoutes = {
    "/": () => (props) => <Crud config={crudConfig(props)} view="list" {...props}/>,

};

const crudConfig = (props) => ({
    compName: 'ForeignItems',
    namespace: 'tasks',
    url: props.path[0].url + "/" + props.parentId + '/foreign-items',
    labelField: 'id',
    Record: InventoryTicketRecord,
    Schema: InventoryTicketSchema,
    ListComponent: ForeignList,
    FormComponent: Form,
    api: {
        use: useTask,
        add: addTask,
        edit: updateTask,
        remove: removeTask
    }
});
