import React, {memo, useState} from "react";
import {useCreationRules} from "../../../../Lib/auth/Api/creationRules";
import ContentBox from "../../../../Components/ContentBox/ContentBox";
import {SimplexionTable} from "../../../simplexion-table/SimplexionTable";
import {baseTableStyles} from "../../../simplexion-table/baseStyle";
import {makeStyles} from "@material-ui/core";
import {customActions} from "../../../simplexion-table/templates/action-buttons";
import {makeTableStyles} from "../../../../Components/Table/tableStyles";
import {updatableCheckBoxStyle} from "../../../simplexion-table/templates/checkbox-input";
import {inputFieldStyles} from "../../../simplexion-table/templates/text-input";
import debug from "../../../debug";
import {FilterField} from "../../../../Components/Table/FilterField";
import LinearProgress from "@material-ui/core/LinearProgress";
import Section from "../../../form/Components/Section/Section";


const useTableStyles = makeStyles(makeTableStyles(baseTableStyles, updatableCheckBoxStyle, inputFieldStyles));

const List = memo((props) => {

    debug("RENDER", "useForm", () => [" -> List_Of_Creation_Rules", props]);

    const {path} = props;

    const tableClasses = useTableStyles();

    const [state, setState] = useState('');

    const urlNs = path[0].url + '/';

    const {loadingStatus, data} = useCreationRules();

    const handleFilter = val => setState(val);

    const tableConfig = {
        id: 'creation-rules-table',
        nameSpace: 'creationRules',
        stickyHeader: true,
        sortable: true,
        styles: tableClasses,
        columns: [
            {
                accessor: 'id',
            },
            {
                accessor: 'name',
            },
            {
                accessor: 'allowPermission',
            },
            {
                accessor: 'description',
            },
            {
                accessor: 'action',
                renderer: (...args) => customActions(true, false, urlNs, ...args),
                sortable: false,
            },
        ]
    };

    return (
        <ContentBox path={path} add={urlNs + "add"}>
            <Section>
                <FilterField handler={handleFilter}/>
                {loadingStatus > 0 ? <LinearProgress/> : null}
                <SimplexionTable tableConfig={tableConfig} data={data} loadingStatus={loadingStatus}
                                 filterValue={state}/>
            </Section>
        </ContentBox>
    );
});

export default List;
