import {Record} from 'immutable';
import {array, object, string} from "yup";

export const VersionRecord = Record({
    apiVersion: '',
    dbVersion: '',
    androidApkPaths: []
});


export const VersionSchema = object().shape({
    apiVersion: string().nullable().default(""),
    dbVersion: string().nullable().default(""),
    androidApkPaths: array().nullable().default([])

});
