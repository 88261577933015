import React, {memo, useState} from "react";
import ContentBox from "../../../Components/ContentBox/ContentBox";
import {SimplexionTable} from "../../../Lib/simplexion-table/SimplexionTable";
import debug from "../../../Lib/debug";
import {FilterField} from "../../../Components/Table/FilterField";
import {makeStyles} from "@material-ui/core";
import {makeTableStyles} from "../../../Components/Table/tableStyles";
import {baseTableStyles} from "../../../Lib/simplexion-table/baseStyle";
import {useTranslation} from "react-i18next";
import LinearProgress from "@material-ui/core/LinearProgress";
import Section from "../../../Lib/form/Components/Section/Section";

const useTableStyles = makeStyles(makeTableStyles(baseTableStyles));

const ListOfItems = memo((props) => {

    const {api, filter, path, parentName} = props;

    const {t} = useTranslation("inventoryItems");

    const nameOfParent = parentName ? parentName.replace(/%20/g, " ") : t("**unknown-name**");

    if (path && path.length === 3) {
        let editPage = {label: nameOfParent, url: path[2].url + "/" + filter.facilityUnit + "/edit"};
        let listOfItems = {
            label: t("inventoryItems"),
            url: path[2].url + "/" + filter.facilityUnit + "/list-of-items/" + nameOfParent
        };
        path.push(editPage);
        path.push(listOfItems);
    }

    const {loadingStatus, data} = api(filter);

    debug("RENDER", "useForm", () => [" -> List_Of_Inventory_Items", props]);

    const tableClasses = useTableStyles();

    const [state, setState] = useState('');

    const handleFilter = val => setState(val);

    const tableConfig = listOfItems(tableClasses);

    return (
        <ContentBox path={path}>
            <Section>
                <FilterField handler={handleFilter}/>
                {loadingStatus > 0 ? <LinearProgress/> : null}
                <SimplexionTable tableConfig={tableConfig} data={data} loadingStatus={loadingStatus}
                                 filterValue={state}/>
            </Section>
        </ContentBox>
    );
});

export default ListOfItems


export function listOfItems(tableClasses) {

    return {
        id: 'inventory-items-table',
        nameSpace: 'inventoryItems',
        stickyHeader: true,
        sortable: true,
        styles: tableClasses,
        columns: [
            {
                accessor: 'idAndMainPartId',
            },
            {
                accessor: 'hasAccessories',
                renderer: (accessor, record) => record.hasAccessories ? `<span<i class="material-icons">done</i></span>` : ``,
            },
            {
                accessor: 'erpId',
            },
            {
                accessor: 'erpIdAccessory',
            },
            {
                accessor: 'name',
            },
            {
                accessor: 'unit',
            },
            {
                accessor: 'quantity',
            },
            {
                accessor: 'formattedLastInventoryTime',
            },
            {
                accessor: 'translStatus',
            },
        ],
    };
}