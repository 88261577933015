import Immutable from 'immutable';
import {types} from './Actions'
import Option from "./Records/Option";
import Condition from "./Records/Condition";

export const searchInitialState = Immutable.OrderedMap({
    options: Immutable.OrderedMap(),
    conditions: Immutable.OrderedMap(),
});

export function searchReducer(state, action) {

    switch (action.type) {
        case types.INIT_OPTIONS:
            return initOptions(state, action);
        case types.LOAD_OPTIONS_SUCCESS_SELECT:
            return loadOptionsSuccessSelect(state, action);
        case types.LOAD_OPTIONS_SUCCESS_TEXT:
            return loadOptionsSuccessText(state, action);
        case types.ADD_CONDITION_SELECT:
            return addConditionSelect(state, action);
        case types.ADD_CONDITION_TEXT:
            return addConditionText(state, action);
        case types.REMOVE_CONDITION:
            return removeCondition(state, action);
        case types.RESET_CONDITIONS:
            return resetConditions(state);
        default:
            throw new Error();
    }

    function initOptions(state, action) {

        // Creates the basic data structure for options

        return state.withMutations(orderedMap => {

            action.data.forEach(o => {

                orderedMap.setIn(
                    ['options', o.name],
                    new Option({
                        name: o.name,
                        fetchFunction: o.fetchFunction,
                        inputType: o.inputType,
                        loading: true,
                    })
                );
            });
        })
    }

    function loadOptionsSuccessSelect(state, action) {

        const optionName = action.optionName;
        const data = action.data;
        const urlData = action.urlData;
        
        // Creates a new OrderedMap which will be the Option record's data attribute

        if (!data) return state;

        const dataMap = Immutable.OrderedMap().withMutations(orderedMap => {

            data.forEach(d => {

                // Creates a new Condition record from the values necessary for the SearchBuilder.
                // The conditionId is a custom id containing the Option's name and the condition's id.
                // This makes easier to handle the Material Dashboard's multi select component and in the same time
                // it solves the problem of creating a unique id for the Store's 'condition' attribute and for custom
                // text inputs

                const conditionId = optionName + '_' + d.get('id');
                const condition = new Condition({
                    id: d.get('id'),
                    conditionId: conditionId,
                    name: d.get('name'),
                    optionName: optionName,
                });

                orderedMap.set(conditionId, condition);
            });

        });

        return state.withMutations(orderedMap => {

            // Sets the current Option record's 'data' value and loading attributes

            orderedMap.setIn(['options', optionName, 'data'], dataMap)
                .setIn(['options', optionName, 'loading'], false)
                .setIn(['options', optionName, 'loaded'], true);

            // Checks if there is initial data which comes from an URL

            if (data.length !== 0 && urlData !== undefined) {
                urlData.forEach(id => {

                    // Tries to find the Condition in the current Option's 'data' value by the 'id' of the URL data

                    const selectedCondition = orderedMap.getIn(['options', optionName, 'data'])
                        .find(condition => condition.get('id') === id);

                    // If the Condition exists the current Option's 'selected' attribute will be updated with the
                    // Condition's conditionId, and the whole Condition record will also be pushed to the Store's
                    // 'conditions' value

                    if (selectedCondition) {

                        orderedMap.updateIn(['options', optionName, 'selected'], arr => {
                            if (!arr) arr = [];
                            arr.push(selectedCondition.get('conditionId'));
                            return arr;
                        })
                            .update('conditions', om => om.set(selectedCondition.get('conditionId'), selectedCondition));
                    }
                })
            }
        });
    }

    function loadOptionsSuccessText(state, action) {

        const optionName = action.optionName;
        const urlData = action.urlData;

        // Checks if there is initial data which comes from an URL. In that case it creates new Condition records
        // from them and sets it in it's Option's 'data' value and the Store's 'condition' attribute

        if (urlData) {
            return state.withMutations(orderedMap => {

                urlData.forEach(text => {

                    const conditionId = optionName + '_' + text;

                    const item = new Condition({
                        id: text,
                        conditionId: conditionId,
                        name: text,
                        optionName: optionName,
                    });

                    orderedMap.setIn(['options', optionName, 'data', conditionId], item)
                        .update('conditions', om => om.set(conditionId, item));
                });
            })
        }

        // In case, that there is no data from an URL, it returns the state itself

        return state;
    }

    function addConditionSelect(state, action) {

        // Get a the Condition record from it's Option's 'data'. Sets it's 'conditionId' in it's' Option's 'selected'
        // value and the record itself in the Store's 'conditions'

        const optionName = action.optionName;
        const conditionId = action.currentTargetId;
        const conditionItem = state.getIn(['options', optionName, 'data', conditionId]);

        return state.withMutations(orderedMap => {
            orderedMap.updateIn(['options', optionName, 'selected'], arr => {
                if (arr === undefined) arr = [];
                arr.push(conditionId);
                return arr;
            })
                .update('conditions', om => om.set(conditionId, conditionItem))
        });
    }

    function addConditionText(state, action) {

        const optionName = action.optionName;
        const text = action.text;

        // Creates a new conditionId from it's Option's 'name' and the string itself.
        // Creates a new Condition record for the new value sets it's 'conditionId' in it's Option's 'selected' value,
        // and the Condition itself in the Store's 'conditions'

        const conditionId = optionName + '_' + text;

        const item = new Condition({
            name: text,
            optionName: optionName,
            id: text,
            conditionId: conditionId,
        });

        return state.withMutations(orderedMap => {
            orderedMap.setIn(['options', optionName, conditionId], item)
                .update('conditions', om => om.set(conditionId, item));
        });
    }

    function removeCondition(state, action) {

        // Removes the Condition's 'id' from it's Option's 'selected' values and from the Store's 'conditions'

        const conditionId = action.currentTargetId;

        return state.withMutations(orderedMap => {
            orderedMap.updateIn(['options', action.optionName, 'selected'], arr => {
                if (arr) return arr.filter(i => i !== conditionId);
            })
                .update('conditions', om => om.delete(conditionId));
        });
    }

    function resetConditions(state) {

        // Reset the Store's 'conditions' value and all Option's selected value
        return state.withMutations(orderedMap => {
            orderedMap.update('options', options => options.map(o => o.set('selected', undefined)))
                .update('conditions', () => Immutable.OrderedMap());
        })
    }

}

