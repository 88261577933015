import baseFetch, {useFetchMany, useFetchOne} from "../../baseFetch";
import {ProcedureRecord, ProcedureSchema} from "../Data/Procedure";

export function useProcedures(filter) {
    return useFetchMany('/procedures', undefined, ProcedureRecord);
}

export function useProcedure(id) {
    return useFetchOne('/procedures', id, ProcedureRecord, ProcedureSchema);
}

export function addProcedure(procedure, onSuccess, onError) {
    baseFetch('POST', '/procedures', procedure,
        data => {
            if (onSuccess) onSuccess(new ProcedureRecord(data))
        },
        onError
    );
}

export function getProcedures(filter, onSuccess, onError) {
    baseFetch('GET', '/procedures', filter,
        data => {
            if (onSuccess) onSuccess(data.map(r => new ProcedureRecord(r)))
        },
        onError
    );
}

export function getProcedure(id, onSuccess, onError) {
    baseFetch('GET', '/procedures/' + id, undefined,
        data => {
            if (onSuccess) onSuccess(new ProcedureRecord(data))
        },
        onError
    );
}

export function updateProcedure(procedure, onSuccess, onError) {
    baseFetch('PATCH', '/procedures/' + procedure.get('id'), procedure, onSuccess, onError);
}

export function removeProcedure(procedure, onSuccess, onError) {
    baseFetch('DELETE', '/procedures/' + procedure.get('id'), undefined, onSuccess, onError);
}

