import {Record} from 'immutable';
import {number, object, string} from "yup";

export const ViewsListRecord = Record({
    id: 0,
    displayName: "",
});

export const ViewsListSchema = object().shape({
    id: number().required().min(1).default(0),
    displayName: string().nullable().default(''),
});
