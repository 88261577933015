export const FileUploadHu = {
    'labelIdle': 'Húzza és dobja rá a kivánt fájlokat vagy nyissa meg a <span class=\"filepond--label-action\"> fájlkezelőből </span>',
    'labelInvalidField': 'A mező érvénytelen fájlokat tartalmaz',
    'labelFileWaitingForSize': 'A fájl méretének feldolgozása',
    'labelFileSizeNotAvailable': 'A fájl mérete nem megfelelő',
    'labelFileProcessingAborted': 'A feltöltés megszakítva',
    'labelFileProcessingRevertError': 'Hiba a feltöltés visszavonása közben',
    'labelFileRemoveError': 'Hiba a fájl eltávolítása közben',
    'labelButtonRemoveItem': 'Visszavonás',
    'labelButtonAbortItemLoad': 'Megszakítás',
    'labelButtonRetryItemLoad': 'Újra',
    'labelButtonAbortItemProcessing': 'Megszakítás',
    'labelButtonUndoItemProcessing': 'Visszavonás',
    'labelButtonRetryItemProcessing': 'Újra',
    'labelButtonProcessItem': 'Feltöltés',
    'labelFileLoading': "Feltöltés",
    'labelFileProcessing': "Feltöltés...",
    'labelFileTypeNotAllowed': "Érvénytelen fájl formátum",
    'labelMaxFileSizeExceeded': "A fálj túl nagy",
    'labelMaxFileSize': "Megengedett méret: maximum 1MB",
    'labelFileLoadError': 'Hiba lépett fel a fájl beltöltése közben',
    'labelFileProcessingComplete': 'A fájl sikeresen fel lett töltve',
    'labelFileProcessingError': 'Hiba lépett fel a fájl feltöltése közben',
    'abelFileProcessingRevertError': 'Sikertelen visszaállítás',
    'labelTapToRetry': 'újraküldés',
    'labelTapToCancel': 'törlés',
    'labelTapToUndo': 'visszavonás',
    'imageFileValidateTypeLabelExpectedTypes': 'Megengedett fájltípusok: ".gif", ".jpeg", ".jpg", ".png", ".raw", ".tiff"',
    'fileValidateTypeLabelExpectedTypes': 'Megengedett fájltípusok: ',

};

export const FileUploadEn = {
    'labelIdle': "Drag & Drop your files or <span class=\"filepond--label-action\"> Browse </span>.",
    'labelInvalidField': "Field contains invalid files",
    'labelFileLoading': 'Loading',
    'labelFileProcessing': 'Loading...',
    'labelFileTypeNotAllowed': "File of invalid type",
    'labelMaxFileSizeExceeded': "File is too large",
    'labelMaxFileSize': "Maximum file size is 1MB",
    'labelFileLoadError': 'Error during load',
    'labelFileProcessingComplete': 'Upload complete',
    'labelFileProcessingAborted': 'Upload cancelled',
    'labelFileProcessingError': 'Error during upload',
    'abelFileProcessingRevertError': 'Error during revert',
    'labelFileRemoveError': 'Error during remove',
    'labelTapToRetry': 'tap to retry',
    'labelTapToCancel': 'tap to cancel',
    'labelTapToUndo': 'tap to undo',
    'fileValidateTypeLabelExpectedTypes': 'Expects image format',

};