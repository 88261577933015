import debug from "./debug";
import {useTranslation} from "react-i18next";
import React, {useContext} from "react";
import {AuthContext} from "./auth/AuthContext";
import {roles} from "../Main/roles";

export default (props) => {
    const {
        config: {
            compName,
            namespace,
            Record,
            Schema,
            api,
            url,
            labelField,
            ListComponent,
            FormComponent,
            parentId,
            setOptRecField,
            optValueOfOptRecField
        },
        view,
        id,
        OptComponent,
        optUrlSectionNLabel,

    } = props;

    debug("RENDER", compName, () => [view]);

    const {t} = useTranslation(namespace);

    const {loadingStatus, data} = parentId ? api.use(parentId, id) : api.use(id);

    const viewData = (loadingStatus ? new Record(Schema.default()) : data);

    const path = [{label: t(namespace), url}];

    // noinspection JSCheckFunctionSignatures
    const name = viewData.get(labelField);

    const authContext = useContext(AuthContext);

    const hasAdminRoles = authContext.getIn(['session', 'roles']).some(role => [roles.BUSINESS_ADMIN.toLowerCase(), roles.SECURITY_OFFICER.toLowerCase()].indexOf(role) !== -1);

    switch (view) {
        case "list" :

            return <ListComponent path={path} {...props} hasAdminRoles={hasAdminRoles}/>;

        case "add" :

            path.push({label: name, url: url + "/add"});
            return <FormComponent id={id}
                                  record={((parentId || optValueOfOptRecField) && setOptRecField) ? viewData.merge({[setOptRecField]: parseInt(optValueOfOptRecField ? optValueOfOptRecField : parentId, 10)}) : viewData}
                                  path={path} view={view} api={api.add} hasAdminRoles={hasAdminRoles}/>;

        case "remove" :

            path.push({label: name, url: url + "/" + id + "/remove"});
            return <FormComponent id={id} record={viewData} path={path} view={view} api={api.remove} hasAdminRoles={hasAdminRoles}/>;

        case "edit" :

            path.push({label: name, url: url + "/" + id + "/edit"});
            return <FormComponent id={id} record={viewData} path={path} view={view} api={api.edit} hasAdminRoles={hasAdminRoles}/>;

        case "other" :

            path.push({label: name, url: url + "/" + id + "/edit"});
            path.push({label: t(optUrlSectionNLabel), url: url + "/" + id + "/" + optUrlSectionNLabel});
            return <OptComponent parentId={id} record={viewData} path={path} hasAdminRoles={hasAdminRoles}/>;

        default :
            return <div>invalid view: {view}</div>
    }
};