import {Record} from 'immutable';
import {mixed, number, object, string} from "yup";
import {GeneralStatus} from "./GeneralStatus";

export const ItemOwnerRecord = Record({
    id: 0,
    erpId: "",
    name: "",
    status: 'ACTIVE',
    translStatus: ""
});

export const ItemOwnerSchema = object().shape({
    id: number().required().min(0).default(0),
    erpId: string().nullable().default(""),
    name: string().required().min(1).max(60).default(""),
    status: mixed().required().oneOf(GeneralStatus).default("ACTIVE"),
    translStatus: string().nullable().default("")
});


