import React from "react";
import List from "./List";
import {useRoutes} from "hookrouter";
import {FacilityUnitRecord, FacilityUnitSchema} from "../../../Data/facility-unit";
import Form from "./Form";
import {
    addFacilityUnit,
    removeFacilityUnit,
    searchForItemsInTheFacilityUnit,
    updateFacilityUnit,
    useFacilityUnit
} from "../../../Api/facility-units";
import Crud from "../../../Lib/Crud";
import ListOfItems from "../../Inventory/InventoryItems/ListOfItems";

export default (props) => useRoutes(subRoutes)(props);

const subRoutes = {
    "/": () => (props) => <Crud config={crudConfig(props)} view="list" {...props}/>,
    "/add": () => (props) => <Crud config={crudConfig(props)} view="add" {...props}/>,
    "/:id/edit": ({id}) => (props) => <Crud config={crudConfig(props)} view="edit" id={id} {...props}/>,
    "/:id/remove": ({id}) => (props) => <Crud config={crudConfig(props)} view="remove" id={id} {...props}/>,
    "/:id/list-of-items/:parentName?": ({id, parentName}) => (props) => <ListOfItems
        api={searchForItemsInTheFacilityUnit}
        filter={{facilityUnit: id}}
        path={props.path}
        parentName={parentName}/>
};

const crudConfig = (props) => ({

    compName: 'facilityUnits',
    namespace: 'facilityUnits',
    Record: FacilityUnitRecord,
    Schema: FacilityUnitSchema,
    api: {
        use: useFacilityUnit,
        add: addFacilityUnit,
        edit: updateFacilityUnit,
        remove: removeFacilityUnit
    },
    url: props.path[0].url + "/" + props.parentId + '/facility-units',
    labelField: 'name',
    ListComponent: List,
    FormComponent: Form,
    setOptRecField: "facility",
    parentId: props.parentId,
});


