import baseFetch, {useFetchMany, useFetchOne} from "../Lib/baseFetch";
import {FacilityTypeRecord, FacilityTypeSchema} from "../Data/facility-type";


export function useFacilityTypes(filter) {
  return useFetchMany('/facility-types', undefined, FacilityTypeRecord);
}

export function useFacilityType(id) {
  return useFetchOne('/facility-types', id, FacilityTypeRecord, FacilityTypeSchema);
}

export function addFacilityType(facilityType, onSuccess, onError) {
  baseFetch('POST', '/facility-types', facilityType,
      data => {
        if (onSuccess) onSuccess(new FacilityTypeRecord(data))
      },
      onError
  );
}

export function getFacilityType(id, onSuccess, onError) {
  baseFetch('GET', '/facility-types/' + id, undefined,
      data => {
        if (onSuccess) onSuccess(new FacilityTypeRecord(data))
      },
      onError
  );
}

export function updateFacilityType(facilityType, onSuccess, onError) {
  baseFetch('PATCH', '/facility-types/' + facilityType.get('id'), facilityType, onSuccess, onError);
}

export function removeFacilityType(facilityType, onSuccess, onError) {
  baseFetch('DELETE', '/facility-types/' + facilityType.get('id'), undefined, onSuccess, onError);
}
