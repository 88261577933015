import React from "react";
import {makeStyles} from "@material-ui/styles";
import {useFormField} from "../../useFormField";
import LinearProgress from "@material-ui/core/LinearProgress";
import Select from "react-virtualized-select";
import "react-select/dist/react-select.css";
import "react-virtualized-select/styles.css";
import styles from "../../../material-dashboard/assets/jss/customSelectStyle";
import InputLabel from "@material-ui/core/InputLabel";
import {useTranslation} from "react-i18next";
import {dangerColor, successColor} from "../../../material-dashboard/assets/jss/material-dashboard-pro-react";

const useStyles = makeStyles(styles);

function ValidatedFilteredSelect(props) {

    const {name, disabled, api, recordFieldNames, filterOfApi, nullable, dependentOn} = props;

    const classes = useStyles();

    const {t} = useTranslation("inventoryItems")

    const ff = useFormField(props, classes);

    const dependency = dependentOn ? ff.values.current[dependentOn] : filterOfApi;

    const filter = dependentOn ? {
        [dependentOn]: ff.values.current[dependentOn],
        filterOfApi: filterOfApi ? filterOfApi : undefined
    } : undefined;

    const {loadingStatus, data} = api(dependentOn ? filter : (filterOfApi ? filterOfApi : undefined));

    const options = Array.from(data, (item) => ({
        label: Array.isArray(recordFieldNames) ? recordFieldNames.map(name => item.get(name) ? item.get(name) + " " : "") : `${item.get(recordFieldNames ? recordFieldNames : 'name')}`,
        value: item.get('id')
    }));

    if (nullable) options.unshift({label: "", value: 0})

    return (
        <div>
            <InputLabel
                style={{
                    padding: "0.4rem", paddingBottom: "0rem",
                    color: (ff.status === "valid" ? successColor[0] : dangerColor[0]),
                    fontWeight: "600",
                    fontSize: "1.1rem",
                }}
                shrink={true}>{t(name)}</InputLabel>
            <Select
                style={{
                    border: '0px', borderBottom: '0.5px', borderStyle: "groove"
                }}
                disabled={dependentOn ? dependency : disabled}
                value={ff.value}
                onChange={(e) => ff.onChange(e ? e.value : 0)}
                options={options}/>

            {(loadingStatus !== 0) ? <LinearProgress/> : null}
        </div>
    );

}

export default ValidatedFilteredSelect;