import React from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../../Lib/form/useForm";
import {FacilityTypeRecord, FacilityTypeSchema} from "../../../Data/facility-type";
import ContentBox from "../../../Components/ContentBox/ContentBox";
import Section from "../../../Lib/form/Components/Section/Section";
import ValidatedInput from "../../../Lib/form/Components/ValidatedInput/ValidatedInput";
import ValidatedSubmit from "../../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import debug from "../../../Lib/debug"


export default ({id, record, path, view, api, hasAdminRoles}) => {

    const {t} = useTranslation("facilityTypes");

    const form = useForm({
        recordType: FacilityTypeRecord,
        recordSchema: FacilityTypeSchema,
        namespace: 'facilityTypes',
        record
    });

    debug("RENDER", "useForm", () => [" -> Form_Of_Facility_Types", view, id, path, record]);


    return (
        <ContentBox path={path} form={form}>

            <Section title={t('facilityType')}>
                <ValidatedInput name="name" disabled={!hasAdminRoles}/>
            </Section>

            <ValidatedSubmit view={view} api={api} hideSave={!hasAdminRoles}/>

        </ContentBox>
    );
};