import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from '@material-ui/core/CircularProgress';
import InlineLoaderStyle from "./InlineLoaderStyle"

function InlineLoader(props) {

    const {classes} = props;

    return <CircularProgress className={classes.progress}
                             size={16}
                             thickness={5}/>;
}

export default withStyles(InlineLoaderStyle)(InlineLoader);