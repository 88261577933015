import React from "react";
import {useRoutes} from "hookrouter";
import List from "./List";
import Form from "./Form";
import Crud from "../../../Crud";
import {SystemSettingRecord, SystemSettingSchema} from "../../Data/SystemSetting";
import {addSystemSetting, removeSystemSetting, updateSystemSetting, useSystemSetting} from "../../Api/system-settings";

export default () => useRoutes(subRoutes);

const subRoutes = {
    "/": () => <Crud config={crudConfig} view="list"/>,
    "/add": () => <Crud config={crudConfig} view="add"/>,
    "/:id/edit": ({id}) => <Crud config={crudConfig} view="edit" id={id}/>,
    "/:id/remove": ({id}) => <Crud config={crudConfig} view="remove" id={id}/>
};

const crudConfig = {
    compName: 'SystemSettings',
    namespace: 'systemSettings',
    url: '/admin/system-settings',
    labelField: 'name',
    Record: SystemSettingRecord,
    Schema: SystemSettingSchema,
    ListComponent: List,
    FormComponent: Form,
    api: {
        use: useSystemSetting,
        add: addSystemSetting,
        edit: updateSystemSetting,
        remove: removeSystemSetting
    }
};

