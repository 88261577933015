import useForm from "../../../form/useForm";
import React from "react";
import ValidatedInput from "../../../form/Components/ValidatedInput/ValidatedInput";
import ValidatedSubmit from "../../../form/Components/ValidatedSubmit/ValidatedSubmit";
import {useTranslation} from "react-i18next";
import ContentBox from "../../../../Components/ContentBox/ContentBox";
import Section from "../../../form/Components/Section/Section";
import {GroupRecord, GroupSchema} from "../../Data/Group";
import {Navigation} from "./Navigation";
import ValidatedCheckbox from "../../../form/Components/ValidatedCheckbox/ValidatedCheckbox";

export default (props) => {

    const {id, record, path, view, api} = props;

    const {t} = useTranslation("groups");

    const form = useForm({
        recordType: GroupRecord,
        recordSchema: GroupSchema,
        namespace: 'groups',
        record
    });

    return (
        <ContentBox path={path} form={form}>

            {view === 'add' ? null : <Navigation parentId={id}/>}

            <Section title={t("group")}>
                <ValidatedInput name={"name"}/>
                <ValidatedInput disabled name="memberCount"/>
                <ValidatedInput name="description" multiline={true}/>
                <ValidatedCheckbox name={"locked"} label={t("locked")}/>
                <ValidatedSubmit view={view} api={api} />
            </Section>
        </ContentBox>
    );
}
