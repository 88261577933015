import React, {Fragment, memo} from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../Lib/form/useForm";
import ContentBox from "../../Components/ContentBox/ContentBox";
import Section from "../../Lib/form/Components/Section/Section";
import ValidatedSubmit from "../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import {TransferRecord, TransferSchema} from "../../Data/transfer";
import debug from "../../Lib/debug"
import {usePrincipals} from "../../Lib/auth/Api/principals";
import ValidatedSelect from "../../Lib/form/Components/ValidatedSelect/ValidatedSelect";
import {useFacilities} from "../../Api/facilities";
import {useSnackbar} from "notistack";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {navigate} from "hookrouter";
import ValidatedInput from "../../Lib/form/Components/ValidatedInput/ValidatedInput";
import {throwsExceptionMessage} from "../../Api/catchException";
import {closeSnack} from "../../Main/util";
import Grid from "@material-ui/core/Grid";
import Button from "../../Lib/material-dashboard/components/CustomButtons/Button";


export default memo(({id, record, path, view, api}) => {
    const {t} = useTranslation('transfers');
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    let warningMessageKey = null

    const form = useForm({
        recordType: TransferRecord,
        recordSchema: TransferSchema,
        namespace: 'transfers',
        record: record
    });

    const processing = (record, onSuccess, onError) => {

        const action = key => {
            warningMessageKey = key
            return (
                <Fragment>
                    <IconButton onClick={() => {
                        closeSnackbar(key)
                    }}><CloseIcon/></IconButton>
                </Fragment>
            );
        }

        if (view === "add") enqueueSnackbar(t('pdfFileIsBeingMade'), {variant: 'info', autoHideDuration: 5000, action});

        const success = (response) => {
            closeSnackbar(warningMessageKey)
            if (view === "add") {
                navigate(view === "add" ? "./" : "../")
            } else {
                const link = document.createElement('a');
                link.href = response.url;
                link.download = record.pdfFileName;
                link.dispatchEvent(new MouseEvent('click'));

                enqueueSnackbar(t('successfulDownload'), {variant: 'success', autoHideDuration: 2500});
            }
        };

        const error = (error) => {
            closeSnackbar(warningMessageKey)
            enqueueSnackbar(t('catchException:' + throwsExceptionMessage(error)), {
                variant: 'error',
                autoHideDuration: 5000,
                action: (key) => closeSnack(t, closeSnackbar, key)
            });
        };

        api(record, success, error);
    };

    debug("RENDER", "useForm", () => [" -> Form_Of_Transfers", id, record, path, view]);

    return (
        <ContentBox path={path} form={form}>

            <Section title={t('transfer')}>
                <ValidatedSelect name="transferorPrincipal" recordFieldName={"fullName"} api={usePrincipals}
                                 disable={view !== "add"} disabled={view !== "add"}/>
                <ValidatedSelect name="recipientPrincipal" recordFieldName={"fullName"} api={usePrincipals}
                                 disable={view !== "add"} disabled={view !== "add"}/>
                <ValidatedSelect name="transferringFacility" api={useFacilities} disable={view !== "add"}
                                 disabled={view !== "add"}/>
                <ValidatedSelect name="receivingFacility" api={useFacilities} disable={view !== "add"}
                                 disabled={view !== "add"}/>
                <ValidatedInput name="inventoryItems" disabled={view !== "add"} multiline={true} rows={5}/>

            </Section>


            <Grid container spacing={2}>
                {view === "add" ? null :
                    <Grid item>
                        <Button style={{backgroundColor: "rgb(9, 50, 90, 1)"}}
                                onClick={() => {
                                  navigate("../");
                                }}
                        >
                            {t('back')}
                        </Button>
                    </Grid>
                }
                <Grid item>
                    <ValidatedSubmit view={view} api={processing} hideCancel={view !== "add"} submitLabel={view !== "add" ?
                        <div><span className="material-icons">get_app</span></div> : undefined}/>
                </Grid>
            </Grid>

        </ContentBox>
    );
})