import {Record} from 'immutable';
import {number, object, string} from "yup";

export const InventoryItemGroupRecord = Record({
    id: 0,
    name: "",
    query: "",
});

export const InventoryItemGroupSchema = object().shape({
    id: number().required().min(0).default(0),
    name: string().required().min(2).max(60).default(''),
    query: string().required().min(1).max(10000).default(''),
});
