import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import useModal from "use-react-modal";
import Button from "../Lib/material-kit/components/CustomButtons/Button";
import styled from "styled-components";


const LoadableImage = styled.div`
    height: ${props => props.height}vh;
    background-image: url(${props => props.src});
    background-origin: border-box;
    background-repeat: no-repeat;
    background-size: contain;
}`;

export const ImageLoader = ({image, onClick, ...props}) => {
    const runOne = true;
    const [loadState, setLoadState] = useState({src: '/api/1.0.0/images/' + image, loaded: false});
    const {t} = useTranslation();
    const {isOpen, openModal, closeModal, Modal} = useModal();

    useEffect(() => {
        const img = new Image();
        img.onload = function () {
            setLoadState({
                src: img.src,
                loaded: true
            })
        };
    }, [runOne]);

    return (
        <div style={{width: "fit-content"}} onClick={openModal}>
            {isOpen && (
                <Modal>
                    {/*<Button color={"danger"} onClick={() => closeModal} >X</Button>*/}
                    <img src={loadState.src} height={'500vh'} alt={"product_image"}/>
                </Modal>)}
            <LoadableImage {...props} {...loadState} />
            <Button color={"primary"} onClick={onClick}>{t('general:changeImage')} </Button>
        </div>
    )
};