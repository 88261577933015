import React from "react";
import {useTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Button from "../../Lib/material-dashboard/components/CustomButtons/Button";
import {A} from 'hookrouter';
import {makeStyles} from "@material-ui/styles";
import {infoColor} from "../../Lib/material-dashboard/assets/jss/material-dashboard-pro-react";


const useStyles = makeStyles({
    button: {
        backgroundColor: "#396593"
    },
    active: {
        backgroundColor: infoColor[0]
    }
});

export function Navigation(props) {

    const {taskId, path, print, disabled, generating, record} = props;
    const classes = useStyles();

    const {t} = useTranslation();

    return (
        <Grid container direction="column">
            <Grid item xs={12}>
                <A href={path[0].url + "/" + taskId + "/edit"}>
                    <Button className={path[1].url.includes("/edit") ? classes.active : classes.button}
                            size={"sm"}>{t('BaseData')}</Button>
                </A>
                <A href={path[0].url + "/" + taskId + "/inventory-tickets"}>
                    <Button className={classes.button} size={"sm"}>{t('inventoryTickets:inventoryTickets')}</Button>
                </A>
                <A href={path[0].url + "/" + taskId + "/foreign-items"}>
                    <Button className={classes.button} size={"sm"}>{t('tasks:foreignItems')}</Button>
                </A>
                <A href={path[0].url + "/" + taskId + "/facility-units"}>
                    <Button className={classes.button} size={"sm"}>{t('taskFacilityUnits:taskFacilityUnits')}</Button>
                </A>
                {print ?
                    <span title={t("tasks:helperTextForGeneratePdf")}>
                        <Button color={"primary"} size={"sm"} disabled={disabled}
                        onClick={() => {
                            generating(record)
                        }}
                    ><div>{t("tasks:report")}</div>
                    </Button>
                    </span> : null
                }
            </Grid>
        </Grid>
    );
}