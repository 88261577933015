import i18next from "i18next";

export const customActions = (showEdit, showDelete, urlNs, accessor, record, tableStorage) => {

    const {styles} = tableStorage.tableConfig;

    const label = i18next.getResource('hu', 'general', 'details');

    const hrefBase = urlNs + record.get('id');
    const customActionsClasses = styles && styles.customActions ? ` class="${styles.customActions}" ` : ``;
    const customEditClasses = styles && styles.customEdit ? ` class="${styles.customEdit}" ` : ``;
    const customActionLabelClasses = styles && styles.customActionLabel ? ` class="${styles.customActionLabel}" ` : ``;
    const customDeleteClasses = styles && styles.customDelete ? ` class="${styles.customDelete}" ` : ``;

    const editButton = showEdit ? `<a ${customEditClasses} href=${hrefBase + "/edit"}><span${customActionLabelClasses}>${label}</span></a>` : '';
    const deleteButton = showDelete ? `<a ${customDeleteClasses} href=${hrefBase + "/remove"}><span${customActionLabelClasses}>x</span></a>` : '';

    const actions = editButton + deleteButton;

    return `<div ${customActionsClasses}>${actions}</div>`
};

export const customActionsStyle = {
    customLongCell: {},
    customActions: {
        display: 'flex',
    },
    customEdit: {
        borderStyle: 'none',
        display: 'flex',
        margin: '.25rem',
        height: 30,
        minWidth: 30,
        color: '#fff',
        backgroundColor: '#4caf50',
        borderRadius: '.2rem',
        fontSize: '.875rem',
        textTransform: 'uppercase',
        lineHeight: 'inherit',
        textDecoration: 'none',
        '&:hover': {
            backgroundColor: '#357a38',
            color: '#fff',
        }
    },
    customActionLabel: {
        margin: 'auto',
        display: 'inherit',
        alignItems: 'inherit',
        justifyContent: 'inherit',
        padding: '.25rem .5rem'
    },
    customDelete: {
        borderStyle: 'none',
        display: 'flex',
        margin: '.25rem',
        height: 30,
        width: 30,
        color: '#fff',
        backgroundColor: '#f44336',
        borderRadius: '.2rem',
        fontSize: '.875rem',
        fontWeight: 500,
        textTransform: 'uppercase',
        lineHeight: 'inherit',
        textDecoration: 'none',
        '&:hover': {
            backgroundColor: '#aa2e25',
            color: '#fff',
        }
    },
};