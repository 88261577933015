export default {
  "facilities" : "Létesítmények",
  "facility-units": "Létesítmény egységek",
  "facility-unit": "Létesítmény egység",
  "inventory-areas": "Leltárkörzetek",
  "item-groups": "Eszköz csoportok",
  "add": "Hozzáadás",
  "edit": "Szerkesztés",
  "remove": "Törlése",
  "name" : "Név",
  "name-invalid" : "A mező érvénytelen. Minimum 2 és maximum 60 karakter lehet.",
  "facility": "Létesítmény",
  "country": "Ország",
  "country-invalid": "A mező érvénytelen. Minimum 2 és maximum 60 karakter lehet.",
  "state": "Megye",
  "state-invalid": "A mező érvénytelen. Minimum 2 és maximum 60 karakter lehet.",
  "city": "Város",
  "city-invalid": "A mező érvénytelen. Minimum 2 és maximum 60 karakter lehet.",
  "address1": "Cím1",
  "address1-invalid": "A mező érvénytelen. Minimum 2 és maximum 60 karakter lehet.",
  "address2": "Cím2",
  "type" : "Létesítmény típus",
  "description": "Leírás",
  "description-invalid": "Érvénytelen mező, legalább 1, legfeljebb 10000 karakternek kell lennie.",
  "administrativeContact": "Adminisztratív kapcsolattartó",
  "technicalContact": "Műszaki kapcsolattartó",
  "id": "Belső #",
  "action": "Műveletek",
  "back": "Vissza a listához",
  "save": "Létesítmény mentése",
  "administrativeContactName": "Admin. kapcsolattartó",
  "technicalContactName": "Tech. kapcsolattartó ",
  "erpId": "Forrás.NET #",
  "erpId2": "Forrás.NET #2",

};