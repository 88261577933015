import React from "react";
import List from "../List";
import {useRoutes} from "hookrouter";
import Form from "./Form";
import Crud from "../../../../Lib/Crud";
import {createOrDeleteEntries, useInventoryAreaEntry} from "../../../../Api/inventory-areas-entries";
import {AreaEntryRecord, AreaEntrySchema} from "../../../../Data/area-entry";


export default (props) => useRoutes(subRoutes)(props);

const subRoutes = {
    "/": () => (props) => <Crud config={crudConfig(props)} view="edit" {...props} id={props.parentId}/>,
};

const crudConfig = (props) => ({

    compName: 'inventoryAreaEntries',
    namespace: 'inventoryAreaEntries',
    Record: AreaEntryRecord,
    Schema: AreaEntrySchema,
    api: {
        use: useInventoryAreaEntry,
        edit: createOrDeleteEntries,
    },
    url: props.path[0].url + "/" + props.parentId + '/entries',
    labelField: 'inventoryAreaName',
    ListComponent: List,
    FormComponent: Form,
    setOptRecField: "inventoryArea",
    parentId: props.parentId,
});


