export default {
    "inventoryTickets": "Leltár jegyek",
    "inventoryTicket": "Leltár jegy",
    "itemName": "Eszköz megnevezése",
    "add": "Leltár jegy hozzáadása",
    "edit": "Leltár jegy szerkesztése",
    "remove": "Leltár jegy törlése",
    "task": "Feladat",
    "erpId": "Bizonylat #",
    "inventoryArea": "Leltár körzet",
    "inventoryItem": "Eszköz megnevezése",
    "registeredQuantity": "Ny. (db)",
    "foundQuantity": "F. (db)",
    "registeredFacilityUnit": "Nyilv. lét. egys.",
    "foundFacilityUnit": "Melyik létesítmény egységben találtuk",
    "registeredStatus": "Ny. áll.",
    "translRegisteredStatus": "Ny. áll.",
    "foundStatus": "F. áll.",
    "translFoundStatus": "F. áll.",
    "back": "Vissza a listához",
    "save": "Leltár jegy mentése",
    "action": "Műveletek",
    "id": "Azonosító",
    "ACTIVE": "Aktív",
    "INACTIVE": "Inaktív",
    "registeredFacilityUnitName": "Nyilv. lét. egys.",
    "foundFacilityUnitName": "Fellelt lét. egys.",
    "registeredFacilityUnitErpId": "Nyilv. #",
    "foundFacilityUnitErpId": "Fell. #",
    "inventoryAreaErpId": "Lelt. körz. #",
    "inventoryItemErpId": "Eszköz #",
    "tagSerial": "RFID tag szériaszáma",
    "foundFacilityErpId": "Fellelt lét. #",
    "registeredFacilityErpId": "Nyilv lét. #",
    "foundFacilityName": "Fellelt lét.",
    "registeredFacilityName": "Nyilv. lét.",
    "itemStatus": "Státusz",


};