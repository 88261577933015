import {Record} from 'immutable';
import {date, mixed, number, object, string} from "yup";
import {TaskStatuses, TaskSystems, TaskTypes} from "./StatusAndTypeOfTask";
import {now} from "moment";

export const TaskRecord = Record({
    id: 0,
    name: "",
    responsible: 0,
    authorization: 0,
    type: 'INVENTORY',
    status: 'PLANNING',
    plannedStart: "",
    actualStart: null,
    plannedEnd: "",
    actualEnd: null,
    erpId: '',
    system: 'NSK',
    itemGroup: 0,
    itemCount: 0,
    formattedPlannedStart: "",
    translSystem: "",
    translStatus: "",
    translType: "",
    taskAdmin: 0,
    forrasNetStatus: ""

});

export const TaskSchema = object().shape({
    id: number().required().min(0).default(0),
    name: string().required().min(2).max(120).default(''),
    responsible: number().required().min(1).default(0),
    authorization: number().required().min(1).default(0),
    type: mixed().required().oneOf(TaskTypes).default("INVENTORY"),
    status: mixed().required().oneOf(TaskStatuses).default("PLANNING"),
    plannedStart: date().required().min(new Date(1900, 1, 1)).default(new Date(now())),
    actualStart: date().nullable().default(null),
    plannedEnd: date().required().min(new Date(1900, 1, 1)).default(""),
    actualEnd: date().nullable().default(null),
    erpId: string().nullable().default(''),
    system: mixed().required().oneOf(TaskSystems).default("NSK"),
    itemGroup: number().nullable().default(0),
    itemCount: number().nullable().default(0),
    formattedPlannedStart: string().nullable().default(""),
    translSystem: string().nullable().default(""),
    translStatus: string().nullable().default(""),
    translType: string().nullable().default(""),
    taskAdmin: number().required().min(1).default(0),
    forrasNetStatus: string().nullable().default("")

});