import {filterTable} from "./table-filter";

export const sortColumn = (event, tableStorage, sticky) => {


    const tableId = tableStorage.tableConfig.id;
    const table = sticky ? document.getElementById(`sticky-header-${tableId}`) : document.getElementById(tableId);

    const target = event.target;
    const accessor = target.getAttribute('data-accessor');
    let targetClasses = target.getAttribute('class') || '';
    const sortOrder = targetClasses.includes('stSortAscending') ? 'ascending' : targetClasses.includes('stSortDescending') ? 'descending' : '';

    tableStorage.data = tableStorage.data.sort((a, b) => {
        let result;
        let val1 = sortOrder === 'descending' ? b[accessor] : a[accessor];
        let val2 = sortOrder === 'descending' ? a[accessor] : b[accessor];

        if (typeof val1 === 'number' && typeof val2 === 'number') {
            result = compareNumbers(val1, val2);
        } else if (typeof val1 === 'string' && typeof val2 === 'string') {
            result = compareString(val1.toLowerCase(), val2.toLowerCase());
        }

        return result;
    });

    removeSortCssClasses(table);

    targetClasses = target.getAttribute('class') || '';

    target.setAttribute('class', targetClasses.concat(sortOrder === 'descending' ? ' stSortAscending ' : ' stSortDescending '));

    filterTable(tableStorage);

};

const removeSortCssClasses = table => {
    const headers = table.getElementsByTagName('th');
    for (let th of headers) {
        let classes = th.getAttribute('class') || '';

        if (!classes) continue;

        classes = classes.replace('stSortAscending', '').replace('stSortDescending', '');

        th.setAttribute('class', classes);
    }
};

const compareString = (str1, str2) => {
    if (str1 < str2) return -1;
    else if (str1 > str2) return 1;

    return 0;
};

const compareNumbers = (num1, num2) => {
    return num1 - num2;
};


