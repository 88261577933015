import React from "react";
import List from "./List";
import {useRoutes} from "hookrouter";
import Form from "./Form";
import Crud from "../../../Lib/Crud";
import {RfidTagRecord, RfidTagSchema} from "../../../Data/rfid-tag";
import {addRfidTag, removeRfidTag, updateRfidTag, useRfidTag} from "../../../Api/rfid-tags";
import RfidTagHistoryEntries from "./RfidTagHistoryEntries/RfidTagHistoryEntries";
import {UploadRFIDTags} from "./UploadRFIDTags";
import {ReaderUploadRFIDTags} from "./ReaderUploadRFIDTags";
import TodayUploads from "./TodayUploads";

export default () => useRoutes(subRoutes);

const subRoutes = {
    "/": () => <Crud config={crudConfig} view="list"/>,
    "/add": () => <Crud config={crudConfig} view="add"/>,
    "/upload": () => <Crud config={crudConfig} view="other" OptComponent={UploadRFIDTags} optUrlSectionNLabel={"upload"}/>,
    "/readerUpload": () => <Crud config={crudConfig} view="other" OptComponent={ReaderUploadRFIDTags} optUrlSectionNLabel={"upload"}/>,
    "/TodayUploads": () => <TodayUploads/>,
    "/:id/edit": ({id}) => <Crud config={crudConfig} view="edit" id={id}/>,
    "/:id/remove": ({id}) => <Crud config={crudConfig} view="remove" id={id}/>,
    "/:id/histories*": ({id}) => <Crud config={crudConfig} OptComponent={RfidTagHistoryEntries}
                                       optUrlSectionNLabel='histories' view="other" id={id}/>,
};

const crudConfig = {
    compName: 'Tags',
    namespace: 'tags',
    url: '/mdm/tags',
    labelField: 'serial',
    Record: RfidTagRecord,
    Schema: RfidTagSchema,
    ListComponent: List,
    FormComponent: Form,
    api: {
        use: useRfidTag,
        add: addRfidTag,
        edit: updateRfidTag,
        remove: removeRfidTag
    }
};
