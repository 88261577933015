import {Record} from 'immutable';

export const NskForrasJobRfidRecord = Record({
    id: 0,
    createdAt: '',
    createdBy: '',
    count: 0,
    error: "",
    status: ""

});