export default {
  "inventoryAreaEntries": "Leltár körzet bejegyzések",
  "inventoryAreaEntry": "Leltár körzet bejegyzés",
  "add": "Leltár körzet bejegyzés hozzáadása",
  "edit": "Leltár körzet bejegyzés szerkesztése",
  "remove": "Leltár körzet bejegyzés törlése",
  "inventoryArea": "Leltár körzet",
  "inventoryItem": "Eszköz",
  "facilityUnit": "Létesítmény egység",
  "itemUser": "Használó",
  "back": "Vissza a listához",
  "save": "Leltár körzet bejegyzés mentése",
  "action": "Műveletek",
  "id": "Azonosító",
  "itemUserName": "Felhasználó",
  "unitName": "Létesítmény egység",
  "itemName": "Eszköz",
};