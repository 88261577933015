import {Record} from 'immutable';

export const NskForrasJobRecord = Record({
    id: 0,
    createdAt: '',
    createdBy: '',
    status: '',
    downloadType: '',
    facilityAdded: 0,
    facilityUpdated: 0,
    facilitySkipped: 0,
    facilityError: 0,
    facilityUnitAdded: 0,
    facilityUnitUpdated: 0,
    facilityUnitSkipped: 0,
    facilityUnitError: 0,
    itemUserAdded: 0,
    itemUserUpdated: 0,
    itemUserSkipped: 0,
    itemUserError: 0,
    errors: '',
    inventoryItemAdded: 0,
    inventoryItemUpdated: 0,
    inventoryItemSkipped: 0,
    inventoryItemError: 0,
    itemOwnerAdded: 0,
    itemOwnerUpdated: 0,
    itemOwnerSkipped: 0,
    itemOwnerError: 0
});