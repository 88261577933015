import React, {memo, useState} from "react";
import ContentBox from "../../../Components/ContentBox/ContentBox";
import {useRfidTags} from "../../../Api/rfid-tags";
import {SimplexionTable} from "../../../Lib/simplexion-table/SimplexionTable";
import {customActions} from "../../../Lib/simplexion-table/templates/action-buttons";
import {makeStyles} from "@material-ui/core";
import {FilterField} from "../../../Components/Table/FilterField"
import {makeTableStyles, tableStyles} from "../../../Components/Table/tableStyles"
import debug from "../../../Lib/debug"
import {baseTableStyles} from "../../../Lib/simplexion-table/baseStyle";
import {updatableCheckBoxStyle} from "../../../Lib/simplexion-table/templates/checkbox-input";
import {inputFieldStyles} from "../../../Lib/simplexion-table/templates/text-input";
import LinearProgress from "@material-ui/core/LinearProgress";
import Section from "../../../Lib/form/Components/Section/Section";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import {A} from "hookrouter";


const useTableStyles = makeStyles(makeTableStyles(baseTableStyles, updatableCheckBoxStyle, inputFieldStyles));

const useStylesFilterField = makeStyles(tableStyles);

const List = memo((props) => {

    debug("RENDER", "useForm", () => [" -> List_Of_Rfid_tags", props]);

    const {path, hasAdminRoles} = props;

    const {t} = useTranslation("tags")

    const tableClasses = useTableStyles();

    const classes = useStylesFilterField();

    const urlNs = path[0].url + "/";

    const [state, setState] = useState('');

    const {loadingStatus, data} = useRfidTags();

    const handleFilter = val => setState(val);

    const tableConfig = {
        id: 'tags-table',
        nameSpace: 'tags',
        stickyHeader: true,
        sortable: true,
        styles: tableClasses,
        columns: [
            {
                accessor: 'id',
            },
            {
                accessor: 'serial',
            },
            {
                accessor: 'translStatus',
            },
            {
                accessor: 'action',
                renderer: (...args) => customActions(true, false, urlNs, ...args),
                sortable: true,
            },
        ],
    };

    return (
        <ContentBox path={path}>
            <Section>
                <FilterField
                    handler={handleFilter}
                    buttons={
                        hasAdminRoles ?
                            <div>
                            <A href={urlNs + "upload"}><Button variant="contained" color="primary" size='medium'
                                                               className={classes.button}>{t('uploadTag')}</Button>
                            </A>
                            <A href={urlNs + "readerUpload"}><Button variant="contained" color="primary" size='medium'
                                                               className={classes.button}>{t('readerUploadTag')}</Button>
                            </A>
                            </div>
                        : undefined}/>
                {loadingStatus > 0 ? <LinearProgress/> : null}
                <SimplexionTable tableConfig={tableConfig} data={data} loadingStatus={loadingStatus}
                                 filterValue={state}/>
            </Section>
        </ContentBox>
    );
});

export default List;
