import React, {memo, useState} from "react";
import ContentBox from "../../../Components/ContentBox/ContentBox";
import {useInventoryItemsInArea} from "../../../Api/inventory-items";
import {SimplexionTable} from "../../../Lib/simplexion-table/SimplexionTable";
import debug from "../../../Lib/debug";
import {customActions} from "../../../Lib/simplexion-table/templates/action-buttons";
import {FilterField} from "../../../Components/Table/FilterField";
import {makeStyles} from "@material-ui/core";
import {makeTableStyles} from "../../../Components/Table/tableStyles";
import {baseTableStyles} from "../../../Lib/simplexion-table/baseStyle";
import {updatableCheckBoxStyle} from "../../../Lib/simplexion-table/templates/checkbox-input";
import {inputFieldStyles} from "../../../Lib/simplexion-table/templates/text-input";
import Grid from "@material-ui/core/Grid";
import SelectedInventoryArea from "./SelectedInventory";
import LinearProgress from "@material-ui/core/LinearProgress";
import Section from "../../../Lib/form/Components/Section/Section";
import {useTranslation} from "react-i18next";


const hasTag = (accessor, record, numberOfMainTag, numberOfSubTag, helperText) => {
    switch (record.hasRFIDTag) {
        case true:
            record.hasAccessories ? numberOfMainTag() : numberOfSubTag()
            return `<div style="padding-left: 1rem"><span title="${helperText.concat(record.tagSerial)}"><i class="material-icons">online_prediction</i></span></div>`
        default:
            return ``;
    }
}

const hasAccessories = (accessor, record, numberOfTag) => {
    numberOfTag()
    switch (record.hasAccessories) {
        case true:
            return `<div style="padding-left: 1rem"><span><i class="material-icons">done</i></span></div>`
        default:
            return ``;
    }
}

const useTableStyles = makeStyles(makeTableStyles(baseTableStyles, updatableCheckBoxStyle, inputFieldStyles));

const List = memo((props) => {

    const {path, config, hasAdminRoles} = props;

    const [state, setState] = useState('');

    const {t} = useTranslation("inventoryItems")

    const urlNs = path[0].url + "/";

    debug("RENDER", "useForm", () => [" -> List_Of_Inventory_Items", props]);

    const tableClasses = useTableStyles();

    const {loadingStatus, data} = useInventoryItemsInArea({inventoryArea: config.optValueOfOptRecField}); // filtering to an inventory-area

    const handleFilter = val => setState(val);

    const onSelectedArea = id => window.location.search = "inventory-area=" + id + "/"

    const [mainItems, setMainItems] = useState(0)
    const [tagCounterForMainItems, setTagCounterForMainItems] = useState(0)

    const [subItems, setSubItems] = useState(0)
    const [tagCounterForSubItems, setTagCounterForSubItems] = useState(0)

    const increaseMainItemCounter = () => setMainItems(prevState => prevState + 1)
    const increaseMainRfidCounter = () => setTagCounterForMainItems(prevState => prevState + 1)

    const increaseSubItemCounter = () => setSubItems(prevState => prevState + 1)
    const increaseSubRfidCounter = () => setTagCounterForSubItems(prevState => prevState + 1)


    const helperText = t("helperText")

    const tableConfig = {
        id: 'inventory-items-table',
        nameSpace: 'inventoryItems',
        stickyHeader: true,
        sortable: true,
        styles: tableClasses,
        columns: [
            {
                accessor: 'name',
            },
            {
                accessor: 'hasAccessories',
                sortable: false,
                renderer: (accessor, record) => hasAccessories(accessor, record, record.hasAccessories ? increaseMainItemCounter : increaseSubItemCounter)
            },
            {
                accessor: 'erpIds',
            },
            {
                accessor: 'facilityUnitErpIds',
            },
            {
                accessor: 'facilityUnit',
            },
            {
                accessor: 'hasRFIDTag',
                sortable: false,
                renderer: (accessor, record) => hasTag(accessor, record, increaseMainRfidCounter, increaseSubRfidCounter, helperText)
            },
            {
                accessor: 'translStatus',
            },
            {
                accessor: 'action',
                renderer: (...args) => customActions(true, false, urlNs, ...args),
                sortable: true,
            },
        ],
    };

    return (
        <div>
            <ContentBox path={path} add={hasAdminRoles ? (urlNs + "add") : null}>
                <Section>
                    <Grid container direction="row" justify="space-between" alignItems="center">
                        <Grid item={true}>
                            <FilterField handler={handleFilter}/>
                        </Grid>
                        <Grid item={true}>
                            <Grid style={{textAlign: "center"}}>
                                <p>{t("textOfTagCounterForMainItem").replace("{mainCount}", mainItems).replace("{rfidMainCounter}", tagCounterForMainItems)}</p>
                                <p>{t("textOfItemCounter").replace("{subItemCount}", subItems).replace("{rfidSubCounter}", tagCounterForSubItems)}</p>
                            </Grid>
                        </Grid>
                        <Grid item={true}>
                            <SelectedInventoryArea inventoryArea={onSelectedArea} area={config.optValueOfOptRecField}/>
                        </Grid>
                    </Grid>
                    {loadingStatus > 0 ? <LinearProgress/> : null}
                    <SimplexionTable tableConfig={tableConfig} data={data} loadingStatus={loadingStatus}
                                     filterValue={state}/>
                </Section>
            </ContentBox>
        </div>
    );
});

export default List;


