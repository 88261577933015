import React from "react";
import {useTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import {A} from 'hookrouter';
import Button from "@material-ui/core/Button";

export function Navigation(props) {

    const {parentId} = props;

    const {t} = useTranslation('groups');

    return (
        <Grid container direction="column">
            <Grid item xs={12}>
                <A href={"/admin/groups/" + parentId + "/memberships"}>
                    <Button variant="contained" color="primary" size="small">{t('memberships')}</Button>
                </A>
            </Grid>
        </Grid>
    );
}