import {Record} from 'immutable';
import {number, object, string} from "yup";

export const FacilityTypeRecord = Record({
    id: 0,
    name: ""
});

export const FacilityTypeSchema = object().shape({
    id: number().required().min(0).default(0),
    name: string().required().min(2).max(60).default(''),
});
