import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../Lib/form/useForm";
import ValidatedInput from "../../Lib/form/Components/ValidatedInput/ValidatedInput";
import Card from "../../Lib/material-dashboard/components/Card/Card";
import CardBody from "../../Lib/material-dashboard/components/Card/CardBody";
import ValidatedSubmit from "../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import {useSnackbar} from "notistack";
import Grid from "@material-ui/core/Grid";
import accountStyle from "./accountStyle";
import {makeStyles} from "@material-ui/styles";
import FormProvider from "../../Lib/form/Components/FormProvider/FormProvider";
import {PasswordRecord, PasswordSchema} from "../../Data/account";
import {updateCredentials} from "../../Api/accounts";
import {closeSnack} from "../../Main/util";
import {navigate} from "hookrouter";
import Button from "../../Lib/material-dashboard/components/CustomButtons/Button";
import {AuthContext} from "../../Lib/auth/AuthContext";
import {roles} from "../../Main/roles";

const useStyles = makeStyles(accountStyle);

/**
 * Checks if the password verification is the same as password.
 */
export const passwordValidator = (fieldName, fieldValue, fieldValues) => {
    return fieldValues["newPassword"] === fieldValue;
};

const PasswordChange = ({id}) => {

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const authContext = useContext(AuthContext);

    const hasAdmin = authContext.getIn(['session', 'roles']).some(role => [roles.BUSINESS_ADMIN, roles.SECURITY_OFFICER.toLowerCase()].indexOf(role) !== -1);

    const api = (record, onSuccess, onError) => {
        updateCredentials(id, record, onSuccess, onError)
    };

    const cbSuccess = () => {
        enqueueSnackbar(
            t('password-change-success-explanation'),
            {
                style: {whiteSpace: 'pre-line'},
                variant: 'success',
                persist: true,
                action: (key) => closeSnack(t, closeSnackbar, key)
            });
        navigate("./edit")
    };

    const cbError = () => {
        enqueueSnackbar(
            t('password-change-error'),
            {variant: 'warning', persist: true, action: (key) => closeSnack(t, closeSnackbar, key)});
    };

    const {t} = useTranslation("accounts");

    // noinspection JSPotentiallyInvalidConstructorUsage
    const form = useForm({
        recordType: PasswordRecord,
        recordSchema: PasswordSchema,
        namespace: 'accounts',
        record: hasAdmin ? new PasswordRecord().set("oldPassword", 12345678) : new PasswordRecord()
    });

    const classes = useStyles();

    return (
        <div className={classes.panel}>
            <Card className={classes.container}>
                <CardBody>
                    <Grid container justify="center">

                        <Grid item xs={12}>
                            <div className={classes.title}>{t('password-change-title')}</div>
                            <p>{t('password-change-explanation')}</p>
                        </Grid>

                        <Grid item xs={12}>
                            <FormProvider form={form} formClassName={classes.form}>
                                {!hasAdmin && <ValidatedInput name="oldPassword" password/>}
                                <ValidatedInput name="newPassword" password/>
                                <ValidatedInput name="verification" password validator={passwordValidator}/>
                                <Grid item container justify="space-between" style={{marginTop: "1.5rem"}}>
                                    <Grid item>
                                        <Button
                                            color={"primary"}
                                            style={{backgroundColor: "#0c427d"}}
                                            onClick={() => navigate("./edit")}
                                        >
                                            {t('general:back')}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <ValidatedSubmit
                                            hideCancel
                                            api={api}
                                            submitLabel={t('password-change-button')}
                                            onSuccess={cbSuccess}
                                            onError={cbError}
                                        />
                                    </Grid>
                                </Grid>
                            </FormProvider>
                        </Grid>

                    </Grid>
                </CardBody>
            </Card>
        </div>
    );
};

export default PasswordChange;