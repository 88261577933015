import baseFetch, {useFetchMany} from "../Lib/baseFetch";
import {NskForrasJobRfidRecord} from "../Data/nsk-forras-job-rfid";

export function useNskForrasJobRfids(filter, revision) {
    return useFetchMany('/nsk-forras-job-rfids', filter, NskForrasJobRfidRecord, revision);
}

export function addNskForrasJobRfid(onSuccess, onError) {
    baseFetch('POST', '/nsk-forras-job-rfids', {},
        onSuccess,
        onError
    );
}