import baseFetch, {useFetchMany} from "../Lib/baseFetch";
import {ViewsListRecord} from "../Data/viewslist";


export function useViewsList(filter) {
    return useFetchMany('/viewslist', undefined, ViewsListRecord);
}

export function getViewsList(viewsList, onSuccess, onError) {
    baseFetch('GET', '/viewslist/' + viewsList.id, undefined,
        data => {
            if (onSuccess) onSuccess(data);
        },
        onError
    );
}
