import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../../../Lib/form/useForm";
import {RfidTagHistoryEntryRecord, RfidTagHistoryEntrySchema} from "../../../../Data/rfid-tag-history-entry";
import ContentBox from "../../../../Components/ContentBox/ContentBox";
import Section from "../../../../Lib/form/Components/Section/Section";
import ValidatedSubmit from "../../../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import debug from "../../../../Lib/debug";
import ValidatedSelect from "../../../../Lib/form/Components/ValidatedSelect/ValidatedSelect";
import {useRfidTags} from "../../../../Api/rfid-tags";
import Grid from "@material-ui/core/Grid";
import ValidatedRadio from "../../../../Lib/form/Components/ValidatedRadio/ValidatedRadio";
import ValidatedRadioGroup from "../../../../Lib/form/Components/ValidatedRadio/ValidatedRadioGroup";
import {useTasks} from "../../../../Api/tasks";
import {useFacilityUnits} from "../../../../Api/facility-units";
import ValidatedFilteredSelect from "../../../../Lib/form/Components/ValidatedFilteredSelect/ValidatedFilteredSelect";
import {useInventoryItems} from "../../../../Api/inventory-items";
import {RfidTagStatus} from "../../../../Data/RfidTagHistoryType";
import ValidatedInput from "../../../../Lib/form/Components/ValidatedInput/ValidatedInput";


export default memo(({id, record, path, view, api}) => {
    const {t} = useTranslation("tagHistoryEntries");

    const hasAdminRoles = false //this parameter is also obtained from the properties

    const form = useForm({
        recordType: RfidTagHistoryEntryRecord,
        recordSchema: RfidTagHistoryEntrySchema,
        namespace: 'tagHistoryEntries',
        record: record

    });

    debug("RENDER", "useForm", () => [" -> Form_Of_Rfid_Tag_History_Entry", view, id, path, record]);

    return (
        <ContentBox path={path} form={form}>

            <Section title={t('tagHistoryEntry')}>
                <ValidatedSelect name="tag" api={useRfidTags} recordFieldName={"serial"} disabled={!hasAdminRoles}/>
                <ValidatedSelect name="task" api={useTasks} disabled={!hasAdminRoles} nullable/>
                <ValidatedFilteredSelect name="inventoryItem" api={useInventoryItems} nullable
                                         disabled={!hasAdminRoles}/>
                <ValidatedSelect name="facilityUnit" api={useFacilityUnits} disabled={!hasAdminRoles} nullable/>
                <ValidatedInput name="notes" disabled={!hasAdminRoles} multiline={true} rows={5}/>
                <ValidatedRadioGroup name="status">
                    <Grid container>
                        {view === "add" ? RfidTagStatus.map(a => <ValidatedRadio name="status" value={a} key={a}/>) :
                            <ValidatedRadio name="status" value={record.status}/>}
                    </Grid>
                </ValidatedRadioGroup>
            </Section>

            <ValidatedSubmit view={view} api={api} hideSave={!hasAdminRoles}/>

        </ContentBox>
    );
})