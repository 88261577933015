import {Record} from 'immutable';
import {number, object, string} from "yup";

export const MembershipRecord = Record({
    id: 0,
    groupId: 0,
    principalId: 0,
    principalName: null,
    fullName: null,
    emailAddress: null
});

export const MembershipSchema = object().shape({
    id: number().required().min(0).default(0),
    groupId: number().required().min(1).default(0),
    principalId: number().required().min(1).default(0),
    principalName: string().nullable().default(null),
    fullName: string().nullable().default(null),
    emailAddress: string().email().nullable().default(null),
});