import baseFetch, {useFetchMany, useFetchOne} from "../Lib/baseFetch";
import {RfidTagRecord, RfidTagSchema} from "../Data/rfid-tag";
import {RfidTagHistoryEntryRecord, RfidTagHistoryEntrySchema} from "../Data/rfid-tag-history-entry";

export function useRfidTags(filter) {
    return useFetchMany('/rfid-tags', undefined, RfidTagRecord);
}

export function useRfidTag(id) {
    return useFetchOne('/rfid-tags', id, RfidTagRecord, RfidTagSchema);
}

export function addRfidTag(rfidTag, onSuccess, onError) {
    baseFetch('POST', '/rfid-tags', rfidTag,
        data => {
            if (onSuccess) onSuccess(new RfidTagRecord(data))
        },
        onError
    );
};

export function updateRfidTags(serialNumberArray, onSuccess, onError) {
    baseFetch('POST', '/upload-rfid-tags', serialNumberArray,  onSuccess, onError);
};

export function useRfidTagTodayUploads(parentId) {
    return useFetchMany('/rfid-tags/todayUploads', undefined, RfidTagHistoryEntryRecord);
}

export function updateRfidTag(rfidTag, onSuccess, onError) {
    baseFetch('PATCH', '/rfid-tags/' + rfidTag.get('id'), rfidTag, onSuccess, onError);
}

export function removeRfidTag(id, onSuccess, onError) {
    baseFetch('DELETE', '/rfid-tags/' + id, undefined, onSuccess, onError);
}

export function useRfidTagHistoryEntries(parentId) {
    return useFetchMany('/rfid-tags/' + parentId + '/histories', undefined, RfidTagHistoryEntryRecord);
}

export function useRfidTagHistoryEntry(parentId, id) {
    return useFetchOne('/rfid-tags/' + id + '/histories', id, RfidTagHistoryEntryRecord, RfidTagHistoryEntrySchema);
}

export function addRfidTagHistoryEntry(rfidTagHistoryEntry, onSuccess, onError) {
    baseFetch('POST', '/rfid-tags/' + rfidTagHistoryEntry.get("tag") + '/histories', rfidTagHistoryEntry,
        data => {
            if (onSuccess) onSuccess(new RfidTagHistoryEntryRecord(data))
        },
        onError
    );
}

export function updateRfidTagHistoryEntry(rfidTagHistoryEntry, onSuccess, onError) {
    baseFetch('PATCH', '/rfid-tags/' + rfidTagHistoryEntry.get('tag') + '/histories/' + rfidTagHistoryEntry.get('id'), rfidTagHistoryEntry, onSuccess, onError);
}

export function removeRfidTagHistoryEntry(rfidTagHistoryEntry, onSuccess, onError) {
    baseFetch('DELETE', '/rfid-tags/' + rfidTagHistoryEntry.get('tag') + '/histories/' + rfidTagHistoryEntry.get('id'), undefined, onSuccess, onError);
}