import React, {memo, useState} from "react";
import ContentBox from "../../../Components/ContentBox/ContentBox";
import {makeStyles} from "@material-ui/core";
import {makeTableStyles} from "../../../Components/Table/tableStyles";
import {baseTableStyles} from "../../../Lib/simplexion-table/baseStyle";
import {updatableCheckBoxStyle} from "../../../Lib/simplexion-table/templates/checkbox-input";
import {inputFieldStyles} from "../../../Lib/simplexion-table/templates/text-input";
import debug from "../../../Lib/debug";
import {FilterField} from "../../../Components/Table/FilterField";
import {SimplexionTable} from "../../../Lib/simplexion-table/SimplexionTable";
import {useTaskFacilityUnits} from "../../../Api/task-facility-units";
import LinearProgress from "@material-ui/core/LinearProgress";
import Section from "../../../Lib/form/Components/Section/Section";


const useTableStyles = makeStyles(makeTableStyles(baseTableStyles, updatableCheckBoxStyle, inputFieldStyles));

const List = memo((props) => {

    debug("RENDER", "useForm", () => [" -> List_Of_Task_Facility_Units", props]);

    const {path, parentId} = props;

    const {loadingStatus, data} = parentId ? useTaskFacilityUnits(parentId) : [];

    const tableClasses = useTableStyles();

    const [state, setState] = useState('');

    const handleFilter = val => setState(val);

    const tableConfig = {
        id: 'task-facility-units-table',
        nameSpace: 'taskFacilityUnits',
        stickyHeader: true,
        sortable: true,
        styles: tableClasses,
        columns: [
            {accessor: 'id'},
            {accessor: 'facilityUnitErpId'},
            {accessor: 'facilityUnitName'},
            {
                accessor: 'finished',
                renderer: (accessor, record) => record.finished ? `<span<i class="material-icons">done</i></span>` : `<span><i class="material-icons">cancel</i></span>`,
            },
            {
                accessor: 'approved',
                renderer: (accessor, record) => record.approved ? `<span<i class="material-icons">done</i></span>` : `<span><i class="material-icons">cancel</i></span>`,
            },
        ],
    };

    return (
        <ContentBox path={path}>
            <Section>
                <FilterField handler={handleFilter}/>
                {loadingStatus > 0 ? <LinearProgress/> : null}
                <SimplexionTable tableConfig={tableConfig} data={data} loadingStatus={loadingStatus}
                                 filterValue={state}/>
            </Section>
        </ContentBox>
    );
});

export default List;

