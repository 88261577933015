import React from "react";
import List from "./List";
import {useRoutes} from "hookrouter";
import Crud from "../../../Lib/Crud";
import {InventoryAreaRecord, InventoryAreaSchema} from "../../../Data/inventory-area";
import {
    addInventoryArea,
    removeInventoryArea,
    updateInventoryArea,
    useInventoryArea
} from "../../../Api/inventory-areas";
import Form from "./Form";
import InventoryAreaEntries from "./InventoryAreaEntries/InventoryAreaEntries";

export default () => useRoutes(subRoutes);

const subRoutes = {
    "/": () => <Crud config={crudConfig} view="list"/>,
    "/add": () => <Crud config={crudConfig} view="add"/>,
    "/:id/edit": ({id}) => <Crud config={crudConfig} view="edit" id={id}/>,
    "/:id/remove": ({id}) => <Crud config={crudConfig} view="remove" id={id}/>,
    "/:id/entries*": ({id}) => <Crud config={crudConfig} OptComponent={InventoryAreaEntries}
                                     optUrlSectionNLabel='entries' view="other" id={id}/>,
};

const crudConfig = {

    compName: 'inventoryAreas',
    namespace: 'inventoryAreas',
    Record: InventoryAreaRecord,
    Schema: InventoryAreaSchema,
    api: {
        use: useInventoryArea,
        add: addInventoryArea,
        edit: updateInventoryArea,
        remove: removeInventoryArea
    },
    url: '/areas',
    labelField: 'name',
    ListComponent: List,
    FormComponent: Form,

};
