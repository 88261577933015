export default {
    "tagHistoryEntries": "Tag előzmények",
    "tagHistoryEntry": "Tag előzmény",
    "add": "Tag előzmény hozzáadása",
    "edit": "Tag előzmény szerkesztése",
    "remove": "Tag előzmény törlése",
    "back": "Vissza a listához",
    "save": "Tag előzmény mentése",
    "action": "Műveletek",
    "id": "Azonosító",
    "parentUnit": "Tag előzmény amelyikhez tartozik",
    "tag": "Tag",
    "tagSerial": "Tag",
    "task": "Feladat",
    "taskName": "Feladat",
    "status": "Státusz",
    "translStatus": "Státusz",
    'inventoryItem': "Eszköz",
    'inventoryItemName': "Eszköz",
    'facilityUnit': "Létesítmény egység",
    'facilityUnitName': "Létesítmény egység",
    'UNASSIGNED': "Nincs hozzárendelve",
    'ASSIGNED': "Hozzárendelve",
    'EXPIRED': "Lejárt",
    'LOST': "Elveszett",
    "notes": "Megjegyzés",
};