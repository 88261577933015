export default {
    "nskForrasJobs": "Forrás áttöltések",
    "id": "Belső #",
    "createdAt": "Indítás",
    "status": "Állapot",
    "facilityAdded": "Átvett tároló ingatlan",
    "facilityUpdated": "@",
    "facilitySkipped": "?",
    "facilityError": "Hibás tároló ingatlan",
    "facilityUnitAdded": "Átvett leltár körzet",
    "facilityUnitUpdated": "@",
    "facilityUnitSkipped": "?",
    "facilityUnitError": "Hibás leltár körzet",
    "itemUserAdded": "Átvett leltározó személy",
    "itemUserUpdated": "@",
    "itemUserSkipped": "?",
    "itemUserError": "Hibás leltározó személy",
    "errors": "Hibák",
    "inventoryItemAdded": "Átvett eszköz",
    "inventoryItemUpdated": "@",
    "inventoryItemSkipped": "?",
    "inventoryItemError": "Hibás eszköz",
    "itemOwnerAdded": "Átvett felelős szerv",
    "itemOwnerUpdated": "@",
    "itemOwnerSkipped": "?",
    "itemOwnerError": "Hibás  felelős szerv",
    "createdBy": "Inditó",
    "changes": "Változások",
    "complete": "Teljes",
    "downloadType": "Lekérdezés tipusa"
};