import baseFetch, {useFetchMany, useFetchOne} from "../Lib/baseFetch";
import {InventoryItemRecord} from "../Data/inventory-item";
import {GroupEntryRecord, GroupEntrySchema} from "../Data/group-entry";

export function createOrDeleteEntries(inventoryGroupEntry, onSuccess, onError) {
    baseFetch('PUT', '/inventory-groups/' + inventoryGroupEntry.get("group") + '/entries', inventoryGroupEntry, onSuccess, onError);
}

export function useInventoryItemGroupEntry(parentId) {
    return useFetchOne('/inventory-groups/' + parentId + '/entries', 0, GroupEntryRecord, GroupEntrySchema);
}

export function useSearchForItemsInTheGroup(filter) {
    return useFetchMany('/inventory-groups-items', filter, InventoryItemRecord);
}