export default {
    "inventoryItem": "Eszköz",
    "inventoryItems": "Eszközök",
    "add": "Eszköz hozzáadaása",
    "edit": "Eszköz szerkesztése",
    "remove": "Eszköz törlése",
    "title": "Eszközök",
    "name": "Eszköz megnevezése",
    "name-invalid": "Érvénytelen mező, legalább 2, legfeljebb 120 karakternek kell lennie.",
    "facility": "Létesítmény",
    "facilityUnitErpIds": "Lét egys. #",
    "facilityUnit": "Létesítmény egység",
    "inventoryArea": "Leltárkörzet",
    "unit": "Egység",
    "quantity": "Mennyiség",
    "quantity-invalid": "Érvénytelen a megadott formátum, csak szám formátum engedélyezett",
    "lastInventoryTime": "Leltárba vétel utolsó időpontja",
    "formattedLastInventoryTime": "Leltárba vétel utolsó időpontja",
    "lastInventoryTime-invalid": "Érvénytelen dátum formátum.",
    "lastInventoryAccount": "Utolsó leltározó neve",
    "lastInventoryAccount-invalid": "Érvénytelen mező",
    "description": "Leírás",
    "description-invalid": "Érvénytelen mező, legalább 1, legfeljebb 10000 karakternek kell lennie.",
    "tag": "RFID tag",
    "hasRFIDTag": "RFID tag",
    "picture": "Kép",
    "pictures": "Képek",
    "pictures-invalid": "Érvénytelen mező; legfeljebb 3db kép tartozhat az eszközhöz.",
    "back": "Vissza a listához",
    "save": "Eszköz mentése",
    "action": "Műveletek",
    "id": "Azonosító",
    "idAndMainPartId": "Azonosító",
    "status": "Státusz",
    "translStatus": "Státusz",
    "ACTIVE": "Aktív",
    "INACTIVE": "Inaktív",
    "erpId": "Eszköz/főeszköz Forrás.net azonosítója",
    "erpIds": "Eszköz #",
    "note": "Megjegyzés",
    "itemOwner": "Tulajdonos",
    "mainPart": " Főeszköz",
    'itemUser': "Aki használja",
    "barCodes": "Vonalkód",
    "hasAccessories": "Főeszköz",
    "erpIdAccessory": "Tartozék Forrás.net azonosítója",
    "locked": "Leltározás alatt van",
    "histories": "Előzmények",
    "relatedItems": "Hozzátartozó tartozékok",
    "true": "Igen",
    "false": "Nem",
    "list": "Tartozék lista",
    "chose": "választás",
    "pleaseChooseOneFromTheSet": " Kérem válaszon leltárkörzetett",
    "itemCategory": "Eszköz kategória",
    "**unknown-name**": "**ismeretlen-név**",
    "**unknown**": "***  ismeretlen  ***",
    "workInProgress": "Leltár feladatban részvevő, zárolt eszköz.",
    "listOfItemsFound": "Talált eszközök",
    "navigationNameOfItem": "Eszközök kezelése",
    "scrappingText": "Ez az eszköz SELEJTEZÉSRE lett javasolva.",
    "waste": "Selejtezésre javasolt",
    "inCatalogText": "Ez az eszköz törzslapon nyilvántartott.",
    "catalog": "Törzslapon szereplő RFID tag",
    "textOfTagCounterForMainItem": "Eszköz: {mainCount} db; RFID-val: {rfidMainCounter} db",
    "textOfItemCounter": "Tartozék: {subItemCount} db; RFID-val: {rfidSubCounter} db",
    "helperText": "Az eszköz rendelkezik RFID taggel. \nTag szériaszáma: "
};