export const GroupHu = {
    "group": "Csoport",
    "groups": "Csoportok",
    "id": "Id",
    "name": "Név",
    "name-invalid": "Érvénytelen mező, legalább 1, legfeljebb 100 karakternek kell lennie.",
    'memberships': 'Tagok',
    "memberCount": "Tagok száma",
    "description": "Leírás",
    "description-invalid": "Érvénytelen mező, legalább 1, legfeljebb 10000 karakternek kell lennie.",
    "locked": "Lezárt",
    "action": "Művelet",
    "back": "Vissza a listához",
    "save": "Csoport mentése",
    "add": "Csoport hozzáadása",
};

export const GroupEn = {
    "group": "Group",
    "groups": "Groups",
    "id": "Id",
    "name": "Name",
    "memberCount": "Number of members",
    "description": "Description",
    "locked": "Locked",
    "actions": "Actions",
    "action": "Action",
};