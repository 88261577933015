import baseFetch, {useFetchMany, useFetchOne} from "../Lib/baseFetch";
import {ItemOwnerRecord, ItemOwnerSchema} from "../Data/item-owner";

export function useItemOwners(filter) {
    return useFetchMany('/item-owners', undefined, ItemOwnerRecord);
}

export function useItemOwner(id) {
    return useFetchOne('/item-owners', id, ItemOwnerRecord, ItemOwnerSchema);
}

export function addItemOwner(itemOwner, onSuccess, onError) {
    baseFetch('POST', '/item-owners', itemOwner,
        data => {
            if (onSuccess) onSuccess(new ItemOwnerRecord(data))
        },
        onError
    );
};

export function updateItemOwner(itemOwner, onSuccess, onError) {
    baseFetch('PATCH', '/item-owners/' + itemOwner.get('id'), itemOwner, onSuccess, onError);
}

export function removeItemOwner(id, onSuccess, onError) {
    baseFetch('DELETE', '/item-owners/' + id, undefined, onSuccess, onError);
}
