import {Record} from 'immutable';
import {number, object, string} from "yup";

export const SystemSettingRecord = Record({
    id: 0,
    name: "",
    value: "",
    description: ""
});

export const SystemSettingSchema = object().shape({
    id: number().required().min(0).default(0),
    name: string().required().min(1).max(100).default(''),
    value: string().required().min(1).max(1000).default(''),
    description: string().nullable().max(10000).default(''),

});