import React, {useState} from "react";
import Box from "@material-ui/core/Box";
import {Input, makeStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {tableStyles} from "./tableStyles";
import {useTranslation} from "react-i18next";


const useStylesFilterField = makeStyles(tableStyles);


export const FilterField = ({handler, buttons}) => {

    const [state, setState] = useState('');

    const {t} = useTranslation('general');

    const classes = useStylesFilterField();

    const handleChange = event => setState(event.target.value);

    const handleEnter = event => {
        if (event.key === "Enter") handler(state);
    };

    const handleClick = () => handler(state);

    return (
        <Box className={classes.filterField}>
            <Input className={classes.filterInputField}
                   value={state}
                   onChange={handleChange}
                   onKeyPress={handleEnter}/>
            <Button onClick={handleClick} variant="contained" color="primary" size='small' className={classes.button}>{t('search')}</Button>
            {buttons}
        </Box>
    )
};