import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../../Lib/form/useForm";
import ContentBox from "../../../Components/ContentBox/ContentBox";
import Section from "../../../Lib/form/Components/Section/Section";
import ValidatedInput from "../../../Lib/form/Components/ValidatedInput/ValidatedInput";
import ValidatedSubmit from "../../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import {InventoryItemGroupRecord, InventoryItemGroupSchema} from "../../../Data/inventory-item-group";
import debug from "../../../Lib/debug";
import {Navigation} from "./Navigation";

export default memo(({id, record, path, view, api}) => {

    const {t} = useTranslation("inventoryItemGroups");

    const form = useForm({
        recordType: InventoryItemGroupRecord,
        recordSchema: InventoryItemGroupSchema,
        namespace: 'inventoryItemGroups',
        record
    });

    debug("RENDER", "useForm", () => [" -> Form_Of_Inventory_Item_group", view, id, path, record]);

    return (
        <ContentBox path={path} form={form}>
            {view === "add" ? null : <Navigation parentId={id} path={path}/>}

            <Section title={t('inventoryItemGroup')}>
                <ValidatedInput name="name"/>
                <ValidatedInput name="query"/>
            </Section>

            <ValidatedSubmit view={view} api={api}/>

        </ContentBox>
    );
})