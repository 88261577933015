import React from "react";
import List from "./List";
import {useRoutes} from "hookrouter";
import Form from "./Form";
import Crud from "../../../Lib/Crud";
import {InventoryTicketRecord, InventoryTicketSchema} from "../../../Data/inventory-ticket";
import {
    addInventoryTicket,
    removeInventoryTicket,
    updateInventoryTicket,
    useInventoryTicket
} from "../../../Api/inventory-tickets";

export default (props) => useRoutes(subRoutes)(props);

const subRoutes = {
    "/": () => (props) => <Crud config={crudConfig(props)} view="list" {...props}/>,
    "/add": () => (props) => <Crud config={crudConfig(props)} view="add" {...props}/>,
    "/:id/edit": ({id}) => (props) => <Crud config={crudConfig(props)} view="edit" id={id} {...props}/>,
    "/:id/remove": ({id}) => (props) => <Crud config={crudConfig(props)} view="remove" id={id} {...props}/>

};

const crudConfig = (props) => ({

    compName: 'inventoryTickets',
    namespace: 'inventoryTickets',
    Record: InventoryTicketRecord,
    Schema: InventoryTicketSchema,
    api: {
        use: useInventoryTicket,
        add: addInventoryTicket,
        edit: updateInventoryTicket,
        remove: removeInventoryTicket
    },
    url: props.path[0].url + "/" + props.parentId + '/inventory-tickets',
    labelField: 'id',
    ListComponent: List,
    FormComponent: Form,
    setOptRecField: "task",
    parentId: props.parentId,
});


