const supportEmail = "support@mnsk.hu"; // FIXME

export default {
    "login": "Belépés",
    "password": "Jelszó",
    "error": "Hiba, kérlek írj a " + supportEmail + " címre.",
    "error404": "Érvénytelen felhasználó azonosító és / vagy jelszó.",
    "error406": "Zárolt fiók, kérlek írj a " + supportEmail + " címre, hogy feloldjuk a zárolást.",
    "error412": "Inaktív fiók, kérlek aktiváld a fiókot (ment róla e-mail) vagy írj a " + supportEmail + " címre.",
    "forgottenPassword": "Elfelejtett jelszó",
    "password-invalid": "A jelszónak legalább 8 karakter hosszúnak kell lennie",
    "oldPassword-invalid": "A jelszónak legalább 8 karakter hosszúnak kell lennie",
    "newPassword-invalid": "A jelszó legalább 8 karakter hosszú kell legyen.",

    "add": "Regisztráció",
    "edit": "Felhasználó szerkesztése",

    "invalid-fields": "Érvényelen mezők",
    "invalid-fields-explanation": "Az alábbi mezők tartalma még nem megfelelő, a regisztrációt csak akkor tudod beküldeni, ha ezeket az adatokat helyesen megadtad.",
    "existing-name": "Ez a felhasználó azonosító már foglalt. Ha korábban regisztráltál egy ilyen nevű fiókot, kérlek használd az elfelejtett jelszó funkciót vagy írj a " + supportEmail + " címre.",
    "close": "bezár",

    "principalName": "Felhasználó azonosító",
    "principalName-invalid": "A felhasználó azonosítónak legalább 3, legfeljebb 100 karakter hosszúnak kell lennie.",
    "emailAddress": "E-mail cím",
    "emailAddress-invalid": "Érvénytelen cím.",
    "credentials": "Jelszó",
    "credentials-invalid": "Legalább 8 karakter.",
    "verification": "Jelszó megerősítése",
    "verification-invalid": "A két jelszó nem azonos.",
    "fullName": "Név",
    "fullName-invalid": "Legalább 5 karakter.",
    "address": "Lakcím",
    "address-invalid": "Legalább 12 karakter.",

    "displayName": "Megjelenési név",
    "language": "Nyelv",
    "postCode": "Irányítószám",
    "postCode-invalid": "Minimum 2 karaktert kell tartalmaznia.",
    "phoneNumber": "Telefonszám",
    "phoneNumber-invalid": "Minimum 5 karakternek kell lennie.",
    "contactName": "Központ / Létesítmény neve",
    "contactName-invalid": "Minimum 5 karaktert kell tartalmaznia.",
    "contactPhone": "Központ / Létesítmény telefonszáma",
    "contactPhone-invalid": "Érvénytelen telefonszám",
    "contactMail": "Központ / Létesítmény e-mail címe",
    "contactMail-invalid": "Érvénytelen e-mail cím.",
    "job": "Munkakör",
    "job-invalid": "Minimum 2 karaktert kell tartalmaznia.",
    "back": "Vissza",
    "save": "Felhasználó mentése",
    "id": "Azonosító",
    "action": "Műveletek",
    "status": "Státusz",
    "translStatus": "Státusz",
    "ACTIVE": "Aktív",
    "INACTIVE": "Inaktív",
    "accounts": "Felhasználók",
    "facility-accesses": "Elérhető létesítmények",
    "enabled": "Engedélyezés",

    "add-success": "Sikeres regisztráció",
    "successful-login": 'Sikeres bejelentkezés',

    "account-fields": "Felhasználó adatai",
    "account-fields-explanation": "Az itt megadott fióknevet és jelszót kell majd megadnod a belépésnél. A Felhasználó azonosító nem látszik a többi felhasználó számára. Kérlek ne hozz létre több fiókot egy személyhez, ha nem tudsz belépni egy korábban létrehozott fiókkal, írj a " + supportEmail + " email címre.",
    "required-fields": "Kötelező adatok",
    "required-fields-explanation": "Ezeket az adatokat mindenkinek meg kell adnia.",

    "reset-start-title": "Jelszó megújítás",
    "reset-start-explanation": "A megújításhoz kérlek add meg fiókod nevét és a fiókhoz tartozó email címedet, majd klikkelj a Megújítás gombra. Ha az adatok megfelelőek, kapni fogsz egy e-mailt, amelyben benne lesz a megújításhoz szükséges titkos kód.",
    "reset-start-button": "Megújítás",

    "reset-start-success": "Sikeresen megkezdődött a megújítás.",
    "reset-start-success-explanation": "A jelszó megújítása megkezdődött, a rendszer küldeni fog egy e-mailt a megújításhoz szükséges titkos kóddal. A titkos kód 8 órán át érvényes, utána újat kell kérned.",
    "reset-start-error": "Nem sikerült elindítani a megújítást.",

    "password-change-title": "Jelszóváltozatás",
    "password-change-explanation": "Kérlek, add meg a régi, majd az új jelszót!",
    "password-change-button": "Végrehajtás",

    "reset-password-title": "Új jelszó beállítása",
    "reset-password-explanation": "Kérlek add meg az új jelszót. A jelszónak legalább 8, legfeljebb 50  karakter hosszúnak kell lennie.",
    "reset-password-button": "Új jelszó beállítása",

    "reset-finish-title": "Új jelszó megadása",
    "reset-finish-explanation": "Kérlek add az új jelszót.",
    "reset-finish-button": "Beállítás",

    "secret": "Titkos kód",
    "oldPassword": "Régi jelszó",
    "newPassword": "Új jelszó",

    "reset-finish-success": "Sikeres jelszó megújítás",
    "reset-finish-success-explanation": "Az új jelszó beállítása sikerült, erre a gombra kattintva be tudsz lépni a rendszerbe:",
    "reset-finish-error": "Az új jelszó beállítása nem sikerült, kérlek vedd fel velünk a kapcsolatot a " + supportEmail + " címen.",

    "activate-title": "Fiók aktiválása",
    "activate-in-progress": "A fiók aktiválása folyamatban.",
    "activate-success": "A fiók aktiválása sikerült, erre a gombra kattintva be tudsz lépni a rendszerbe:",
    "activate-error": "A fiók aktiválása nem sikerült, kérlek vedd fel velünk a kapcsolatot a " + supportEmail + " címen.",

    "password-change": "Jelszó változtatás",
    "password-change-error": "Nem sikerült megváltoztatni a jelszót.",
    "password-change-success": "Sikeres jelszó változtatás",
    "password-change-success-explanation": "Az új jelszó beállítása sikeresen megtörtént. \n A következő bejelentkezés alkalmával a most megadott új jelszót kell majd használni.",

    "expired-session": "Lejárt munkamenet",
    "expired-session-explanation": "A munkamenet lejárt, ezért újból be kell jelentkezned: ",
};