import React, {useEffect, useState} from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import styles from "../../../material-dashboard/assets/jss/customSelectStyle.jsx";
import MenuItem from "@material-ui/core/MenuItem";
import {makeStyles} from "@material-ui/styles";
import {useFormField} from "../../useFormField";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles(styles);

function ValidatedSelect(props) {

    const {name, disabled, api, recordFieldName, filterOfApi, nullable, dependentOn} = props;

    const classes = useStyles();

    const ff = useFormField(props, classes);

    const [dependency, setDependency] = useState(!!dependentOn)

    const [filter, setFilter] = useState(dependentOn ? {
        ...{[dependentOn]: ff.values.current[dependentOn]},
        ...filterOfApi
    } : undefined)

    useEffect(() => {
        if (dependentOn) {
            const labels = document.getElementsByTagName("label");
            for (let i = 0; i < labels.length; i++) {
                if (labels[i].htmlFor === (dependentOn + '-input')) {
                    setDependency(labels[i].classList.toString().indexOf("labelRootSuccess") <= 0)
                }
            }
        }

        setFilter(
            dependentOn ? {
                ...{[dependentOn]: ff.values.current[dependentOn]},
                ...filterOfApi
            } : undefined
        )
    }, [ff.values.current[dependentOn]])

    const {loadingStatus, data} = api((dependency && dependentOn) ? filter : (filterOfApi ? filterOfApi : undefined));
    // FIXME select error handling

    return (
        <FormControl
            fullWidth
            className={classes.selectFormControl}>
            <InputLabel
                htmlFor={ff.id}
                className={ff.labelClasses}
                shrink={ff.value !== 0}>
                {ff.labelText}
            </InputLabel>
            <Select
                MenuProps={{
                    className: classes.selectMenu
                }}
                classes={{
                    select: classes.select
                }}
                disabled={dependentOn ? dependency : disabled}
                value={ff.value}
                onChange={(e) => ff.onChange(e.target.value)}
                inputProps={{
                    name: name,
                    id: ff.id,
                    value: ff.value
                }}>
                {nullable ? <MenuItem
                    classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                    }}
                    key={0}
                    value={0}>
                    <br/>
                </MenuItem> : null}
                {data.map(item =>
                    <MenuItem
                        classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                        }}
                        key={item.get('id')}
                        value={item.get('id')}>
                        {item.get(recordFieldName ? recordFieldName : 'name')}
                    </MenuItem>
                )}
            </Select>
            {(loadingStatus !== 0) ? ((loadingStatus === 1) ? <LinearProgress/> :
                <LinearProgress color="secondary" value="100" variant={"buffer"}/>) : null}
        </FormControl>
    );
}

export default ValidatedSelect;