import {Record} from 'immutable';
import {array, number, object, string} from "yup";

export const PermitsRecord = Record({
    id: 0, //principalId
    fullName: '',
    roles: []

});

export const PermitsSchema = object().shape({
    id: number().required().min(0).default(0),
    fullName: string().required().min(1).max(100).default(''),
    roles: array().nullable()
});