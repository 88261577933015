import {Record} from 'immutable';
import {number, object, string} from "yup";

export const MobileClientRecord = Record({
    id: 0,
    serial: '',
    user : 0,
    userName: ""
});

export const MobileClientSchema = object().shape({
    id: number().required().min(0).default(0),
    serial: string().required().min(1).max(100).default(''),
    user: number().required().min(1).default(0),
    userName: string().nullable().default(null)

});