import baseFetch, {useFetchMany, useFetchOne} from "../Lib/baseFetch";
import {MobileClientRecord, MobileClientSchema} from "../Data/mobile-client";


export function useMobileClients(filter) {
    return useFetchMany('/mobile-clients', undefined, MobileClientRecord);
}

export function useMobileClient(id) {
    return useFetchOne('/mobile-clients', id, MobileClientRecord, MobileClientSchema);
}

export function addMobileClient(mobileClient, onSuccess, onError) {
    baseFetch('POST', '/mobile-clients', mobileClient,
        data => {
            if (onSuccess) onSuccess(new MobileClientRecord(data))
        },
        onError
    );
}

export function updateMobileClient(mobileClient, onSuccess, onError) {
    baseFetch('PATCH', '/mobile-clients/' + mobileClient.get('id'), mobileClient, onSuccess, onError);
}

export function removeMobileClient(id, onSuccess, onError) {
    baseFetch('DELETE', '/mobile-clients/' + id, undefined, onSuccess, onError);
}
