export default {
  "facilityType": "Létesítmény típus",
  "facilityTypes": "Létesítmény típusok",
  "add": "Létesítmény típus hozzáadása",
  "edit": "Létesítmény típus szerkesztése",
  "remove": "Létesítmény típus törlése",
  "name" : "Név",
  "name-invalid": "Érvénytelen mező, legalább 2, legfeljebb 60 karakternek kell lennie.",
  "back": "Vissza a listához",
  "save": "Létesítmény típus mentése",
  "action": "Műveletek",
  "id": "Azonosító",
};