import baseFetch, {useFetchMany, useFetchOne} from "../Lib/baseFetch";
import {TaskFacilityUnitRecord, TaskFacilityUnitSchema} from "../Data/task-facility-unit";

export function useTaskFacilityUnits(parent) {
    return useFetchMany('/tasks/' + parent + '/facility-units', undefined, TaskFacilityUnitRecord);
}

export function useTaskFacilityUnit(parent, id) {
    return useFetchOne('/tasks/' + parent + '/facility-units', id, TaskFacilityUnitRecord, TaskFacilityUnitSchema);
}

export function addTaskFacilityUnit(taskFacilityUnit, onSuccess, onError) {
    baseFetch('POST', '/tasks/' + taskFacilityUnit.get('task') + '/facility-units', taskFacilityUnit,
        data => {
            if (onSuccess) onSuccess(new TaskFacilityUnitRecord(data))
        },
        onError
    );
}

export function updateTaskFacilityUnit(taskFacilityUnit, onSuccess, onError) {
    baseFetch('PATCH', '/tasks/' + taskFacilityUnit.get('task') + '/facility-units/' + taskFacilityUnit.get('id'), taskFacilityUnit, onSuccess, onError);
}

export function removeTaskFacilityUnit(taskFacilityUnit, onSuccess, onError) {
    baseFetch('DELETE', '/tasks/' + taskFacilityUnit.get('task') + '/facility-units/' + taskFacilityUnit.get('id'), undefined, onSuccess, onError);
}
