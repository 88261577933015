import React, {useContext} from "react";
import searchBuilderStyle from "./styles/searchBuilderStyle";
import Chip from "@material-ui/core/Chip";
import {actions} from "../Data/Actions";
import {SearchContext} from "../Context/SearchContext";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(searchBuilderStyle);

function ConditionChip(props) {

    const classes = useStyles();
    const {chip, chipId, form} = props;

    const {dispatch} = useContext(SearchContext);

    const handleDelete = () => {
        const optionName = chip.get('optionName');
        const conditionId = chip.get('conditionId');
        const newId = parseInt(conditionId.match(/\d+/g)[0], 10);
        // FIXME don't use fieldValues directly
        let formValues = form.fieldValues.current[optionName];
        if (formValues.includes(newId)) {
            for (let i = 0; i < formValues.length; i++) {
                if (formValues[i] === newId) {
                    formValues.splice(i, 1);
                    i--;
                }
            }
        }
        dispatch(actions.removeCondition(optionName, conditionId))
    };

    return (
        <Chip component='div'
              label={chip.name}
              id={chipId}
              onDelete={() => handleDelete()}
              className={classes.sbChip}
              color="primary"/>
    );
}

export default ConditionChip;