import {Record} from 'immutable';
import {bool, number, object, string} from "yup";

export const TaskFacilityUnitRecord = Record({
    id: 0,
    task: 0,
    facilityUnit: 0,
    finished: false,
    approved: false,
    taskName: "",
    facilityUnitName:"",
    facilityUnitErpId: ""
});

export const TaskFacilityUnitSchema = object().shape({
    id: number().required().min(0).default(0),
    task: number().min(1).default(0),
    facilityUnit: number().min(1).default(0),
    finished: bool().required().default(false),
    approved: bool().required().default(false),
    taskName: string().nullable().default(""),
    facilityUnitName: string().nullable().default(""),
    facilityUnitErpId: string().nullable().default("")

});