import baseFetch, {useFetchOne} from "../Lib/baseFetch";
import {FacilityAccessRecord, FacilityAccessSchema} from "../Data/facility-access";


export function useFacilityAccess(parent) {
    return useFetchOne('/accounts/' + parent + '/facilities-accesses', 0, FacilityAccessRecord, FacilityAccessSchema);
}


export function createOrDeleteFacilityAccess(facilityAccess, onSuccess, onError) {
    baseFetch('PUT', '/accounts/' + facilityAccess.get("accountId") + '/facilities-accesses', facilityAccess, onSuccess, onError);
}
