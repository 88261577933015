import React, {memo, useContext} from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../Lib/form/useForm";
import ValidatedInput from "../../Lib/form/Components/ValidatedInput/ValidatedInput";
import Card from "../../Lib/material-dashboard/components/Card/Card";
import CardBody from "../../Lib/material-dashboard/components/Card/CardBody";
import ValidatedSubmit from "../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import {assume} from "../../Lib/auth/Api/session";

import recordType from "../../Lib/auth/Records/AuthenticationData";
import AuthenticationData from "../../Lib/auth/Records/AuthenticationData";
import recordSchema from "../../Lib/auth/Schemas/AuthenticationData";
import {AppContext} from "../../Main/AppContext";
import Actions from "../../Lib/auth/AuthActions";
import {useSnackbar} from "notistack";
import {A, navigate} from "hookrouter";
import Grid from "@material-ui/core/Grid";

import accountStyle from "./accountStyle";
import Button from "../../Lib/material-kit/components/CustomButtons/Button";
import {makeStyles} from "@material-ui/styles";
import FormProvider from "../../Lib/form/Components/FormProvider/FormProvider";
import {closeSnack} from "../../Main/util";
import ContentBox from "../../Components/ContentBox/ContentBox";
import {roles} from "../../Main/roles";

const useStyles = makeStyles(accountStyle);

const Login = memo(() => {

    // stuff specific for login  ----------------------------------

    const appContext = useContext(AppContext);
    const dispatch = appContext.get('dispatch');

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const onSuccess = (sessionData) => {
        if (sessionData.roles.some(role => [roles.SITE_MEMBER.toLowerCase()].indexOf(role) !== -1)) {
            dispatch(Actions.sessionAdd(sessionData));
            enqueueSnackbar(t('accounts:successful-login'), {variant: 'success', autoHideDuration: 3000});
            navigate('/dashboard');
        } else {
            enqueueSnackbar(t('catchException:hasNoSiteMemberRole'), {
                variant: 'error',
                persist: true,
                action: (key) => closeSnack(t, closeSnackbar, key)
            });
        }
    };

    const onError = (error) => {
        let message;
        switch (error.status) {
            case 404:
                message = t('error404');
                break;
            case 406:
                message = t('error406');
                break;
            case 412:
                message = t('error412');
                break;
            default:
                message = t('error');
        }
        enqueueSnackbar(message, {variant: 'error', persist: true, action: (key) => closeSnack(t, closeSnackbar, key)});
    };

    // usual form stuff  ----------------------------------

    const {t} = useTranslation("accounts");

    // noinspection JSPotentiallyInvalidConstructorUsage
    const form = useForm({
        recordType: recordType,
        recordSchema: recordSchema,
        namespace: 'accounts',
        record: new recordType(recordSchema.default())
    });

    const classes = useStyles();

    const handle = () => {
        const recordStatus = Object.values(form.validationStatuses.current).find(s => s === "invalid" || s === "default-invalid");
        console.log(recordStatus)
        if (recordStatus === undefined) {
            assume(new AuthenticationData(form.fieldValues.current), (sessionData) => onSuccess(sessionData), (error) => onError(error))
        }
    };

    // noinspection HtmlUnknownTarget
    return (
        <ContentBox>
            <Card className={classes.container}>
                <CardBody>
                    <Grid container justify="center">

                        <Grid item xs={12}>
                            <div className={classes.title}>{t('login')}</div>
                        </Grid>

                        <Grid item xs={12}>
                            <FormProvider form={form} formClassName={classes.form}>
                                <ValidatedInput name="principalName" handleEnter={handle}/>

                                <ValidatedInput name="password" password handleEnter={handle}/>

                                <Grid item container justify="space-between">
                                    <Grid item style={{marginTop: 40}}>
                                        <ValidatedSubmit
                                            api={assume}
                                            hideCancel
                                            submitLabel={t('login')}
                                            onSuccess={(sessionData) => onSuccess(sessionData)}
                                            onError={(error) => onError(error)}
                                        />
                                    </Grid>
                                    <Grid item style={{marginTop: 40}}>
                                        <A href="/accounts/reset">
                                            <Button color="info">
                                                {t('forgottenPassword')}
                                            </Button>
                                        </A>
                                    </Grid>
                                </Grid>
                            </FormProvider>
                        </Grid>

                    </Grid>
                </CardBody>
            </Card>
        </ContentBox>
    );
});

export default Login;