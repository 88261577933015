import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../../Lib/form/useForm";
import ContentBox from "../../../Components/ContentBox/ContentBox";
import Section from "../../../Lib/form/Components/Section/Section";
import ValidatedSubmit from "../../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import {InventoryTicketRecord, InventoryTicketSchema} from "../../../Data/inventory-ticket"
import debug from "../../../Lib/debug";
import ValidatedSelect from "../../../Lib/form/Components/ValidatedSelect/ValidatedSelect";
import {useInventoryAreas} from "../../../Api/inventory-areas";
import {useInventoryItems} from "../../../Api/inventory-items";
import {useFacilityUnits} from "../../../Api/facility-units";
import {useTasks} from "../../../Api/tasks";
import ValidatedInput from "../../../Lib/form/Components/ValidatedInput/ValidatedInput";
import ValidatedRadioGroup from "../../../Lib/form/Components/ValidatedRadio/ValidatedRadioGroup";
import Grid from "@material-ui/core/Grid";
import {GeneralStatus} from "../../../Data/GeneralStatus";
import ValidatedRadio from "../../../Lib/form/Components/ValidatedRadio/ValidatedRadio";

export default memo(({id, record, path, view, api}) => {
    const {t} = useTranslation("inventoryTickets");

    const form = useForm({
        recordType: InventoryTicketRecord,
        recordSchema: InventoryTicketSchema,
        namespace: 'inventoryTickets',
        record
    });

    debug("RENDER", "useForm", () => [" -> Form_Of_Inventory_Tickets", view, id, path, record]);

    return (
        <ContentBox path={path} form={form}>

            <Section title={t('inventoryTicket')}>
                <ValidatedSelect name="task" api={useTasks}/>
                <ValidatedInput name="erpId" disabled/>
                <ValidatedSelect name="inventoryArea" api={useInventoryAreas} />
                <ValidatedSelect name="inventoryItem" api={useInventoryItems} />
                <ValidatedInput name="registeredQuantity" />
                <ValidatedInput name="foundQuantity"/>
                <ValidatedSelect name="registeredFacilityUnit" api={useFacilityUnits} />
                <ValidatedSelect name="foundFacilityUnit" api={useFacilityUnits}/>
                <ValidatedRadioGroup name="registeredStatus">
                    <Grid container>
                        {GeneralStatus.map(a => <ValidatedRadio name="registeredStatus" value={a} key={a}/>)}
                    </Grid>
                </ValidatedRadioGroup>
                <ValidatedRadioGroup name="foundStatus">
                    <Grid container>
                        {GeneralStatus.map(a => <ValidatedRadio name="foundStatus" value={a} key={a}/>)}
                    </Grid>
                </ValidatedRadioGroup>
            </Section>

            <ValidatedSubmit view={view} api={api}/>

        </ContentBox>
    );
})