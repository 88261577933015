import React, {useRef, useState} from "react";
import {useRedirect, useRoutes} from 'hookrouter';
import cx from "classnames";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import Sidebar from "../Components/Sidebar/Sidebar.jsx";
import appStyle from "./appStyle.jsx";
import Immutable from "immutable";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(appStyle);

function AppLayout(props) {

    const {appStore, authStore, ...rest} = props;

    const classes = useStyles();

    const [state, setState] = useState(Immutable.Map({
        mobileOpen: false,
        miniActive: false,
    }));

    const mainPanelRef = useRef(null);

    useRedirect('/', '/dashboard');

    const handleDrawerToggle = () => {
        setState(state.update('mobileOpen', mobileOpen => !mobileOpen));
    };

    const sidebarMinimize = () => {
        setState(state.update('miniActive', miniActive => !miniActive));
    };

    const mainPanelStyle = cx(classes.mainPanel, {[classes.mainPanelSidebarMini]: state.get('miniActive')});

    return (
        <div className={classes.wrapper}>
            <Sidebar
                handleDrawerToggle={handleDrawerToggle}
                sidebarMinimize={sidebarMinimize}
                open={state.get('mobileOpen')}
                mini={state.get('miniActive')}
                {...rest}
            />
            <div className={mainPanelStyle} ref={mainPanelRef}>
                {useRoutes(appStore.get('routingMap'))}
            </div>
        </div>
    );

}

export default AppLayout;
