export const TaskTypes = [
    'INVENTORY',
    'BLIND_INVENTORY'
];

export const TaskStatuses = [
    'PLANNING',
    'PREPARED',
    'EXECUTION',
    'APPROVED',
    'CLOSED',
    'SENT'
];

export const TaskSystems = [
    'NSK',
    'FORRAS_NET',
];
