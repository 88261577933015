import React from "react";
import List from "./List";
import {useRoutes} from "hookrouter";
import Form from "./Form";
import Crud from "../../../Lib/Crud";
import {MobileClientRecord, MobileClientSchema} from "../../../Data/mobile-client";
import {addMobileClient, removeMobileClient, updateMobileClient, useMobileClient} from "../../../Api/mobile-clients";

export default () => useRoutes(subRoutes);

const subRoutes = {
  "/": () => <Crud config={crudConfig} view="list" />,
  "/add": () => <Crud config={crudConfig} view="add" />,
  "/:id/edit": ({id}) => <Crud config={crudConfig} view="edit" id={id} />,
  "/:id/remove": ({id}) => <Crud config={crudConfig} view="remove" id={id} />
};

const crudConfig = {
  compName: 'mobileClients',
  namespace: 'mobileClients',
  url: '/mdm/mobile-clients',
  labelField: 'serial',
  Record: MobileClientRecord,
  Schema: MobileClientSchema,
  ListComponent: List,
  FormComponent: Form,
  api: {
    use: useMobileClient,
    add: addMobileClient,
    edit: updateMobileClient,
    remove: removeMobileClient
  }
};
