/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {
    dangerColor,
    defaultFont,
    grayColor,
    primaryColor,
    successColor,
    whiteColor
} from "../material-dashboard-pro-react.jsx";
import {warningColor} from "../material-dashboard-pro-react";

const customInputStyle = {
    disabled: {
        "&:before": {
            borderColor: "transparent !important"
        }
    },
    underline: {
        "&:hover:not($disabled):before,&:before": {
            borderColor: grayColor[4] + "!important",
            borderWidth: "1px !important"
        },
        "&:after": {
            borderColor: primaryColor[0]
        },
        "& + p": {
            fontWeight: "300"
        }
    },
    underlineError: {
        "&:after": {
            borderColor: dangerColor[0]
        }
    },
    underlineSuccess: {
        "&:after": {
            borderColor: successColor[0]
        }
    },
    labelRoot: {
        ...defaultFont,
        color: warningColor[1] + " !important",
        fontWeight: "500",
        fontSize: "1rem",
        //lineHeight: "1.42857",
        top: "10px",
        letterSpacing: "unset",
        "& + $underline": {
            marginTop: "0px"
        }
    },
    labelRootError: {
        color: dangerColor[0] + " !important"
    },
    labelRootSuccess: {
        color: successColor[0] + " !important"
    },
    formControl: {
        margin: "0px 0 0px 0",
        paddingTop: "27px",
        position: "relative",
        // no idea why this was added, it screws up everything... verticalAlign: "unset",
        "& svg,& .fab,& .far,& .fal,& .fas,& .material-icons": {
            color: grayColor[14]
        },
        minHeight: 56
    },
    whiteUnderline: {
        "&:hover:not($disabled):before,&:before": {
            backgroundColor: whiteColor
        },
        "&:after": {
            backgroundColor: whiteColor
        }
    },
    input: {
        color: grayColor[14],
        "&,&::placeholder": {
            fontSize: "14px",
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: "400",
            //lineHeight: "1.42857",
            opacity: "1"
        },
        "&::placeholder": {
            color: grayColor[3]
        }
    },
    dateTimeInput: {
        padding: "0 !important",
        height: "29px !important"
    },
    whiteInput: {
        "&,&::placeholder": {
            color: whiteColor,
            opacity: "1"
        }
    }
};

export default customInputStyle;
