import React from "react";
import {A, useRoutes} from "hookrouter";
import Activate from "./Activate";
import Login from "./Login";
import Logout from "./Logout";
import ResetStart from "./ResetStart";
import {Form} from "./Form";
import AddSuccess from "./AddSuccess";
import ResetFinish from "./ResetFinish";
import Button from "../../Lib/material-kit/components/CustomButtons/Button";
import {Face} from "@material-ui/icons";
import {AccountRecord, AccountSchema} from "../../Data/account";
import PasswordChange from "./PasswordChange";
import List from "./List";
import Crud from "../../Lib/Crud";
import {addAccount, removeAccount, updateAccount, useAccount} from "../../Api/accounts";
import FacilityAccess from "./FacilityAccess/FacilityAccess";

const subRoutes = {
    "/": () => <Crud config={crudConfig} view="list"/>,
    "/add": () => <Crud config={crudConfig} view="add"/>,
    "/:id/edit": ({id}) => <Crud config={crudConfig} view="edit" id={id}/>,
    "/:id/remove": ({id}) => <Crud config={crudConfig} view="remove" id={id}/>,
    "/assume": () => <Login/>,
    "/renounce": () => <Logout/>,
    "/reset": () => <ResetStart/>,
    "/add/success": () => <AddSuccess/>,
    "/:id/password": ({id}) => <PasswordChange id={id}/>,
    "/:id/reset/:token": ({id, token}) => <ResetFinish id={id} token={token}/>,
    "/:id/activation-keys/:ak": ({id, ak}) => <Activate id={id} ak={ak}/>,
    "/:id/facility-accesses*": ({id}) => <Crud config={crudConfig} OptComponent={FacilityAccess}
                                                optUrlSectionNLabel='facility-accesses' view="other" id={id}/>
};


export default () => useRoutes(subRoutes);

// noinspection HtmlUnknownTarget
export const LoginButton = ({classes, t}) => (
    <A href="/login">
        <Button color="success" className={classes.button}>
            <Face className={classes.icons}/><span style={{marginLeft: 4}}>{t('header:login')}</span>
        </Button>
    </A>
);

const crudConfig = {
    compName: 'Accounts',
    namespace: 'accounts',
    url: '/admin/accounts',
    labelField: 'displayName',
    Record: AccountRecord,
    Schema: AccountSchema,
    ListComponent: List,
    FormComponent: Form,
    api: {
        use: useAccount,
        add: addAccount,
        edit: updateAccount,
        remove: removeAccount
    }
};