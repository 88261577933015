import {Record} from 'immutable';

const Option = Record({
    name: '',
    data: undefined,
    selected: undefined,
    loading: false,
    loaded: false,
    fetchFunction: undefined,
    inputType: '',
});

export default Option;