import React from "react";
import {useRoutes} from "hookrouter";
import Crud from "../../../Lib/Crud";
import {createOrDeleteFacilityAccess, useFacilityAccess} from "../../../Api/facilities-accesses";
import List from '../List';
import Form from './Form';
import {FacilityAccessRecord, FacilityAccessSchema} from "../../../Data/facility-access";

export default (props) => useRoutes(subRoutes)(props);

const subRoutes = {
    "/": () => (props) => <Crud config={crudConfig(props)} view="edit" {...props} id={props.parentId}/>,
};

const crudConfig = (props) => ({

    compName: 'Accesses',
    namespace: 'facilitiesAccesses',
    Record: FacilityAccessRecord,
    Schema: FacilityAccessSchema,
    ListComponent: List,
    FormComponent: Form,
    api: {
        use: useFacilityAccess,
        edit: createOrDeleteFacilityAccess,
    },
    url: props.path[0].url + "/" + props.parentId + '/facility-accesses',
    setOptRecField: "accountId",
    parentId: props.parentId,
    labelField: 'principalName',
});

