import baseFetch, {useFetchMany, useFetchOne} from "../../baseFetch";
import {CreationRuleRecord, CreationRuleSchema} from "../Data/CreationRule";


export function useCreationRules(filter) {
    return useFetchMany('/creation-rules', undefined, CreationRuleRecord);
}

export function useCreationRule(id) {
    return useFetchOne('/creation-rules', id, CreationRuleRecord, CreationRuleSchema);
}

export function addCreationRule(creationRule, onSuccess, onError) {
    baseFetch('POST', '/creation-rules', creationRule,
        data => {
            if (onSuccess) onSuccess(new CreationRuleRecord(data))
        },
        onError
    );
}

export function getCreationRules(filter, onSuccess, onError) {
    baseFetch('GET', '/creation-rules', filter,
        data => {
            if (onSuccess) onSuccess(data.map(r => new CreationRuleRecord(r)))
        },
        onError
    );
}

export function getCreationRule(id, onSuccess, onError) {
    baseFetch('GET', '/creation-rules/' + id, undefined,
        data => {
            if (onSuccess) onSuccess(new CreationRuleRecord(data))
        },
        onError
    );
}

export function updateCreationRule(creationRule, onSuccess, onError) {
    baseFetch('PATCH', '/creation-rules/' + creationRule.get('id'), creationRule, onSuccess, onError);
}

export function removeCreationRule(creationRule, onSuccess, onError) {
    baseFetch('DELETE', '/creation-rules/' + creationRule.get('id'), undefined, onSuccess, onError);
}
