import baseFetch, {useFetchMany, useFetchOne} from "../../baseFetch";
import {MembershipRecord, MembershipSchema} from "../Data/Membership";
import {GroupRecord, GroupSchema} from "../Data/Group";

export function useGroups(filter) {
    return useFetchMany('/groups', undefined, GroupRecord);
}

export function useGroup(id) {
    return useFetchOne('/groups', id, GroupRecord, GroupSchema);
}

export function addGroup(group, onSuccess, onError) {
    baseFetch('POST', '/groups', group,
        data => {
            if (onSuccess) onSuccess(new GroupRecord(data))
        },
        onError
    );
}

export function getGroups(filter, onSuccess, onError) {
    baseFetch('GET', '/groups', filter,
        data => {
            if (onSuccess) onSuccess(data.map(r => new GroupRecord(r)))
        },
        onError
    );
}

export function getGroup(id, onSuccess, onError) {
    baseFetch('GET', '/groups/' + id, undefined,
        data => {
            if (onSuccess) onSuccess(new GroupRecord(data))
        },
        onError
    );
}

export function updateGroup(group, onSuccess, onError) {
    baseFetch('PATCH', '/groups/' + group.get('id'), group, onSuccess, onError);
}

export function removeGroup(group, onSuccess, onError) {
    baseFetch('DELETE', '/groups/' + group.get('id'), undefined, onSuccess, onError);
}

// Memberships

export function useMemberships(parentId) {
    return useFetchMany('/groups/' + parentId + '/memberships', undefined, MembershipRecord);
}

export function useMembership(parentId, id) {
    return useFetchOne('/groups/' + parentId + '/memberships', id, MembershipRecord, MembershipSchema);
}

export function addMembership(membership, onSuccess, onError) {
    baseFetch('POST', '/groups/' + membership.get("groupId") + '/memberships', membership,
        data => {
            if (onSuccess) onSuccess(new MembershipRecord(data))
        },
        onError
    );
}

export function removeMembership(membership, onSuccess, onError) {
    baseFetch('DELETE', '/groups/' + membership.get("groupId") + '/memberships/' + membership.get("principalId"), undefined, onSuccess, onError);
}
