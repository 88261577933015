import React from "react";
import List from "./List";
import {useRoutes} from "hookrouter";
import {TaskRecord, TaskSchema} from "../../Data/task";
import {addTask, removeTask, updateTask, useTask} from "../../Api/tasks";
import Crud from "../../Lib/Crud";
import Form from "./Form";
import InventoryTickets from "./InventoryTickets/InventoryTickets";
import TaskFacilityUnits from "./TaskFacilityUnits/TaskFacilityUnits";
import FoundList from "./InventoryTickets/FoundList";

export default () => useRoutes(subRoutes);

const subRoutes = {
    "/": () => <Crud config={crudConfig} view="list"/>,
    "/add": () => <Crud config={crudConfig} view="add"/>,
    "/:id/edit": ({id}) => <Crud config={crudConfig} view="edit" id={id}/>,
    "/:id/remove": ({id}) => <Crud config={crudConfig} view="remove" id={id}/>,
    "/:id/inventory-tickets*": ({id}) => <Crud config={crudConfig} OptComponent={InventoryTickets}
                                               optUrlSectionNLabel='inventory-tickets' view="other" id={id}/>,
    "/:id/facility-units*": ({id}) => <Crud config={crudConfig} OptComponent={TaskFacilityUnits}
                                            optUrlSectionNLabel='facility-units' view="other" id={id}/>,
    "/:id/foreign-items*": ({id}) => <Crud config={crudConfig} OptComponent={FoundList}
                                      optUrlSectionNLabel='foreign-items' view="other"
                                      id={id}/>,
};

const crudConfig = {
    compName: 'Tasks',
    namespace: 'tasks',
    url: '/inventory/tasks',
    labelField: 'name',
    Record: TaskRecord,
    Schema: TaskSchema,
    ListComponent: List,
    FormComponent: Form,
    api: {
        use: useTask,
        add: addTask,
        edit: updateTask,
        remove: removeTask
    }
};