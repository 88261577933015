import baseFetch, {useFetchMany, useFetchOne} from "../../baseFetch";
import {RoleRecord, RoleSchema} from "../Data/Role";
import {GrantRecord} from "../Data/Grant";
import {PermitsRecord} from "../Data/Permits";

export function useRoles(filter) {
    return useFetchMany('/roles', undefined, RoleRecord);
}

export function useRole(id) {
    return useFetchOne('/roles', id, RoleRecord, RoleSchema);
}

export function addRole(role, onSuccess, onError) {
    baseFetch('POST', '/roles', role,
        data => {
            if (onSuccess) onSuccess(new RoleRecord(data))
        },
        onError
    );
}

export function updateRole(role, onSuccess, onError) {
    baseFetch('PATCH', '/roles/' + role.get('id'), role, onSuccess, onError);
}

export function removeRole(role, onSuccess, onError) {
    baseFetch('DELETE', '/roles/' + role.get('id'), undefined, onSuccess, onError);
}

// RoleGrants

export function useGrants(parentId) {
    return useFetchMany('/roles/' + parentId + '/grants', undefined, GrantRecord);
}

export function useGrant(parentId, id) {
    return useFetchOne('/roles/' + parentId + '/grants', id, GrantRecord, RoleSchema);
}

export function addGrant(grant, onSuccess, onError) {
    baseFetch('POST', '/roles/' + grant.get("roleId") + '/grants', grant,
        data => {
            if (onSuccess) onSuccess(new GrantRecord(data))
        },
        onError
    );
}

export function removeGrant(grant, onSuccess, onError) {
    baseFetch('DELETE', '/roles/' + grant.get("roleId") + '/grants/' + grant.get("principalId"), undefined, onSuccess, onError);
}

export function usePermits(filter, revision) {
    return useFetchMany('/permits', undefined, PermitsRecord, revision);
}

export function updatePermit(principal, role, action, onSuccess, onError) {
    baseFetch('PATCH', '/update-permits?principal=' + principal + "&role=" + role + "&action=" + action, undefined, onSuccess, onError);
}