import React from "react";
import List from "./List";
import {useRoutes} from "hookrouter";
import {
    InventoryItemHistoryEntryRecord,
    InventoryItemHistoryEntrySchema
} from "../../../../Data/inventory-item-histrory-entry";
import Form from "./Form";
import {
    addInventoryItemHistoryEntry,
    removeInventoryItemHistoryEntry,
    updateInventoryItemHistoryEntry,
    useInventoryItemHistoryEntry
} from "../../../../Api/inventory-item-histories";
import Crud from "../../../../Lib/Crud";

export default (props) => useRoutes(subRoutes)(props);

const subRoutes = {
    "/": () => (props) => <Crud config={crudConfig(props)} view="list" {...props}/>,
    "/add": () => (props) => <Crud config={crudConfig(props)} view="add" {...props}/>,
    "/:id/edit": ({id}) => (props) => <Crud config={crudConfig(props)} view="edit" id={id} {...props}/>,
    "/:id/remove": ({id}) => (props) => <Crud config={crudConfig(props)} view="remove" id={id} {...props}/>,
};

const crudConfig = (props) => ({

    compName: 'inventoryItemHistoryEntries',
    namespace: 'inventoryItemHistoryEntries',
    Record: InventoryItemHistoryEntryRecord,
    Schema: InventoryItemHistoryEntrySchema,
    api: {
        use: useInventoryItemHistoryEntry,
        add: addInventoryItemHistoryEntry,
        edit: updateInventoryItemHistoryEntry,
        remove: removeInventoryItemHistoryEntry
    },
    url: props.path[0].url + "/"  + props.parentId + '/histories',
    labelField: 'itemName',
    ListComponent: List,
    FormComponent: Form,
    setOptRecField: "item",
    parentId: props.parentId,
});


