import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../../Lib/form/useForm";
import ContentBox from "../../../Components/ContentBox/ContentBox";
import Section from "../../../Lib/form/Components/Section/Section";
import ValidatedInput from "../../../Lib/form/Components/ValidatedInput/ValidatedInput";
import ValidatedSubmit from "../../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import {ItemUserRecord, ItemUserSchema} from "../../../Data/item-user";
import debug from "../../../Lib/debug"
import ValidatedRadioGroup from "../../../Lib/form/Components/ValidatedRadio/ValidatedRadioGroup";
import Grid from "@material-ui/core/Grid";
import {GeneralStatus} from "../../../Data/GeneralStatus";
import ValidatedRadio from "../../../Lib/form/Components/ValidatedRadio/ValidatedRadio";


export default memo(({id, record, path, view, api, hasAdminRoles}) => {
    const {t} = useTranslation('itemUsers');

    const form = useForm({
        recordType: ItemUserRecord,
        recordSchema: ItemUserSchema,
        namespace: 'itemUsers',
        record: record
    });

    debug("RENDER", "useForm", () => [" -> Form_Of_Item_Users", id, record, path, view]);

    return (
        <ContentBox path={path} form={form}>

            <Section title={t('itemUser')}>
                <ValidatedInput name="name" disabled={!hasAdminRoles}/>
                <ValidatedInput name="erpId" disabled/>
                <ValidatedRadioGroup name="status">
                    <Grid container>
                        {GeneralStatus.map(a => <ValidatedRadio name="status" value={a} key={a}/>)}
                    </Grid>
                </ValidatedRadioGroup>
            </Section>

            <ValidatedSubmit view={view} api={api} hideSave={!hasAdminRoles}/>

        </ContentBox>
    );
})