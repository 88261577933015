import React, {memo, useContext, useEffect, useState} from "react";
import {updatePermit, usePermits, useRoles} from "../../Api/roles";
import ContentBox from "../../../../Components/ContentBox/ContentBox";
import {SimplexionTable} from "../../../simplexion-table/SimplexionTable";
import {baseTableStyles} from "../../../simplexion-table/baseStyle";
import {makeStyles} from "@material-ui/core";
import {makeTableStyles} from "../../../../Components/Table/tableStyles";
import {updatableCheckBoxStyle} from "../../../simplexion-table/templates/checkbox-input";
import {inputFieldStyles} from "../../../simplexion-table/templates/text-input";
import debug from "../../../debug";
import {FilterField} from "../../../../Components/Table/FilterField";
import LinearProgress from "@material-ui/core/LinearProgress";
import Section from "../../../form/Components/Section/Section";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";
import {throwsExceptionMessage} from "../../../../Api/catchException";
import {closeSnack} from "../../../../Main/util";
import {AuthContext} from "../../AuthContext";
import {roles as Roles} from "../../../../Main/roles";


const useTableStyles = makeStyles(makeTableStyles(baseTableStyles, updatableCheckBoxStyle, inputFieldStyles));

export const roleColumn = (accessor, record, roleId, helperText) => {
    const itHas = record.roles.indexOf(roleId) !== -1

    return `<div class="addOrRemoveTheRole" style="cursor: pointer; padding-left: 1.3rem">${itHas ?
        `<span id=${record.id} data-principal-id=${record.id} data-role-id=${roleId} data-action="REMOVE" style="color:green" class="material-icons" title="${helperText.toRemoveHelpText}">check_box</span>` :
        `<span id=${record.id} data-principal-id=${record.id} data-role-id=${roleId} data-action="ADD" style="color:orangered" class="material-icons" title="${helperText.toAddHelpText}">check_box_outline_blank</span>`}</div>`
}

const List = memo((props) => {

    debug("RENDER", "useForm", () => [" -> List_Of_Permits", props]);

    const {path, hasAdminRoles} = props;

    const rolesData = useRoles().data

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const [num, setNum] = useState(0)

    const tableClasses = useTableStyles();

    const {t} = useTranslation("roles")

    const {loadingStatus, data} = usePermits(undefined, num);

    const [state, setState] = useState('');

    const handleFilter = val => setState(val);

    const authContext = useContext(AuthContext);

    const hasSORole = authContext.getIn(['session', 'roles']).indexOf(Roles.SECURITY_OFFICER) !== -1;

    const success = (action) => {
        enqueueSnackbar(t(action === "ADD" ? "successfulCreation" : "successfulRemoval"), {
            variant: 'success',
            autoHideDuration: 2500,
            action: (key) => closeSnack(t, closeSnackbar, key)
        });
        setNum(num => num + 1)
    };

    const error = (error) => {
        enqueueSnackbar(t('catchException:' + throwsExceptionMessage(error)), {
            variant: 'error',
            autoHideDuration: 5000,
            action: (key) => closeSnack(t, closeSnackbar, key)
        });
    };

    const handleClick = (event) => {
        if (hasAdminRoles && event.target.parentElement && event.target.parentElement.classList && event.target.parentElement.classList.length > 0) {
            if (event.target.parentElement.classList.contains("addOrRemoveTheRole")) {
                let principal = event.target.getAttribute("data-principal-id")
                let role = event.target.getAttribute("data-role-id")
                let action = event.target.getAttribute("data-action")
                updatePermit(principal, role, action, success(action), error)
            }
        }
    };

    useEffect(() => {
        window.addEventListener('click', handleClick);
        // cleanup this component
        return () => {
            window.removeEventListener('click', handleClick);
        };
    }, []);

    if (rolesData.length < 1) return null

    const tableConfig = {
        id: 'permits-table',
        nameSpace: 'roles',
        stickyHeader: true,
        sortable: true,
        styles: tableClasses,
        columns: [
            {accessor: 'fullName'}
        ].concat(
            rolesData.filter(role => hasSORole ? (role.name.toLowerCase() !== "anonymous") : (role.name.toLowerCase() !== "anonymous" && role.name.toLowerCase() !== "developer" && role.name.toLowerCase() !== "security-officer")).map(r => (
                {
                    accessor: t(r.name),
                    renderer: (accessor, record) => roleColumn(accessor, record, r.id, {
                        toAddHelpText: t("toAddHelpText"),
                        toRemoveHelpText: t("toRemoveHelpText")
                    })
                }
            ))
        )
    };

    return (
        <ContentBox path={path}>
            <Section>
                <FilterField handler={handleFilter}/>
                {loadingStatus > 0 ? <LinearProgress/> : null}
                <SimplexionTable tableConfig={tableConfig} data={data} loadingStatus={loadingStatus}
                                 filterValue={state}/>
            </Section>
        </ContentBox>
    );
});

export default List;

