import baseFetch, {useFetchMany, useFetchOne} from "../Lib/baseFetch";
import {AccountRecord, AccountSchema} from "../Data/account";
import {VersionRecord, VersionSchema} from "../Data/version";


export function useAccounts(filter) {
    return useFetchMany('/accounts', undefined, AccountRecord);
}

export function useAccount(id) {
    return useFetchOne('/accounts', id, AccountRecord, AccountSchema);
}

export function addAccount(account, onSuccess, onError) {
    baseFetch('POST', '/accounts', account,
        data => {
            if (onSuccess) onSuccess(new AccountRecord(data))
        },
        onError
    );
}

export function activateAccount(id, ak, onSuccess, onError) {
    baseFetch('GET', '/accounts/' + id + '/activation-keys/' + ak, undefined,
        onSuccess,
        onError
    );
}

export function updateAccount(account, onSuccess, onError) {
    baseFetch('PATCH', '/accounts/' + account.get('id'), account, onSuccess, onError);
}

export function removeAccount(account, onSuccess, onError) {
    baseFetch('DELETE', '/accounts/' + account.get('id'), undefined, onSuccess, onError);
}

export function enableReset(reset, onSuccess, onError) {
    baseFetch('PATCH', '/accounts/reset', reset,
        onSuccess,
        onError
    );
}

export function resetCredentials(id, credentials, onSuccess, onError) {
    baseFetch('PATCH', '/accounts/' + id + '/reset', credentials,
        onSuccess,
        onError
    );
}
export function resetPassword(id, credentials, onSuccess, onError) {
    baseFetch('PATCH', '/accounts/' + id + '/reset-password', credentials,
        onSuccess,
        onError
    );
}

export function updateCredentials(id, credentials, onSuccess, onError) {
    baseFetch('PATCH', '/accounts/' + id + '/credentials', credentials,
        onSuccess,
        onError
    );
}

export function useVersion() {
    return useFetchOne('/about-project', null, VersionRecord, VersionSchema);
}

export function useDownloadApk(filePath, onSuccess, onError) {
    baseFetch('GET', '/android-apk/' + filePath, undefined,
        data => {
            if (onSuccess) onSuccess(data)
        },
        onError
    );
}