import React, {Fragment, memo, useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../Lib/form/useForm";
import ContentBox from "../../Components/ContentBox/ContentBox";
import Section from "../../Lib/form/Components/Section/Section";
import ValidatedInput from "../../Lib/form/Components/ValidatedInput/ValidatedInput";
import ValidatedSubmit from "../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import {TaskRecord, TaskSchema} from "../../Data/task";
import ValidatedSelect from "../../Lib/form/Components/ValidatedSelect/ValidatedSelect";
import ValidatedDateTime from "../../Lib/form/Components/ValidatedDateTime/ValidatedDateTime";
import debug from "../../Lib/debug"
import ValidatedRadioGroup from "../../Lib/form/Components/ValidatedRadio/ValidatedRadioGroup";
import Grid from "@material-ui/core/Grid";
import ValidatedRadio from "../../Lib/form/Components/ValidatedRadio/ValidatedRadio";
import {TaskSystems, TaskTypes} from "../../Data/StatusAndTypeOfTask";
import {Navigation} from "./Navigation";
import {useInventoryItemGroups} from "../../Api/item-groups";
import {usePrincipals} from "../../Lib/auth/Api/principals";
import {useSnackbar} from "notistack";
import {navigate} from "hookrouter";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {updateTask, useGenerateNDownloadPdf} from "../../Api/tasks";
import {throwsExceptionMessage} from "../../Api/catchException";
import {closeSnack} from "../../Main/util";
import Button from "../../Lib/material-dashboard/components/CustomButtons/Button";
import {AuthContext} from "../../Lib/auth/AuthContext";
import {roles} from "../../Main/roles";


export const erpIdValidator = (fieldName, fieldValue, fieldValues) => {
    if ((fieldValues["status"] === "PREPARED") && (fieldValues["system"] === "FORRAS_NET")) {
        return fieldValue.length > 3
    } else return true
};

export const groupValidator = (fieldName, fieldValue, fieldValues) => {
    if ((fieldValues["status"] === "PREPARED") && (fieldValues["system"] === "NSK")) {
        return fieldValue > 0
    } else return true
};

export const AreYouSure = ({form, t, open, closeDialog}) => {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const success = () => {
        enqueueSnackbar(t('general:save.success'), {
            variant: 'success',
            autoHideDuration: 3000,
            action: (key) => closeSnack(t, closeSnackbar, key)
        });
        navigate("../");
    }

    const error = (error) => {
        enqueueSnackbar(t('catchException:' + throwsExceptionMessage(error)), {
            variant: 'error',
            autoHideDuration: 4000,
            action: (key) => closeSnack(t, closeSnackbar, key)
        });
    }

    const handleClickYes = () => {
        closeDialog();
        updateTask(new TaskRecord(form.fieldValues.current), success, error);
    };


    const handleClickNo = () => {
        closeDialog();
    };

    return (
        <div>
            <Dialog
                open={open}
                // onClose={handleClickNo}
            >
                <DialogTitle id="alert-dialog-title">{t("areYouSure")}</DialogTitle>
                <DialogContent style={{fontStyle: "italic"}}>
                    <DialogContentText id="alert-dialog-description" style={{color: "black"}}>
                        {t("rejectionOfResult")}
                        <br/>
                        <br/>
                        <h4 style={{color: "red", fontWeight: 500}}>{t("areYouSure")}</h4>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClickNo} style={{backgroundColor: "rgb(9, 50, 90, 1)"}} autoFocus>
                        {t("stop")}
                    </Button>
                    <Button onClick={handleClickYes} color="success">
                        {t("continue")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}


export default memo(({id, record, path, view, api}) => {
    const {t} = useTranslation('tasks');
    const [disabledButton, setDisabledButton] = useState(record.status !== "PLANNING");
    const [checkValues, setCheckValues] = useState(false);
    const [number, setNumber] = useState(0);
    const [nskSystem, setNskSystem] = useState(record.system === "NSK")
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [originalStatus, setOriginalStatus] = useState(record.status)
    const [statusArray, setStatusArray] = useState([])
    const [openDialog, setOpenDialog] = useState(false)
    let warningMessageKey = null
    const [disabled, setDisabled] = useState(false)
    const [print, setPrint] = useState(false)

    const authContext = useContext(AuthContext);

    const hasSORole = authContext.getIn(['session', 'roles']).some(role => [roles.SECURITY_OFFICER.toLowerCase()].indexOf(role) !== -1);

    const form = useForm({
        recordType: TaskRecord,
        recordSchema: TaskSchema,
        namespace: 'tasks',
        record: record
    });

    const action = key => {
        warningMessageKey = key
        return (
            <Fragment>
                <IconButton onClick={() => {
                    closeSnackbar(key)
                }}><CloseIcon/></IconButton>
            </Fragment>
        );
    }

    const processing = (record, onSuccess, onError) => {
        const success = () => {
            closeSnackbar(warningMessageKey)
            if ((record.system === "FORRAS_NET") && (originalStatus === "PLANNING") && (record.status === "PREPARED")) {
                enqueueSnackbar(t('forrasTicketsAreBeingLoaded'), {variant: 'info', autoHideDuration: 5000, action});
            }
            if ((record.system === "FORRAS_NET") && (originalStatus === "CLOSED") && (record.status === "SENT")) {
                enqueueSnackbar(t('returnToForras'), {variant: 'info', autoHideDuration: 4000, action});
            }
            enqueueSnackbar(t('general:save.success'), {variant: 'success', autoHideDuration: 4000});
            navigate(view === "add" ? "./" : "../")
        };

        const error = (error) => {
            if (error && error.status === 422) {
                enqueueSnackbar(
                    t('alreadyUsingThisErpId'), {variant: 'error', autoHideDuration: 10000, action}
                );
                onError({status: 422}, true);
            } else {
                onError(error, true);
            }
        };

        if ((originalStatus === "APPROVED") && (record.status === "PREPARED")) {
            setOpenDialog(true)
            return;
        }

        if ((originalStatus === "PLANNING") && (record.status === "PREPARED") && (record.system === "NSK"))
            enqueueSnackbar(t('ticketsAreBeingMade'), {variant: 'warning', autoHideDuration: 25000, action});

        if ((originalStatus === "APPROVED") && (record.status === "CLOSED"))
            enqueueSnackbar(t('closeTheTask'), {variant: 'warning', autoHideDuration: 25000, action});

        api(record, success, error);
    };

    const generating = (record) => {
        setDisabled(true)
        enqueueSnackbar(t('pdfFileIsBeingMade'), {variant: 'info', autoHideDuration: 100000, action});

        const success = (response) => {
            const link = document.createElement('a');
            link.href = response.url;
            link.download = record.pdfFileName;
            link.dispatchEvent(new MouseEvent('click'));
            setDisabled(false)
            closeSnackbar(warningMessageKey)
            enqueueSnackbar(t('successfulDownload'), {variant: 'success', autoHideDuration: 2500, action});
        };

        const error = (error) => {
            setDisabled(false)
            closeSnackbar(warningMessageKey)
            enqueueSnackbar(t('catchException:' + throwsExceptionMessage(error)), {
                variant: 'error', autoHideDuration: 5000, action
            });
        };

        // eslint-disable-next-line no-undef,react-hooks/rules-of-hooks
        useGenerateNDownloadPdf(record, success, error);
    };

    useEffect(() => {
        setOriginalStatus(record.status)
        setDisabledButton(record.status !== "PLANNING")
        setPrint((['CLOSED', 'SENT'].indexOf(record.status) !== -1))
        // eslint-disable-next-line default-case
        switch (record.status) {
            case "PLANNING":
                setStatusArray(["PLANNING", "PREPARED"]);
                break;
            case "APPROVED":
                if (hasSORole) {
                    setStatusArray(["PREPARED", "APPROVED", "CLOSED"])
                } else {
                    setStatusArray(["APPROVED", "CLOSED"]);
                }
                break;
            case "CLOSED":
                if (record.system !== "NSK") setStatusArray(["CLOSED", "SENT"]);
                else setStatusArray(["CLOSED"])
                break;
            default :
                setStatusArray([record.status])
        }
    }, [record.status]);

    const closeDialog = () => {
        setOpenDialog(false)
    }

    useEffect(() => {
        setNskSystem(record.system === "NSK")
    }, [record.system])

    useEffect(() => {
    }, [record.forrasNetStatus])

    // FIXME don't use fieldValues and validationStatuses directly
    useEffect(() => {
        let formValues = form.fieldValues.current;
        let formStatusValues = form.validationStatuses.current;
        setNskSystem(formValues.system === "NSK")

        if (formValues.status.indexOf("PLANNING") === -1) {
            if (formValues.system.indexOf("FORRAS_NET") >= 0) {
                if (formValues.erpId && formValues.erpId.length > 3) {
                    formValues.itemGroup = 0
                    formStatusValues.erpId = 'valid';
                    formStatusValues.itemGroup = 'valid';
                } else {
                    formValues.itemGroup = 0
                    formStatusValues.erpId = 'invalid';
                    formStatusValues.itemGroup = 'valid';
                }
            } else {
                if (formValues.itemGroup > 0) {
                    formValues.erpId = ""
                    formStatusValues.erpId = 'valid';
                    formStatusValues.itemGroup = 'valid';
                } else {
                    formValues.erpId = ""
                    formStatusValues.erpId = 'valid';
                    formStatusValues.itemGroup = 'invalid';
                }
            }
        } else {
            if (nskSystem) {
                formValues.erpId = ""
            } else {
                formValues.itemGroup = 0
            }
            formStatusValues.erpId = 'valid';
            formStatusValues.itemGroup = 'valid';
        }

        // eslint-disable-next-line default-case
        switch (true) {
            case (formValues.responsible < 1):
                formStatusValues.responsible = 'default-invalid';
                break;
            case (formValues.responsible === formValues.authorization):
                formStatusValues.responsible = 'invalid';
                formStatusValues.authorization = 'invalid';
                break;
            case (formValues.responsible >= 1) && (formValues.responsible !== formValues.authorization):
                formStatusValues.responsible = 'valid';
                break;
        }

        // eslint-disable-next-line default-case
        switch (true) {
            case (formValues.authorization < 1):
                formStatusValues.authorization = 'default-invalid';
                break;
            case (formValues.responsible === formValues.authorization):
                formStatusValues.responsible = 'invalid';
                formStatusValues.authorization = 'invalid';
                break;
            case (formValues.authorization >= 1) && (formValues.responsible !== formValues.authorization):
                formStatusValues.authorization = 'valid';
                break;
        }

        setNumber(number + 1)
    }, [checkValues]);

    debug("RENDER", "useForm", () => [" -> Form_Of_Tasks", id, record, path, view]);

    return (
        <ContentBox path={path} form={form}>
            {(view === "add") || !record.itemCount ? null :
                <Navigation taskId={id} path={path} print={print} disabled={disabled} generating={() => generating(record)} record={record}/>}

            {openDialog ? <AreYouSure open={openDialog} closeDialog={closeDialog} form={form} t={t}/> : null}

            <Section title={t('task')}>
                <div onClick={() => setCheckValues(!checkValues)}>
                    <ValidatedSelect name="responsible" recordFieldName={"fullName"} api={usePrincipals}
                                     filterOfApi={!disabledButton ? {role: "task-responsible"} : null}
                                     disabled={disabledButton}/>
                    <ValidatedSelect name="authorization" recordFieldName={"fullName"} api={usePrincipals}
                                     filterOfApi={!disabledButton ? {role: "task-authenticator"} : null}
                                     disabled={disabledButton}/>
                    <ValidatedSelect name="taskAdmin" recordFieldName={"fullName"} api={usePrincipals}
                                     filterOfApi={!disabledButton ? {role: "task-admin"} : null}
                                     disabled={disabledButton}/>
                </div>
                <ValidatedRadioGroup name="type">
                    <Grid container>
                        {!disabledButton ? TaskTypes.map(a => <ValidatedRadio name="type" value={a} key={a}
                                                                              disabled={a !== "INVENTORY"}/>) :
                            <ValidatedRadio name={record.type} value={record.type}
                                            key={record.type}/>}
                    </Grid>
                </ValidatedRadioGroup>
                <div onClick={() => setCheckValues(!checkValues)}>
                    <ValidatedRadioGroup name="status">
                        <Grid container>
                            {statusArray.map(a => <ValidatedRadio name="status" value={a} key={a}/>)}
                        </Grid>
                    </ValidatedRadioGroup>
                </div>
                <ValidatedInput name="name" disabled={disabledButton}/>
                <ValidatedDateTime name="plannedStart" disabled={disabledButton} date={true} time={false}
                                   timeDependency={{max: "plannedEnd"}}/>
                <ValidatedDateTime name="actualStart" disabled={true} date={true}
                                   time={false}/> {/*Will set this time from mobile phone*/}
                <ValidatedDateTime name="plannedEnd" disabled={disabledButton} date={true} time={false}
                                   timeDependency={{min: "plannedStart"}}/>
                <ValidatedDateTime name="actualEnd" disabled={true} date={true}
                                   time={false}/> {/*Will set this time from mobile phone*/}
                <div onClick={() => setCheckValues(!checkValues)}>
                    <ValidatedRadioGroup name="system">
                        <Grid container>
                            {!disabledButton ? TaskSystems.map(a => <ValidatedRadio name="system" value={a} key={a}/>) :
                                <ValidatedRadio name={record.system} value={record.system}
                                                key={record.system}/>}
                        </Grid>
                    </ValidatedRadioGroup>
                    <div hidden={nskSystem}>
                        <ValidatedInput name="erpId" disabled={disabledButton} validator={erpIdValidator}/>
                        {record.forrasNetStatus && record.forrasNetStatus.length > 3 ?
                            <ValidatedInput name="forrasNetStatus" multiline={true} rows={3} disabled={true}/>
                            : null}
                    </div>
                    <div hidden={!nskSystem}>
                        <ValidatedSelect name="itemGroup" api={useInventoryItemGroups} disabled={disabledButton}
                                         nullable validator={groupValidator}/>
                    </div>
                </div>
                <ValidatedInput name="itemCount" disabled/>
            </Section>

            <Grid container spacing={2}>
                <Grid item>
                    <ValidatedSubmit view={view} api={processing}/>
                </Grid>
            </Grid>


        </ContentBox>
    );
})