export default {
    "tag": "Tag",
    "tags": "Tagek",
    "serialNumberArray": "Tagek szériaszámai",
    "serialNumberArray-invalid": "A mező érvénytelen. Minimum egy karaktert kell tartalmaznia a mezőnek.",
    "uploadTag": "Tagek feltöltése",
    "uploadTagInDb": "Tagek feltöltése az adatbázisba",
    "serial": "Széria",
    "serial-invalid": "A széria érvénytelen. Minimum 2 és maximum 100 karakter lehet.",
    "add": "Tag hozzáadása",
    "uploadAdd": "Tagek hozzáadása",
    "back": "Vissza a listához",
    "save": "Tag mentése",
    "action": "Műveletek",
    "id": "Azonosító",
    "histories": "Előzmények",
    "status": "Státusz",
    "translStatus": "Státusz",
    'UNASSIGNED': "Nincs hozzárendelve",
    'ASSIGNED': "Hozzárendelve",
    'EXPIRED': "Lejárt",
    'LOST': "Elveszett",
    "readerUploadTag" : "Tagek beolvasása",
    "noteOnUploadForm": "Minden egyes új sorba kezdett karakter láncból lesz az új szériaszám az adatbázisban. Ha már létezik a szériaszám, akkor azt figyelmen kívül hagyjuk.",
    "readerUploadTagInDb": "Tagek beolvasása",
    "noteOnReaderUploadForm": "<p>Bekapcsolt olvasó esetén a beolvasás folyamatosan történik. A számlálóban automatikusan " +
        "frissül a látott tagek száma (ha a tekercselő működik, ez a szám folyamatosan nő). A látott tagek listája 1 órán " +
        "keresztül érhető el a felületről.</p>" +
        "<p>A <b>Listázás gombbal</b> a képernyő alján megjelenik a látott tagek listája.</p>" +
        "<p>Az <b>Alapállapotba állítás</b> gomb a látott tagek listáját elveti.</p>" +
        "<p>A <b>Tagek feltöltése</b> gombbal történik meg a látott tagek adatbázisba mentése.</p>" +
        "<p>A <b>Mai napon feltöltött tagek</b> gomb kilistázza a mai napon adatbázisba mentett tageket.</p>",
    "readerUploadTagList" : "LÁTOTT TAGEK LISTÁJA",
    "noteOnReaderUploadTagList" : "A Listázás gombra kattintva lehet megjeleníteni az importálható látott tagek listáját.",
    "readerUploadTagResult" : "SIKERESEN FELTÖLTÖTT TAGEK",
    "noteOnReaderUploadTagResult" : "A <b>Tagek feltöltése</b> gomb hatására a látott tagek közül csak az adatbázisban még nem" +
        " szereplő, új tagek kerülnek mentésre.",
    "tagSerial" : "Széria"
};