import React, {memo, useState} from "react";
import ContentBox from "../../../../Components/ContentBox/ContentBox";
import debug from "../../../../Lib/debug";
import {FilterField} from "../../../../Components/Table/FilterField";
import {SimplexionTable} from "../../../../Lib/simplexion-table/SimplexionTable";
import {makeStyles} from "@material-ui/core";
import {makeTableStyles} from "../../../../Components/Table/tableStyles";
import {baseTableStyles} from "../../../../Lib/simplexion-table/baseStyle";
import {updatableCheckBoxStyle} from "../../../../Lib/simplexion-table/templates/checkbox-input";
import {inputFieldStyles} from "../../../../Lib/simplexion-table/templates/text-input";
import {useTranslation} from "react-i18next";
import {useInventoryItemHistoryEntries} from "../../../../Api/inventory-item-histories";
import LinearProgress from "@material-ui/core/LinearProgress";
import Section from "../../../../Lib/form/Components/Section/Section";

const useTableStyles = makeStyles(makeTableStyles(baseTableStyles, updatableCheckBoxStyle, inputFieldStyles));

const List = memo((props) => {

    debug("RENDER", "useForm", () => [" -> List_Of_Inventory_Item_History_Entries", props]);

    const {path, parentId} = props;

    const {loadingStatus, data} = parentId ? useInventoryItemHistoryEntries(parentId) : [];

    const {t} = useTranslation("inventoryItemHistoryEntries");

    const tableClasses = useTableStyles();

    const [state, setState] = useState('');

    const handleFilter = val => setState(val);

    const tableConfig = {
        id: 'inventory-item-history-entries-table',
        nameSpace: 'inventoryItemHistoryEntries',
        stickyHeader: true,
        sortable: true,
        styles: tableClasses,
        columns: [
            {
                accessor: 'id',
                sortable: true
            },
            {
                accessor: 'itemName',
                sortable: true
            },
            {
                accessor: 'taskName',
                sortable: true
            },
            {
                accessor: 'type',
                sortable: true,
                renderer: (accessor, record) => `<span>${t(record.type)}</span>`

            }
        ],
    };

    return (
        <ContentBox path={path}>
            <Section>
                <FilterField handler={handleFilter}/>
                {loadingStatus > 0 ? <LinearProgress/> : null}
                <SimplexionTable tableConfig={tableConfig} data={data} loadingStatus={loadingStatus}
                                 filterValue={state}/>
            </Section>
        </ContentBox>
    );
});

export default List;