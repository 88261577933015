export default {
    "inventoryItemHistoryEntries": "Eszköz előzmények",
    "inventoryItemHistoryEntry": "Eszköz előzmény",
    "add": "Eszköz előzmény hozzáadása",
    "edit": "Eszköz előzmény szerkesztése",
    "remove": "Eszköz előzmény törlése",
    "back": "Vissza a listához",
    "save": "Eszköz előzmény mentése",
    "action": "Műveletek",
    "id": "Azonosító",
    "item": "Eszköz",
    "itemName": "Eszköz",
    "task": "Feladat",
    "taskName": "Feladat",
    "type": "Típus",
    'ENTRY': "Rendszerbe került",
    'RFID_ASSIGN': "Hozzárendelve",
    'RFID_READ': "Olvasott",
    'MANUAL_VERIFICATION': "Kézi ellenőrzés",
    'EXPIRATION': "Selejtezett"
};