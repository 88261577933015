import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../Lib/form/useForm";
import ContentBox from "../../Components/ContentBox/ContentBox";
import {Navigation} from "./Navigation";
import Section from "../../Lib/form/Components/Section/Section";
import ValidatedInput from "../../Lib/form/Components/ValidatedInput/ValidatedInput";
import ValidatedSelect from "../../Lib/form/Components/ValidatedSelect/ValidatedSelect";
import {useFacilityTypes} from "../../Api/facility-types";
import ValidatedSubmit from "../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import {FacilityRecord, FacilitySchema} from "../../Data/facility";
import debug from "../../Lib/debug";
import {usePrincipals} from "../../Lib/auth/Api/principals";

export default memo(({id, record, path, view, api, hasAdminRoles}) => {

    const {t} = useTranslation("facilities");

    const form = useForm({
        recordType: FacilityRecord,
        recordSchema: FacilitySchema,
        namespace: 'facilities',
        record
    });

    debug("RENDER", "useForm", () => [" -> Form_Of_Facilities", view, id, path, record]);

    return (
        <ContentBox path={path} form={form}>

            {view === "add" ? null : <Navigation parentId={id} path={path}/>}

            <Section title={t('facility')}>
                <ValidatedInput name="name" disabled={!hasAdminRoles}/>
                <ValidatedInput name="erpId" disabled={true}/>
                <ValidatedInput name="country" disabled={!hasAdminRoles}/>
                <ValidatedInput name="state" disabled={!hasAdminRoles}/>
                <ValidatedInput name="city" disabled={!hasAdminRoles}/>
                <ValidatedInput name="address1" disabled={!hasAdminRoles}/>
                <ValidatedInput name="address2" disabled={!hasAdminRoles}/>
                <ValidatedSelect name="type" api={useFacilityTypes} disabled={!hasAdminRoles}/>
                <ValidatedInput name="description" multiline={true} rows={3} disabled={!hasAdminRoles}/>
                <ValidatedSelect name="administrativeContact" recordFieldName={"fullName"} api={usePrincipals}
                                 disabled={!hasAdminRoles}/>
                <ValidatedSelect name="technicalContact" recordFieldName={"fullName"} api={usePrincipals}
                                 disabled={!hasAdminRoles}/>
            </Section>

            <ValidatedSubmit view={view} api={api} hideSave={!hasAdminRoles}/>

        </ContentBox>
    );
})