import {baseTableStyles} from "../../Lib/simplexion-table/baseStyle";
import {customActionsStyle} from "../../Lib/simplexion-table/templates/action-buttons";

export const tableStyles = {
    title: {
        marginTop: '1rem',
        marginBottom: '1rem',
    },
    description: {
        marginBottom: '1rem'
    },
    tableContainerWithHeight: {
        '& div[id|=simplexion-table]': {
            maxHeight: '22rem',
        }
    },
    filterField: {
        marginBottom: '1rem',
        marginTop: '1rem',
        display: "flex",
        flexDirection: "row"
    },
    filterInputField: {

    },
    filterButton: {
        marginLeft: '1.5rem',
    },
    propertyList: {
        margin: '1rem 0',
    },
    button: {
        marginLeft: '1.5rem',
        backgroundColor: "#396593"
    },

};

export const styles = () => {
    let classes = baseTableStyles;
    Object.entries(customActionsStyle).forEach(entry => {
        classes[entry[0]] = entry[1];
    });
    return classes;
};

export const makeTableStyles = (...args) => {
    let jss = {};
    args.forEach(styles => {
        Object.entries(styles).forEach(entry => {
            jss[entry[0]] = entry[1];
        });
    });

    return jss;
};