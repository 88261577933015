import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../../../Lib/form/useForm";
import {
    InventoryItemHistoryEntryRecord,
    InventoryItemHistoryEntrySchema
} from "../../../../Data/inventory-item-histrory-entry";
import ContentBox from "../../../../Components/ContentBox/ContentBox";
import Section from "../../../../Lib/form/Components/Section/Section";
import ValidatedSubmit from "../../../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import debug from "../../../../Lib/debug";
import ValidatedSelect from "../../../../Lib/form/Components/ValidatedSelect/ValidatedSelect";
import Grid from "@material-ui/core/Grid";
import ValidatedRadio from "../../../../Lib/form/Components/ValidatedRadio/ValidatedRadio";
import ValidatedRadioGroup from "../../../../Lib/form/Components/ValidatedRadio/ValidatedRadioGroup";
import {useTasks} from "../../../../Api/tasks";
import {InventoryItemHistoryType} from "../../../../Data/InventoryItemHistoryType"


export default memo(({id, record, path, view, api, hasAdminRoles}) => {
    const {t} = useTranslation("inventoryItemHistoryEntries");

    const form = useForm({
        recordType: InventoryItemHistoryEntryRecord,
        recordSchema: InventoryItemHistoryEntrySchema,
        namespace: 'inventoryItemHistoryEntries',
        record: record
    });

    debug("RENDER", "useForm", () => [" -> Form_Of_Inventory_Item_History_Entry", view, id, path, record]);

    return (
        <ContentBox path={path} form={form}>

            <Section title={t('inventoryItemHistoryEntry')}>
                {/*<ValidatedSelect name="item" api={useInventoryItems} disabled={!hasAdminRoles}/>*/}
                <ValidatedSelect name="task" api={useTasks} disabled={!hasAdminRoles}/>
                <ValidatedRadioGroup name="type">
                    <Grid container>
                        {InventoryItemHistoryType.map(a => <ValidatedRadio name="type" value={a} key={a}/>)}
                    </Grid>
                </ValidatedRadioGroup>
            </Section>

            <ValidatedSubmit view={view} api={api} hideSave={!hasAdminRoles}/>

        </ContentBox>
    );
})