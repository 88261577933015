import {Record} from 'immutable';
import {lazy, mixed, number, object, string} from "yup";
import {GeneralStatus} from "./GeneralStatus";

export const FacilityUnitRecord = Record({
    id: 0,
    erpId: "",
    designation: "",
    name: "",
    description: "",
    facility: 0,
    parentUnit: null,
    tag: 0,
    picture: null,
    status: "ACTIVE",
    barCode: "",
    translStatus: ""

});

export const FacilityUnitSchema = object().shape({
    id: number().required().min(0).default(0),
    erpId: string().nullable().default(""),
    designation: string().required().min(2).max(120).default(''),
    name: string().required().min(2).max(120).default(''),
    description: string().nullable().max(10000).default(''),
    facility: number().required().min(1).default(0),
    parentUnit: number().nullable().default(null),
    tag: lazy(value => !value ? number().nullable().default(null) : number().min(1).default(0)),
    picture: number().nullable().default(null),
    status: mixed().required().oneOf(GeneralStatus).default("ACTIVE"),
    barCode: string().nullable().max(180).default(""),
    translStatus: string().nullable().default("")

});
