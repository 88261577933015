import React, {Fragment, memo, useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../../Lib/form/useForm";
import ContentBox from "../../../Components/ContentBox/ContentBox";
import Section from "../../../Lib/form/Components/Section/Section";
import ValidatedInput from "../../../Lib/form/Components/ValidatedInput/ValidatedInput";
import ValidatedSubmit from "../../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import ValidatedSelect from "../../../Lib/form/Components/ValidatedSelect/ValidatedSelect";
import {useFacilityUnits} from "../../../Api/facility-units";
import {InventoryItemRecord, InventoryItemSchema} from "../../../Data/inventory-item";
import ValidatedDateTime from "../../../Lib/form/Components/ValidatedDateTime/ValidatedDateTime";
import debug from "../../../Lib/debug";
import {useInventoryAreas} from "../../../Api/inventory-areas";
import {useRfidTags} from "../../../Api/rfid-tags";
import Grid from "@material-ui/core/Grid";
import {GeneralStatus} from "../../../Data/GeneralStatus";
import ValidatedRadio from "../../../Lib/form/Components/ValidatedRadio/ValidatedRadio";
import ValidatedRadioGroup from "../../../Lib/form/Components/ValidatedRadio/ValidatedRadioGroup";
import {useInventoryItems} from "../../../Api/inventory-items";
import {useItemOwners} from "../../../Api/item-owners";
import {useItemUsers} from "../../../Api/item-users";
import {Navigation} from "./Navigation";
import {Gallery} from "../../../Components/ImageGallery";
import {useSnackbar} from "notistack";
import ValidatedFilteredSelect from "../../../Lib/form/Components/ValidatedFilteredSelect/ValidatedFilteredSelect";
import ValidatedCheckbox from "../../../Lib/form/Components/ValidatedCheckbox/ValidatedCheckbox";
import {usePrincipals} from "../../../Lib/auth/Api/principals";
import {closeSnack} from "../../../Main/util";
import {useFacilities} from "../../../Api/facilities";
import Button from "../../../Lib/material-dashboard/components/CustomButtons/Button";
import {Button as ButtonOfSnackbar} from "@material-ui/core";

import {AuthContext} from "../../../Lib/auth/AuthContext";
import {roles} from "../../../Main/roles";

export default memo(({id, record, path, view, api, hasAdminRoles}) => {
    const [locked, setLocked] = useState(record.locked);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [disabled, setDisabled] = useState(false)
    const authContext = useContext(AuthContext);
    const canEditing = hasAdminRoles ? hasAdminRoles : authContext.getIn(['session', 'roles']).some(role => [roles.TASK_ADMIN.toLowerCase()].indexOf(role) !== -1);

    const {t} = useTranslation("inventoryItems");

    const form = useForm({
        recordType: InventoryItemRecord,
        recordSchema: InventoryItemSchema,
        namespace: 'inventoryItems',
        record: record
    });

    debug("RENDER", "useForm", () => [" -> Form_Of_Inventory_Items", id, record, path, view]);

    useEffect(() => {
        setLocked(record.locked ? record.locked : !canEditing)
        if (record.locked) enqueueSnackbar(t('workInProgress'), {
            autoHideDuration: 5500,
            variant: 'warning',
            action: (key) => closeSnack(t, closeSnackbar, key)
        })
    }, [record.locked]);

    useEffect(() => {
        if (record.waste) enqueueSnackbar(t('scrappingText'), {
            autoHideDuration: 7000,
            variant: 'warning',
            action: (key) => closeSnack(t, closeSnackbar, key)
        })

        if (record.catalog) enqueueSnackbar(t('inCatalogText'), {
            autoHideDuration: 7000,
            variant: 'success',
            action: (key) => closeSnack(t, closeSnackbar, key)
        })
    }, [record.waste, record.catalog])

    const action = key => (
        <Fragment>
            <ButtonOfSnackbar onClick={() => {
                closeSnackbar(key);
                setDisabled(false)
                window.history.back();
            }}>
                {t('general:yes')}
            </ButtonOfSnackbar>
            <ButtonOfSnackbar onClick={() => {
                setDisabled(false)
                closeSnackbar(key)
            }}>
                {t('general:no')}
            </ButtonOfSnackbar>
        </Fragment>
    );

    return (
        <ContentBox path={path} form={form}>
            {view === "add" ? null :
                <Navigation parentId={id} relatedItems={false} path={path}/>}

            <Section title={t('inventoryItem')}>
                <ValidatedInput name="name" disabled={locked}/>
                <ValidatedInput name="erpId" disabled/>
                <ValidatedInput name="erpIdAccessory" disabled/>
                <ValidatedFilteredSelect name="mainPart" api={useInventoryItems} nullable
                                         disabled={locked} recordFieldNames={["erpId", "erpIdAccessory", "name"]}/>
                <ValidatedInput name="description" multiline={true} rows={3} disabled={locked}/>
                <ValidatedInput name="unit" disabled={true}/>
                <ValidatedInput name="quantity" disabled={locked}/>
                {view === "add" ? null :
                    <ValidatedSelect name="facility" api={useFacilities} disabled={true}/>}
                <ValidatedSelect name="facilityUnit" api={useFacilityUnits} disabled={locked}/>
                <ValidatedSelect name="inventoryArea" api={useInventoryAreas} nullable={true} disabled={locked}/>
                <ValidatedDateTime name="lastInventoryTime" disabled={true} date={true} time={false}/>
                <ValidatedSelect name="lastInventoryAccount" api={usePrincipals} recordFieldName="fullName"
                                 nullable={true} disabled={true}/>
                <div style={{pointerEvents: locked ? "none" : "auto"}}>
                    <ValidatedRadioGroup name="status">
                        <Grid container>
                            {GeneralStatus.map(a => <ValidatedRadio name="status" value={a} key={a}/>)}
                        </Grid>
                    </ValidatedRadioGroup>
                </div>
                <ValidatedSelect name="tag" recordFieldName={"serial"} nullable={true} api={useRfidTags}
                                 disabled={true}/>
                <ValidatedSelect name="itemOwner" api={useItemOwners} nullable={true} disabled={locked}/>
                <ValidatedSelect name="itemUser" api={useItemUsers} nullable={true} disabled={locked}/>
                {/*<ValidatedInput name="barCodes" disabled={locked}/>*/}
                <Grid container direction="column" justify="flex-start" alignItems="flex-start"
                      style={{padding: '1rem', pointerEvents: locked ? "none" : "auto"}}>
                    <Grid item sm style={{padding: "0.4rem"}}><ValidatedCheckbox name={"catalog"} label={t("catalog")}/></Grid>
                    <Grid item sm style={{padding: "0.4rem"}}><ValidatedCheckbox name={"waste"}
                                                                                 label={t("waste")}/></Grid>
                    <Grid item sm style={{padding: "0.4rem", pointerEvents: "none"}}><ValidatedCheckbox name={"locked"}
                                                                                                        label={t("locked")}/></Grid>
                </Grid>
                <Gallery name="pictures" disabled={locked} view={view} multiple={true}/>
            </Section>

            <Grid container spacing={2}>
                <Grid item>
                    <Button
                        style={{backgroundColor: "rgb(9, 50, 90, 1)"}}
                        disabled={disabled}
                        onClick={() => {
                            setDisabled(true)
                            enqueueSnackbar(t('general:areYouSure'), {
                                variant: 'warning',
                                autoHideDuration: 5000,
                                action
                            })
                        }}
                    >
                        {t('back')}
                    </Button>
                </Grid>
                <Grid item>
                    <ValidatedSubmit view={view} api={api} hideCancel={true}
                                     hideSave={locked}
                                     onSuccess={() => window.history.back()}/>
                </Grid>
            </Grid>

        </ContentBox>
    );
});