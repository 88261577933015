import React from "react";
import ContentBox from "../../Components/ContentBox/ContentBox";
import {makeStyles} from "@material-ui/styles";
import backgroundImage from "./72774641_381121549459312_1177208131621486592_n.png"

const useStyles = makeStyles({
    button: {
        width: "12em",
        backgroundColor: "#396593"
    },
    paperContainer: {
        backgroundColor: "#EFF5F0",
        position: 'absolute',
        marginLeft: '-2rem',
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "contain",
        height: '100%',
        width: '100%',
        backgroundImage: `url(${backgroundImage})`
    }
});

function Dashboard() {

    const classes = useStyles();

    return (
        <ContentBox>
            <div className={classes.paperContainer}>
            </div>
        </ContentBox>
    )
}

export default Dashboard;