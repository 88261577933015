import React from "react";
import {useRoutes} from 'hookrouter';
import List from "./List";
import {addGroup, removeGroup, updateGroup, useGroup} from "../../Api/groups";
import Crud from "../../../Crud";
import {GroupRecord, GroupSchema} from "../../Data/Group";
import Form from "./Form";
import Memberships from "./Memberships/Memberships";


export default () => useRoutes(subRoutes);

const subRoutes = {
    "/": () => <Crud config={crudConfig} view="list"/>,
    "/add": () => <Crud config={crudConfig} view="add"/>,
    "/:id/edit": ({id}) => <Crud config={crudConfig} view="edit" id={id}/>,
    "/:id/remove": ({id}) => <Crud config={crudConfig} view="remove" id={id}/>,
    "/:id/memberships*": ({id}) => <Crud config={crudConfig} OptComponent={Memberships}
                                         optUrlSectionNLabel='memberships' view="other" id={id}/>,
};

const crudConfig = {
    compName: 'Groups',
    namespace: 'groups',
    url: '/admin/groups',
    labelField: 'name',
    Record: GroupRecord,
    Schema: GroupSchema,
    ListComponent: List,
    FormComponent: Form,
    api: {
        use: useGroup,
        add: addGroup,
        edit: updateGroup,
        remove: removeGroup,
    }
};


