import {Record} from 'immutable';
import {number, object, string} from "yup";

export const ProcedureRecord = Record({
    id: 0,
    type: 0,
    name: '',
    procedure: '',

});
// FIXME
export const ProcedureSchema = object().shape({
    id: number().required().min(0).default(0),
    type: string().required().min(1).default(0),
    name: string().required().min(1).max(60).default(''),
    procedure: string().required().min(0).max(10000).default(''),
});