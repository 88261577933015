import React, {useEffect, useState} from "react";
import ContentBox from "../../Components/ContentBox/ContentBox";
import {SimplexionTable} from "../../Lib/simplexion-table/SimplexionTable";
import {baseTableStyles} from "../../Lib/simplexion-table/baseStyle";
import {makeStyles} from "@material-ui/core";
import {makeTableStyles, tableStyles} from "../../Components/Table/tableStyles"
import {FilterField} from "../../Components/Table/FilterField";
import {inputFieldStyles} from "../../Lib/simplexion-table/templates/text-input";
import {updatableCheckBoxStyle} from "../../Lib/simplexion-table/templates/checkbox-input";
import {addNskForrasJob, itemChanges, useNskForrasJobs} from "../../Api/nsk-forras-jobs";
import {useTranslation} from "react-i18next";
import Button from "@material-ui/core/Button";
import Section from "../../Lib/form/Components/Section/Section";
import LinearProgress from "@material-ui/core/LinearProgress";

const useTableStyles = makeStyles(makeTableStyles(baseTableStyles, updatableCheckBoxStyle, inputFieldStyles));

const useStylesFilterField = makeStyles(tableStyles);

export default () => {

    const t = useTranslation("nskForrasJobs").t;

    const tableClasses = useTableStyles();

    const classes = useStylesFilterField();

    const [revision, setRevision] = useState(0);

    const [state, setState] = useState('');

    const {loadingStatus, data} = useNskForrasJobs(undefined, revision);

    useEffect(() => {
    }, [revision])

    const handleFilter = val => setState(val);

    const path = [{label: t('nskForrasJobs'), url: "/nsk-forras-jobs"}];

    const tableConfig = {
        id: 'nsk-forras-jobs-table',
        nameSpace: 'nskForrasJobs',
        stickyHeader: true,
        sortable: true,
        styles: tableClasses,
        columns: [
            {accessor: 'id'},
            {accessor: 'createdAt'},
            {accessor: 'createdBy'},
            {accessor: 'downloadType'},
            {accessor: 'status'},
            {accessor: 'facilityAdded'},
            {accessor: 'facilityUnitAdded'},
            {accessor: 'itemUserAdded'},
            {accessor: 'itemOwnerAdded'},
            {accessor: 'inventoryItemAdded'},
        ],
    };

    const startAllJob = () => {
        addNskForrasJob();
        setRevision(revision + 1);
    };

    const startChangesJob = () => {
        itemChanges();
        setRevision(revision + 1);
    };

    return (
        <ContentBox path={path}>
            <Section>
                <FilterField
                    handler={handleFilter}
                    buttons={<div>
                        <Button onClick={startAllJob} variant="contained" color="primary" size='medium'
                                className={classes.button}>{t('complete')}</Button>
                        <Button onClick={startChangesJob} variant="contained" color="primary" size='medium'
                                className={classes.button}>{t('changes')}</Button>
                    </div>}/>
                {loadingStatus > 0 ? <LinearProgress/> : null}
                <SimplexionTable tableConfig={tableConfig} data={data} loadingStatus={loadingStatus}
                                 filterValue={state}/>
            </Section>
        </ContentBox>
    );
};