import useForm from "../../../form/useForm";
import React, {memo} from "react";
import ValidatedInput from "../../../form/Components/ValidatedInput/ValidatedInput";
import ValidatedSubmit from "../../../form/Components/ValidatedSubmit/ValidatedSubmit";
import {useTranslation} from "react-i18next";
import ContentBox from "../../../../Components/ContentBox/ContentBox";
import Section from "../../../form/Components/Section/Section";
import {CreationRuleRecord, CreationRuleSchema} from "../../Data/CreationRule";
import ValidatedRichTextEditor from "../../../form/Components/ValidatedRichTextEditor/ValidatedRichTextEditor";
import debug from "../../../debug";
import ValidatedSelect from "../../../form/Components/ValidatedSelect/ValidatedSelect";
import {useProcedures} from "../../Api/procedures";

export default memo(({id, record, path, view, api}) => {
    const {t} = useTranslation("creationRules");

    const form = useForm({
        recordType: CreationRuleRecord,
        recordSchema: CreationRuleSchema,
        namespace: 'creationRules',
        record
    });

    debug("RENDER", "useForm", () => [" -> Form_Of_Creation_Rules", id, record, path, view]);


    return (
        <ContentBox path={path} form={form}>

            <Section title={t("creationRule")}>
                <ValidatedInput name="name"/>
                <ValidatedSelect name="allowPep" api={useProcedures}/>
                <ValidatedInput name="allowPermission"/>
                <ValidatedSelect name="assignPep" api={useProcedures}/>
                <ValidatedRichTextEditor name="description" showLabel={t("description")}/>
                <ValidatedSubmit view={view} api={api}/>
            </Section>
        </ContentBox>
    );
})