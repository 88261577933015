import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../../Lib/form/useForm";
import ContentBox from "../../../Components/ContentBox/ContentBox";
import Section from "../../../Lib/form/Components/Section/Section";
import ValidatedInput from "../../../Lib/form/Components/ValidatedInput/ValidatedInput";
import ValidatedSubmit from "../../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import {InventoryAreaRecord, InventoryAreaSchema} from "../../../Data/inventory-area";
import debug from "../../../Lib/debug";
import ValidatedRadioGroup from "../../../Lib/form/Components/ValidatedRadio/ValidatedRadioGroup";
import Grid from "@material-ui/core/Grid";
import {GeneralStatus} from "../../../Data/GeneralStatus";
import ValidatedRadio from "../../../Lib/form/Components/ValidatedRadio/ValidatedRadio";
import {Navigation} from "./Navigation";

export default memo(({id, record, path, view, api}) => {

    const {t} = useTranslation("inventoryAreas");

    const form = useForm({
        recordType: InventoryAreaRecord,
        recordSchema: InventoryAreaSchema,
        namespace: 'inventoryAreas',
        record
    });

    debug("RENDER", "useForm", () => [" -> Form_Of_Inventory_Area", view, id, path, record]);

    return (
        <ContentBox path={path} form={form}>
            {view === "add" ? null : <Navigation parentId={id} path={path}/>}

            <Section title={t('inventoryArea')}>
                <ValidatedInput name="name" disabled={true}/>
                <ValidatedInput name="erpId" disabled/>
                <ValidatedRadioGroup name="status">
                    {view === "add" ?
                        <Grid container>
                            {GeneralStatus.map(a => <ValidatedRadio name="status" value={a} key={a}/>)}
                        </Grid> : <Grid container>
                            <ValidatedRadio name="status" value={record.status} key={record.status}/>
                        </Grid>}
                </ValidatedRadioGroup>
            </Section>

            <ValidatedSubmit view={view} api={api} hideSave={true}/>

        </ContentBox>
    );
})