import React, {memo} from "react";
import ContentBox from "../../../Components/ContentBox/ContentBox";
import {useTranslation} from "react-i18next";
import useForm from "../../../Lib/form/useForm";
import {UploadRfidTagsRecord, UploadRfidTagsSchema} from "../../../Data/rfid-tag";
import debug from "../../../Lib/debug";
import Section from "../../../Lib/form/Components/Section/Section";
import ValidatedSubmit from "../../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import {updateRfidTags} from "../../../Api/rfid-tags";
import ValidatedInput from "../../../Lib/form/Components/ValidatedInput/ValidatedInput";


export const UploadRFIDTags = memo(() => {

    const {t} = useTranslation('tags');

    const paths = [{label: t("tags"), url: "/mdm/tags"}, {label: t("uploadTag"), url: "/mdm/tags/upload"}]

    const form = useForm({
        recordType: UploadRfidTagsRecord,
        recordSchema: UploadRfidTagsSchema,
        namespace: 'tags',
        record: new UploadRfidTagsRecord()
    });
    debug("RENDER", "useForm", () => [" -> Form_Of_The_Upload_Rfid_Tags", form, paths]);

    return (
        <ContentBox path={paths} form={form}>

            <Section title={t('uploadTagInDb')} notes={t("noteOnUploadForm")}>
                <ValidatedInput name={"serialNumberArray"} multiline={true} rows={5}/>
            </Section>

            <ValidatedSubmit view={"add"} api={updateRfidTags} submitLabel={t("uploadTag")}/>

        </ContentBox>
    )

});
