import {Record} from 'immutable';
import {mixed, number, object, string} from "yup";
import {GeneralStatus} from "./GeneralStatus";

export const InventoryTicketRecord = Record({
    id: 0,
    task: 0,
    erpId: '',
    inventoryArea: null,
    inventoryItem: 0,
    registeredQuantity: null,
    foundQuantity: null,
    registeredFacilityUnit: null,
    registeredFacilityUnitName: "",
    foundFacilityUnit: null,
    foundFacilityUnitName: "",
    registeredStatus: '',
    foundStatus: '',
    itemName: '',
    translRegisteredStatus: '',
    translFoundStatus: '',
    registeredFacilityUnitErpId: '',
    foundFacilityUnitErpId: '',
    inventoryAreaErpId: '',
    inventoryItemErpId: '',
});

export const InventoryTicketSchema = object().shape({
    id: number().required().min(0).default(0),
    task: number().required().min(1).default(0),
    erpId: string().nullable().default(''),
    inventoryArea: number().nullable().default(null),
    inventoryItem: number().required().min(1).default(0),
    registeredQuantity: number().nullable().default(null),
    foundQuantity: number().nullable().default(null),
    registeredFacilityUnit: number().nullable().default(null),
    registeredFacilityUnitName: string().nullable().default(""),
    foundFacilityUnit: number().nullable().default(null),
    foundFacilityUnitName: string().nullable().default(""),
    registeredStatus: mixed().nullable().oneOf(GeneralStatus),
    foundStatus: mixed().nullable().oneOf(GeneralStatus),
    itemName: string().nullable().default(null),
    translRegisteredStatus: string().nullable().default(null),
    translFoundStatus: string().nullable().default(null),
    registeredFacilityUnitErpId: string().nullable().default(null),
    foundFacilityUnitErpId: string().nullable().default(null),
    inventoryAreaErpId: string().nullable().default(null),
    inventoryItemErpId: string().nullable().default(null),
    tagSerial: string().nullable().default(null),
    foundFacilityName: string().nullable().default(null),
    foundFacilityErpId: string().nullable().default(null)

});

export const TicketDTOForListRecord = Record({
    id: 0,
    inventoryAreaErpId: '',
    erpId: '',
    inventoryItemErpId: '',
    itemName: '',
    itemStatus: '',
    registeredFacilityErpId: '',
    registeredFacilityUnitErpId: '',
    registeredFacilityName: "",
    registeredFacilityUnitName: "",
    foundFacilityUnitErpId: '',
    foundFacilityUnitName: "",
    registeredQuantity: null,
    foundQuantity: null,
    translRegisteredStatus: '',
    translFoundStatus: '',
    tagSerial: '',
    foundFacilityName: '',
    foundFacilityErpId: ''
});