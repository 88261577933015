import React, {useContext, useEffect, useState} from "react";
import GridItem from "../../material-dashboard/components/Grid/GridItem";
import CustomInput from "../../material-dashboard/components/CustomInput/CustomInput";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import {primaryColor, successColor} from "../../material-dashboard/assets/jss/material-dashboard-pro-react";
import {useTranslation} from "react-i18next";
import {SearchContext} from "../Context/SearchContext";
import {actions} from "../Data/Actions";
import Fade from "@material-ui/core/Fade";


function AdornmentButton(props) {

    const {value, handler} = props;

    return (
        <Fade in={value.length !== 0} timeout={300} exit={true}>
            <IconButton href='' size='small' onClick={() => handler()}>
                <AddCircleIcon style={{color: primaryColor[0]}}/>
            </IconButton>
        </Fade>
    );
}

function SuccessIcon(props) {

    const {saved} = props;

    return (
        <Fade in={saved} timeout={300} exit={true}>
            <CheckCircleOutlineIcon style={{color: successColor[0]}}/>
        </Fade>
    );
}


function OptionText(props) {

    const [value, changeValue] = useState('');
    const [saved, changeSaved] = useState(false);
    const {t} = useTranslation();
    const {dispatch} = useContext(SearchContext);

    const {name, urlData} = props;

    let successTimeout;

    useEffect(() => dispatch(actions.loadOptionsSuccessText(name, [], urlData)), [dispatch, urlData, name]);

    const handleClick = () => {
        dispatch(actions.addConditionText(name, value));

        changeValue('');
        changeSaved(true);

        successTimeout = window.setTimeout(() => changeSaved(false), 3000);
    };

    const handleChange = (event) => {
        if (saved) changeSaved(false);
        if (successTimeout) window.clearTimeout(successTimeout);

        changeValue(event.target.value);
    };

    return (
        <GridItem xs={12} sm={12} md={3} lg={3}>
            <CustomInput
                labelText={t(name)}
                formControlProps={{fullWidth: true}}
                inputProps={{
                    id: ("sb_input_" + name),
                    value: value,
                    onChange: (event) => handleChange(event),
                    endAdornment: (
                        <InputAdornment position="end" children={
                            saved ?
                                <SuccessIcon saved={saved}/> :
                                <AdornmentButton value={value} handler={() => handleClick()}/>
                        }/>
                    )
                }}/>
        </GridItem>
    );
}

export default OptionText;