import {Record} from 'immutable';
import {mixed, number, object, string} from "yup";
import {InventoryItemHistoryType} from "./InventoryItemHistoryType";

export const InventoryItemHistoryEntryRecord = Record({
    id: 0,
    item: 0,
    itemName:"",
    type: "ENTRY",
    task: 0,
    taskName: ''
});

export const InventoryItemHistoryEntrySchema = object().shape({
    id: number().required().min(0).default(0),
    item: number().required().min(1).default(0),
    itemName: string().nullable().default(""),
    type: mixed().required().oneOf(InventoryItemHistoryType).default("ENTRY"),
    task: number().nullable().default(0),
    taskName: string().nullable().default("")
});