export default {
    "task": "Feladat",
    "tasks": "Feladatok",
    "add": "Feladat hozzáadaása",
    "edit": "Feladat szerkesztése",
    "remove": "Feladat törlése",
    "title": "Feladatok",
    "responsible": "Leltározó személy",
    "authorization": "Hitelesítő személy",
    "type": "Típus",
    "translType": "Típus",
    'ITEM_ENTRY': "Eszköz állományba vétel",
    'INVENTORY': 'Leltározás',
    'BLIND_INVENTORY': 'Vakleltár',
    "status": "Státusz",
    "translStatus": "Státusz",
    'PLANNING': "Tervezés alatt",
    'PREPARED': "Indításra vár",
    'EXECUTION': "Folyamatban",
    'PENDING': "Hítelesítésre vár",
    'APPROVED': "Hitelesítve",
    'CLOSED': "Lezárt",
    'SENT': "Visszaküldve",
    "name": "Megnevezés",
    "name-invalid": "Minimum 2 karaktert kell tartalmaznia.",
    "plannedStart": "Tervezett kezdés",
    "formattedPlannedStart": "Tervezett kezdés",
    "plannedStart-invalid": "Érvénytelen dátum formátum.",
    "actualStart": "Tényleges kezdés",
    "actualStart-invalid": "Érvénytelen dátum formátum.",
    "plannedEnd": "Tervezett befejezés",
    "plannedEnd-invalid": "Érvénytelen dátum formátum.",
    "actualEnd": "Tényleges befejezés",
    "actualEnd-invalid": "Érvénytelen dátum formátum.",
    "back": "Vissza a listához",
    "save": "Feladat mentése",
    "action": "Műveletek",
    "id": "Azonosító",
    "itemGroup": "Eszköz csoport",
    "itemGroup-invalid": "Az NSK rendszerben készített feladatoknál kötelező az eszköz csoportot kiválasztani.",
    "system": "Rendszer",
    "translSystem": "Rendszer",
    "erpId": "Forrás.net azonosító",
    "erpId-invalid": "Forrás.net rendszerből átvett feladatoknál kötelező a Forrás.net azonosító.",
    "inventory-tickets": "Leltár jegyek",
    "itemCount": "Eszközök száma",
    "listOfTicketsFound": "Talált eszközök",
    "foreignItems": "Talált eszközök",
    "foreign-items": "Talált eszközök",
    "list-of-tickets-found": "Talált eszközök",
    "NSK": "Nemzeti Sportközpontok (NSK)",
    "FORRAS_NET": "Forrás ügyviteli rendszer (FORRAS NET)",
    "facility-units": "A feladathoz tartozó létesítmény egységek",
    "taskAdmin": "Leltárfelelős személy",
    "forrasTicketsAreBeingLoaded": "A FORRAS NET rendszerű leltár feladathoz tartozó leltár jegyek induló adatainak átvétele folyamatban.....",
    "ticketsAreBeingMade": "Az NSK rendszerű leltár feladathoz tartozó leltár jegyek generálása folyamatban.....",
    "forrasNetStatus": "A FORRAS_NET feladat adatkapcsolatának állapota",
    "closeTheTask": "A feladat lezárása elkezdődött...",
    "returnToForras": "Sikeresen megkezdtük a feladat visszaküldését a FORRAS_NET-nek!",
    "alreadyUsingThisErpId": "A feladatt indítása sikertelen. Ezzel a Forrás.net azonosítóval már lett elindítva feladat.",
    "areYouSure": "BIZTOSAN FOLYTATNI SZERETNÉ?",
    "rejectionOfResult": "A leltár feladat minden eredmény adata törlődni fog, ha a feladatot visszaállítja 'indításra vár' állapotba.",
    "stop": "Abbahagyom",
    "continue": "Folytatom",
    "helperTextForGeneratePdf": 'Leltár eredményét részletező jegyzőkönyv letőltése.\nA PDF generálása eltarthat egy kicsit hosszabb ideig...',
    "successfulDownload": "A fájl sikeresen le lett töltve",
    "pdfFileIsBeingMade": "A PDF fájl generálása folyamatban.....",
    "report": "Jegyzőkönyv",

};