import {Record} from 'immutable';
import {number, object, string} from "yup";


export const CreationRuleRecord = Record({
    id: 0,
    name: '',
    description: '',
    allowPep: 0,
    allowPermission: '',
    assignPep: 0,
});

export const CreationRuleSchema = object().shape({
    id: number().required().min(0).default(0),
    name: string().required().min(1).max(60).default(''),
    description: string().required().min(1).max(10000).default(''),
    allowPep: number().required().min(1).default(0),
    allowPermission: string().required().min(1).max(60).default(''),
    assignPep: number().required().min(1).default(0),
});

