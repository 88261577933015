import baseFetch, {useFetchMany, useFetchOne} from "../Lib/baseFetch";
import {InventoryItemRecord, InventoryItemSchema} from "../Data/inventory-item";
import {ListOfItemsRecord} from "../Data/list-of-inventory-item";

export function useInventoryItems(filter) {
    return useFetchMany('/inventory-items', filter, InventoryItemRecord);
}

export function useInventoryItemsInArea(filter) {
    return useFetchMany('/items', filter, ListOfItemsRecord);
}

export function useInventoryItem(id) {
    return useFetchOne('/inventory-items', id, InventoryItemRecord, InventoryItemSchema);
}

export function addInventoryItem(inventoryItem, onSuccess, onError) {
    baseFetch('POST', '/inventory-items', inventoryItem,
        data => {
            if (onSuccess) onSuccess(new InventoryItemRecord(data))
        },
        onError
    );
}

export function updateInventoryItem(inventoryItem, onSuccess, onError) {
    baseFetch('PATCH', '/inventory-items/' + inventoryItem.get('id'), inventoryItem, onSuccess, onError);
}

export function removeInventoryItem(inventoryItem, onSuccess, onError) {
    baseFetch('DELETE', '/inventory-items/' + inventoryItem.get('id'), undefined, onSuccess, onError);
}

export function useFoundInventoryItems() {
    return useFetchMany('/found-items', undefined, ListOfItemsRecord);
}
