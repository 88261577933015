import baseFetch, {useFetchMany, useFetchOne} from "../../baseFetch";
import {SystemSettingRecord, SystemSettingSchema} from "../Data/SystemSetting";

export function useSystemSettings(filter) {
    return useFetchMany('/system-settings', filter, SystemSettingRecord);
}

export function useSystemSetting(id) {
    return useFetchOne('/system-settings', id, SystemSettingRecord, SystemSettingSchema);
}

export function useSystemSettingName(name) {
    return useFetchOne('/find-system-setting-by-name', name, SystemSettingRecord, SystemSettingSchema);
}

export function addSystemSetting(systemSetting, onSuccess, onError) {
    baseFetch('POST', '/system-settings', systemSetting,
        data => {
            if (onSuccess) onSuccess(new SystemSettingRecord(data))
        },
        onError
    );
}

export function updateSystemSetting(systemSetting, onSuccess, onError) {
    baseFetch('PATCH', '/system-settings/' + systemSetting.get('id'), systemSetting, onSuccess, onError);
}

export function removeSystemSetting(systemSetting, onSuccess, onError) {
    baseFetch('DELETE', '/system-settings/' + systemSetting.get('id'), undefined, onSuccess, onError);
}

