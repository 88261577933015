import React from "react";
import ReactQuill from "react-quill";
import {ImageDrop} from "quill-image-drop-module";
import ImageResize from 'quill-image-resize-module-react';
import 'react-quill/dist/quill.snow.css';
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import customInputStyle from "../../../material-dashboard/assets/jss/components/customInputStyle.jsx";
import {makeStyles} from "@material-ui/styles";
import {useFormField} from "../../useFormField";
import {ControlledEditor} from "@monaco-editor/react";
import {Box} from "@material-ui/core";

const useStyles = makeStyles(customInputStyle);

ReactQuill.Quill.register('modules/imageDrop', ImageDrop);
ReactQuill.Quill.register('modules/imageResize', ImageResize);

const quillModules = {
    toolbar: [
        [{'header': [1, 2, 3, 4, 5, false]}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'align': ['', 'center', 'right', 'justify']}],
        [{'script': 'sub'}, {'script': 'super'}],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image', 'video'],
        ['clean']
    ],
    imageDrop: true,
    imageResize: {
        modules: ['Resize', 'Toolbar']
    },
};

const quillFormats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote', 'script', 'align',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
];

function ValidatedRichTextEditor(props) {
    const {showLabel, codeEditor} = props;

    const classes = useStyles();

    const ff = useFormField(props, classes);

    const formControlClasses = classes.formControl;

    let style = showLabel ? {marginTop: "10px"} : {};

    // FIXME losing focus
    // to prevent losing focus - see https://github.com/zenoamaro/react-quill/issues/309
    // doesn't work ...  const value = ff.value ? ff.value : {ops : []};

    return (
        <FormControl className={formControlClasses} fullWidth style={style}>
            {showLabel ?
                <InputLabel
                    className={ff.labelClasses}
                    shrink={true}
                    htmlFor={ff.id}>
                    {ff.labelText}
                </InputLabel>
                : null}

            {ff.helperText ?
                <FormHelperText id={ff.id + "-text"} className={ff.helpTextClasses}>
                    {ff.helperText}
                </FormHelperText>
                : null}
            {codeEditor ?
                <Box style={{border: '1px solid #d2d2d2', paddingTop: '0.2rem'}}>
                    <ControlledEditor language="yaml"
                                      height="20rem"
                                      theme="light"
                                      value={ff.value}
                                      onChange={(e, val) => ff.onChange(val)}
                    />
                </Box>
                :
                <ReactQuill id={ff.id}
                            theme={'snow'}
                            value={ff.value}
                            onChange={ff.onChange}
                            modules={quillModules}
                            formats={quillFormats}
                            style={{paddingTop: '0.2rem'}}/>
            }
        </FormControl>
    );
}

export default ValidatedRichTextEditor;