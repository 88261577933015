import React, {memo, useContext, useState} from "react";
import ContentBox from "../../Components/ContentBox/ContentBox";
import {useFacilities} from "../../Api/facilities";
import {customActions} from "../../Lib/simplexion-table/templates/action-buttons";
import {FilterField} from "../../Components/Table/FilterField";
import {SimplexionTable} from "../../Lib/simplexion-table/SimplexionTable";
import {makeStyles} from "@material-ui/core";
import {makeTableStyles} from "../../Components/Table/tableStyles"
import {inputFieldStyles} from "../../Lib/simplexion-table/templates/text-input";
import {baseTableStyles} from "../../Lib/simplexion-table/baseStyle";
import {updatableCheckBoxStyle} from "../../Lib/simplexion-table/templates/checkbox-input";
import debug from "../../Lib/debug"
import LinearProgress from "@material-ui/core/LinearProgress";
import Section from "../../Lib/form/Components/Section/Section";
import {AuthContext} from "../../Lib/auth/AuthContext";
import {roles} from "../../Main/roles";


const useTableStyles = makeStyles(makeTableStyles(baseTableStyles, updatableCheckBoxStyle, inputFieldStyles));

const List = memo((props) => {

    debug("RENDER", "useForm", () => [" -> List_Of_Facilities", props]);

    const {path} = props;

    const authContext = useContext(AuthContext);

    const hasAdminRoles = authContext.getIn(['session', 'roles']).some(role => [roles.SECURITY_OFFICER.toLowerCase()].indexOf(role) !== -1);

    const {loadingStatus, data} = useFacilities();

    const urlNs = path[0].url + "/";

    const tableClasses = useTableStyles();

    const [state, setState] = useState('');

    const handleFilter = val => setState(val);

    const tableConfig = {
        id: 'facilities-table',
        nameSpace: 'facilities',
        stickyHeader: true,
        sortable: true,
        styles: tableClasses,
        columns: [
            {
                accessor: 'id',
            },
            {
                accessor: 'erpId',
            },
            {
                accessor: 'name',
            },
            {
                accessor: 'administrativeContactName',
            },
            {
                accessor: 'technicalContactName',
            },
            {
                accessor: 'state',
            },
            {
                accessor: 'city',
            },
            {
                accessor: 'action',
                renderer: (...args) => customActions(true, false, urlNs, ...args),
                sortable: true,
            },
        ],
    };

    return (
        <ContentBox path={path} add={hasAdminRoles ? (urlNs + "add") : null}>
            <Section>
                <FilterField handler={handleFilter}/>
                {loadingStatus > 0 ? <LinearProgress/> : null}
                <SimplexionTable tableConfig={tableConfig} data={data} loadingStatus={loadingStatus}
                                 filterValue={state}/>
            </Section>
        </ContentBox>
    );
});

export default List;