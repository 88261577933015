export const GrantHu = {
    "id": "Id",
    "grants": "Jogosultsággal rendelkezők",
    "grant": "Jogosultsággal rendelkező",
    "groupId": "Csoport",
    "principalId": "Felhasználó",
    "principalName": "Felhasználó neve",
    "fullName": "Teljes név",
    "emailAddress": "Email cím",
    "action": "Művelet",
    "back": "Vissza a listához",
    "save": "Jogosultság mentése",
    "add": "Jogosultság hozzáadása",
    "delete": "Jogosultság elvétele",
    "badRequest": "A kérés nem végrehajtható, mert már ehhez a felhasználóhoz létezik ez a jogosultság."
};

export const GrantEn = {
    "id": "Id",
    "groupId": "Group Id",
    "principalId": "Principal Id",
    "principalName": "Principal name",
    "fullName": "Full name",
    "emailAddress": "Email address",
};