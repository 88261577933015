import {Record} from 'immutable';
import {number, object, string} from "yup";

export const FacilityRecord = Record({
    id: 0,
    name: "",
    country: "Magyarország",
    state: "",
    city: "",
    address1: "",
    address2: "",
    type: 0,
    description: "",
    administrativeContact: 0,
    technicalContact: 0,
    administrativeContactName: "",
    technicalContactName: "",
    erpId: "",
    erpId2: ""
});

export const FacilitySchema = object().shape({
    id: number().required().min(0).default(0),
    name: string().required().min(2).max(60).default(''),
    country: string().required().min(2).max(60).default('Magyarország'),
    state: string().required().min(2).max(60).default(''),
    city: string().required().min(2).max(60).default(''),
    address1: string().min(2).max(120).required().default(''),
    address2: string().nullable().max(120).default(''),
    type: number().required().min(1).default(0),
    description: string().nullable().max(10000).default(''),
    administrativeContact:  number().required().min(1).default(0),
    technicalContact: number().required().min(1).default(0),
    administrativeContactName: string().default(""),
    technicalContactName: string().default(""),
    erpId: string().max(30).nullable().default(""),
    erpId2: string().max(30).nullable().default("")
});