import React, {memo, useState} from "react";
import ContentBox from "../../Components/ContentBox/ContentBox";
import {SimplexionTable} from "../../Lib/simplexion-table/SimplexionTable";
import {customActions} from "../../Lib/simplexion-table/templates/action-buttons";
import {makeStyles} from "@material-ui/core";
import {FilterField} from "../../Components/Table/FilterField"
import {makeTableStyles} from "../../Components/Table/tableStyles"
import debug from "../../Lib/debug"
import {baseTableStyles} from "../../Lib/simplexion-table/baseStyle";
import {updatableCheckBoxStyle} from "../../Lib/simplexion-table/templates/checkbox-input";
import {inputFieldStyles} from "../../Lib/simplexion-table/templates/text-input";
import LinearProgress from "@material-ui/core/LinearProgress";
import {useTransfers} from "../../Api/transfers";
import Section from "../../Lib/form/Components/Section/Section";


const useTableStyles = makeStyles(makeTableStyles(baseTableStyles, updatableCheckBoxStyle, inputFieldStyles));

const List = memo((props) => {

    debug("RENDER", "useForm", () => [" -> List_Of_Transfers", props]);

    const {path} = props;

    const tableClasses = useTableStyles();

    const [state, setState] = useState('');

    const urlNs = path[0].url + '/';

    const {loadingStatus, data} = useTransfers();

    const handleFilter = val => setState(val);

    const tableConfig = {
        id: 'transfers-table',
        nameSpace: 'transfers',
        stickyHeader: true,
        sortable: true,
        styles: tableClasses,
        columns: [
            {accessor: 'id'},
            {accessor: 'createdAt'},
            {accessor: 'transferorPrincipalName'},
            {accessor: 'recipientPrincipalName'},
            {accessor: 'transferringFacilityName'},
            {accessor: 'receivingFacilityName'},
            {
                accessor: 'action',
                renderer: (...args) => customActions(true, false, urlNs, ...args),
                sortable: true,
            },
        ],
    };

    return (
        <ContentBox path={path} add={urlNs + "add"}>
            <Section>
                <FilterField handler={handleFilter}/>
                {loadingStatus > 0 ? <LinearProgress/> : null}
                <SimplexionTable tableConfig={tableConfig} data={data} loadingStatus={loadingStatus}
                                 filterValue={state}/>
            </Section>
        </ContentBox>
    );
});

export default List;
