import React from "react";
import List from "./List";
import {useRoutes} from "hookrouter";
import Form from "./Form";
import Crud from "../../../Lib/Crud";
import {addItemOwner, removeItemOwner, updateItemOwner, useItemOwner} from "../../../Api/item-owners";
import {ItemOwnerRecord, ItemOwnerSchema} from "../../../Data/item-owner";

export default () => useRoutes(subRoutes);

const subRoutes = {
  "/": () => <Crud config={crudConfig} view="list" />,
  "/add": () => <Crud config={crudConfig} view="add" />,
  "/:id/edit": ({id}) => <Crud config={crudConfig} view="edit" id={id} />,
  "/:id/remove": ({id}) => <Crud config={crudConfig} view="remove" id={id} />
};

const crudConfig = {
  compName: 'itemOwners',
  namespace: 'itemOwners',
  url: '/mdm/item-owners',
  labelField: 'name',
  Record: ItemOwnerRecord,
  Schema: ItemOwnerSchema,
  ListComponent: List,
  FormComponent: Form,
  api: {
    use: useItemOwner,
    add: addItemOwner,
    edit: updateItemOwner,
    remove: removeItemOwner
  }
};
