import React, {memo, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../../Lib/form/useForm";
import {FacilityUnitRecord, FacilityUnitSchema} from "../../../Data/facility-unit";
import ContentBox from "../../../Components/ContentBox/ContentBox";
import Section from "../../../Lib/form/Components/Section/Section";
import ValidatedInput from "../../../Lib/form/Components/ValidatedInput/ValidatedInput";
import ValidatedSubmit from "../../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import debug from "../../../Lib/debug";
import ValidatedSelect from "../../../Lib/form/Components/ValidatedSelect/ValidatedSelect";
import {useRfidTags} from "../../../Api/rfid-tags";
import Grid from "@material-ui/core/Grid";
import {GeneralStatus} from "../../../Data/GeneralStatus";
import ValidatedRadio from "../../../Lib/form/Components/ValidatedRadio/ValidatedRadio";
import ValidatedRadioGroup from "../../../Lib/form/Components/ValidatedRadio/ValidatedRadioGroup";
import ValidatedFileUpload from "../../../Lib/form/Components/ValidatedFileUpload/ValidatedFileUpload";
import {useFacilityUnits} from "../../../Api/facility-units";
import InputLabel from "@material-ui/core/InputLabel";
import {ImageLoader} from "../../../Components/ImageLoader";
import {Navigation} from "./Navigation";
import {successColor} from "../../../Lib/material-dashboard/assets/jss/material-dashboard-pro-react";


export default memo(({id, record, path, view, api, hasAdminRoles}) => {
    const [hasAPicture, setHasAPicture] = useState(record.picture !== 0);
    const {t} = useTranslation("facilityUnits");

    const form = useForm({
        recordType: FacilityUnitRecord,
        recordSchema: FacilityUnitSchema,
        namespace: 'facilityUnits',
        record: record

    });

    debug("RENDER", "useForm", () => [" -> Form_Of_Facility_Units", view, id, path, record]);

    useEffect(() => {
        setHasAPicture(record.picture !== 0)
    }, [record.picture]);

    const nameOfParent = record.name ? (record.name.replace(/%20/g, "").length > 1 ?
        record.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\//g, "") : t("**unknown-name**")) : t("**unknown-name**");

    return (
        <ContentBox path={path} form={form}>

            <Section title={t('facilityUnit')}>

                {view === "add" ? null : <Navigation parentId={id} path={path} parentName={nameOfParent}/>}

                <ValidatedInput name="name" disabled={!hasAdminRoles}/>
                <ValidatedInput name="erpId" disabled={true}/>
                <ValidatedInput name="designation" disabled={!hasAdminRoles}/>
                <ValidatedSelect name="parentUnit" api={useFacilityUnits} filterOfApi={{facility: record.facility}}
                                 nullable={true} disabled={!hasAdminRoles}/>
                <ValidatedSelect name="tag" api={useRfidTags} recordFieldName={"serial"} nullable={true}
                                 disabled={!hasAdminRoles}/>
                <ValidatedRadioGroup name="status">
                    <Grid container>
                        {GeneralStatus.map(a => <ValidatedRadio name="status" value={a} key={a}/>)}
                    </Grid>
                </ValidatedRadioGroup>
                <ValidatedInput name="description" multiline={true} rows={3} disabled={!hasAdminRoles}/>
                <ValidatedInput name="barCode" disabled={!hasAdminRoles}/>
                <InputLabel style={{padding: "0.3rem", color: successColor[0], fontWeight: "500", fontSize: "1rem"}}
                            shrink={true}>{t("picture")}</InputLabel>
                {(record.picture && hasAPicture) ?
                    <ImageLoader onClick={() => setHasAPicture(hasAPicture => !hasAPicture)} height={'20'}
                                 image={record.picture}/> :
                    <ValidatedFileUpload name="picture" apiPath="/images" sizeValidation={true} maxFileSize={'1MB'}
                                         acceptedFileTypes={['image/*']}/>}

            </Section>

            <ValidatedSubmit view={view} api={api} hideSave={!hasAdminRoles}/>

        </ContentBox>
    );
})