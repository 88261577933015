import React from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../Lib/form/useForm";
import ValidatedInput from "../../Lib/form/Components/ValidatedInput/ValidatedInput";
import Card from "../../Lib/material-dashboard/components/Card/Card";
import CardBody from "../../Lib/material-dashboard/components/Card/CardBody";
import {useSnackbar} from "notistack";
import Grid from "@material-ui/core/Grid";

import accountStyle from "./accountStyle";
import {makeStyles} from "@material-ui/styles";
import FormProvider from "../../Lib/form/Components/FormProvider/FormProvider";
import {ResetPasswordRecord, ResetPasswordSchema} from "../../Data/account";
import {resetPassword} from "../../Api/accounts";
import {closeSnack} from "../../Main/util";
import {navigate} from "hookrouter";
import Button from "../../Lib/material-dashboard/components/CustomButtons/Button";
import ValidatedSubmit from "../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";

const useStyles = makeStyles(accountStyle);

/**
 * Checks if the password verification is the same as password.
 */
export const passwordValidator = (fieldName, fieldValue, fieldValues) => {
    return fieldValues["newPassword"] === fieldValue;
};

const ResetPassword = ({id, resetKey}) => {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const api = (record, onSuccess, onError) => {
        record = record.set('key', resetKey);
        resetPassword(id, record, onSuccess, onError)
    };

    const cbSuccess = () => {
        navigate("/login")
        enqueueSnackbar(
            t('password-change-success-explanation'),
            {
                style: {whiteSpace: 'pre-line'},
                variant: 'success',
                persist: true,
                action: (key) => closeSnack(t, closeSnackbar, key)
            });
    };

    const cbError = () => {
        enqueueSnackbar(
            t('password-change-error'),
            {variant: 'warning', persist: true, action: (key) => closeSnack(t, closeSnackbar, key)});
    };

    const {t} = useTranslation("accounts");

    // noinspection JSPotentiallyInvalidConstructorUsage
    const form = useForm({
        recordType: ResetPasswordRecord,
        recordSchema: ResetPasswordSchema,
        namespace: 'accounts',
        record: new ResetPasswordRecord()
    });

    const classes = useStyles();

    return (
        <div className={classes.panel}>
            <Card className={classes.container}>
                <CardBody>
                    <Grid container justify="center">

                        <Grid item xs={12}>
                            <div className={classes.title}>{t('reset-password-title')}</div>
                            <p>{t('reset-password-explanation')}</p>
                        </Grid>

                        <Grid item xs={12}>
                            <FormProvider form={form} formClassName={classes.form}>
                                <ValidatedInput name="newPassword" password/>
                                <ValidatedInput name="verification" password validator={passwordValidator}/>
                                <Grid item container justify="space-between" style={{marginTop: "1.5rem"}}>
                                    <Grid item>
                                        <Button
                                            color={"primary"}
                                            style={{backgroundColor: "#0c427d"}}
                                            onClick={() => navigate("/dashboard")}
                                        >
                                            {t('general:back')}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <ValidatedSubmit
                                            hideCancel
                                            api={api}
                                            submitLabel={t('reset-password-button')}
                                            onSuccess={cbSuccess}
                                            onError={cbError}
                                        />
                                    </Grid>
                                </Grid>
                            </FormProvider>
                        </Grid>

                    </Grid>
                </CardBody>
            </Card>
        </div>
    );
};

export default ResetPassword;