  export default {
  "taskFacilityUnits": "Létesítmény egységek",
  "taskFacilityUnit": "Létesítmény egység",
  "add": "Létesítmény egység hozzáadása",
  "edit": "Létesítmény egység szerkesztése",
  "remove": "Létesítmény egység törlése",
  "task": "Feladat",
  "taskName": "Feladat",
  "facilityUnit": "Létesítmény egység",
  "facilityUnitName": "Létesítmény egység",
  "back": "Vissza a listához",
  "save": "Létesítmény egység mentése",
  "action": "Műveletek",
  "id": "Azonosító",
  "finished": "Be van fejezve",
  "approved": "Hitelesítve van",
  "facilityUnitErpId": "Forrás.net azonosító"

};