import {getAncestorRow, getRecord} from "./util";


export const updatableCheckBox = (accessor, record, tableStorage) => {
    const {styles} = tableStorage.tableConfig;

    const val = record[accessor];
    const id = record.id;

    const inputField = `<input type="checkbox" ${val ? 'checked' : ''} value="${val}" class="${styles.updatableCheckBox}" data-record="${id}" data-accessor="${accessor}">`;

    return `${inputField}`;
};

export const updatableCheckBoxStyle = {
    updatableCheckBox: {}
};

export const handleCheckBoxUpdate = (updateApi, inputEl, tableStorage) => {

    const recordId = parseInt(inputEl.getAttribute('data-record'));
    const accessor = inputEl.getAttribute('data-accessor');
    const {data, rows} = tableStorage;
    const {index, record} = getRecord(recordId, data);
    const newRecord = record.set(accessor, inputEl.checked);

    updateApi(newRecord,
        () => {
            data[index] = newRecord;
            rows[recordId] = getAncestorRow(inputEl);
            simulateClick(inputEl);
        },
        () => {
            // TODO
        }
    );

};

const simulateClick = (inputEl) => {
    const onClick = new MouseEvent('click', {bubbles : false});
    inputEl.dispatchEvent(onClick);
};