export default {
    "projectName": "Nemzeti Sportközpontok (NSK)",
    "projectSummary": "A rendszer célja, hogy a Nemzeti Sportközpontok (NSK) számára olyan leltár feladatok elvégzését támogassa, melyekben a leltározandó eszközök egyedi azonosítása RFID tagek segítségével történik.",
    "version": "A webalkalmazás aktuális verzió számai:",
    "simplexionName": "Simplexion Informatikai Kft.",
    "simplexionAddress": "1096 Budapest, Vendel u. 11.",
    "noVersion": "Nincs megadott verzió szám.",
    "name": "Cég teljes neve",
    "address": "Elérhetőségi cím",
    "notFound": "Nem található alkalmazás!",
    "successfulDownload": "A mobil alkalmazást letöltése sikeresen meg lett kezdve!"
};