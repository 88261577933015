import React from "react";
import List from "./List";
import {useRoutes} from "hookrouter";
import Form from "./Form";
import Crud from "../../../Lib/Crud";
import {addItemUser, removeItemUser, updateItemUser, useItemUser} from "../../../Api/item-users";
import {ItemUserRecord, ItemUserSchema} from "../../../Data/item-user";

export default () => useRoutes(subRoutes);

const subRoutes = {
  "/": () => <Crud config={crudConfig} view="list" />,
  "/add": () => <Crud config={crudConfig} view="add" />,
  "/:id/edit": ({id}) => <Crud config={crudConfig} view="edit" id={id} />,
  "/:id/remove": ({id}) => <Crud config={crudConfig} view="remove" id={id} />
};

const crudConfig = {
  compName: 'itemUsers',
  namespace: 'itemUsers',
  url: '/mdm/item-users',
  labelField: 'name',
  Record: ItemUserRecord,
  Schema: ItemUserSchema,
  ListComponent: List,
  FormComponent: Form,
  api: {
    use: useItemUser,
    add: addItemUser,
    edit: updateItemUser,
    remove: removeItemUser
  }
};
