import baseFetch, {useFetchMany, useFetchOne} from "../Lib/baseFetch";
import {InventoryAreaRecord, InventoryAreaSchema} from "../Data/inventory-area";

export function useInventoryAreas(filter) {
    return useFetchMany('/inventory-areas', undefined, InventoryAreaRecord);
}

export function useInventoryArea(id) {
    return useFetchOne('/inventory-areas', id, InventoryAreaRecord, InventoryAreaSchema);
}


export function addInventoryArea(inventoryArea, onSuccess, onError) {
    baseFetch('POST', '/inventory-areas', inventoryArea,
        data => {
            if (onSuccess) onSuccess(new InventoryAreaRecord(data))
        },
        onError
    );
}

export function updateInventoryArea(inventoryArea, onSuccess, onError) {
    baseFetch('PATCH', '/inventory-areas/' + inventoryArea.get('id'), inventoryArea, onSuccess, onError);
}

export function removeInventoryArea(inventoryArea, onSuccess, onError) {
    baseFetch('DELETE', '/inventory-areas/' + inventoryArea.get('id'), undefined, onSuccess, onError);
}
