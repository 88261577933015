import React from "react";
import CustomInput from "../../../material-dashboard/components/CustomInput/CustomInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import {useFormField} from "../../useFormField";

function ValidatedInput(props) {
  const {name, adornment, multiline, rows, password, compose, decompose, autoComplete, handleEnter} = props;

  const ff = useFormField(props);

  return <CustomInput

      id={ff.id}

      labelText={ff.labelText}
      helperText={ff.helperText}

      success={ff.status === "valid"}
      error={ff.status === "invalid"}

      multiline={multiline}

      disabled={ff.disabled}

      formControlProps={{
        fullWidth: true,
      }}

      inputProps={{
        name: name,
        disabled: ff.disabled,
        value: compose ? compose(ff.value) : ff.value,
        type: password ? 'password' : 'text',
        autoComplete: autoComplete,
        multiline: multiline,
        rows: rows,
        startAdornment: adornment ? <InputAdornment position="start">{adornment}</InputAdornment> : undefined,
        onChange: (e) => ff.onChange(decompose ? decompose(e.target.value) : e.target.value),
        onKeyPress: (e) => { if (handleEnter && e.key === "Enter") handleEnter() }
      }}
  />
}

export default ValidatedInput;