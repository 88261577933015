import React, {memo} from "react";
import useForm from "../../../../Lib/form/useForm";
import ContentBox from "../../../../Components/ContentBox/ContentBox";
import Section from "../../../../Lib/form/Components/Section/Section";
import ValidatedSubmit from "../../../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import debug from "../../../../Lib/debug";
import {useFacilityUnits} from "../../../../Api/facility-units";
import {useItemUsers} from "../../../../Api/item-users";
import Search from "../../Search";
import {AreaEntryRecord, AreaEntrySchema} from "../../../../Data/area-entry";
import {useSearchForItemsInTheArea} from "../../../../Api/inventory-areas-entries";
import {useInventoryItems} from "../../../../Api/inventory-items";

const optionData = [
    {name: 'inventoryItems', fetchFunction: useInventoryItems, inputType: 'multi-select'},
    {name: 'facilityUnits', fetchFunction: useFacilityUnits, inputType: 'multi-select'},
    {name: 'itemUsers', fetchFunction: useItemUsers, inputType: 'multi-select'},
];

export default memo(({id, record, path, view, api}) => {
    record.merge({"inventoryArea": parseInt(id, 10)});

    const form = useForm({
        recordType: AreaEntryRecord,
        recordSchema: AreaEntrySchema,
        namespace: 'inventoryAreaEntries',
        record
    });

    debug("RENDER", "useForm", () => [" -> Form_Of_Inventory_Area_Entry", view, id, path, record]);

    return (
        <ContentBox path={path} form={form} disabled>
            <Section>
                <Search optionData={optionData} area={record.inventoryArea} form={form}
                        api={useSearchForItemsInTheArea}/>
            </Section>
            <ValidatedSubmit view={view} api={api} hideSave={true}/>

        </ContentBox>
    );
})