import React from "react";
import List from "./List";
import {useRoutes} from "hookrouter";
import {
    addInventoryItem,
    removeInventoryItem,
    updateInventoryItem,
    useInventoryItem
} from "../../../Api/inventory-items";
import Crud from "../../../Lib/Crud";
import Form from "./Form";
import {InventoryItemRecord, InventoryItemSchema} from "../../../Data/inventory-item";
import InventoryItemHistoryEntries from "./InventoryItemHistoryEntires/InventoryItemHistoryEntries";

export default (props) => useRoutes(subRoutes)(props);

const params = new URLSearchParams(window.location.search);
let area = {inventoryArea: params.get('inventory-area') ? parseInt(params.get('inventory-area'), 10) : 0}

const subRoutes = {
    "/": () => (props) => <Crud config={crudConfig(props)} view="list" {...props}/>,
    "/add": () => (props) => <Crud config={crudConfig(props)} view="add" {...props}/>,
    "/:id/edit": ({id}) => (props) => <Crud config={crudConfig(props)} view="edit" id={id} {...props}/>,
    "/:id/remove": ({id}) => (props) => <Crud config={crudConfig(props)} view="remove" id={id} {...props}/>,
    "/:id/histories*": ({id}) => (props) => <Crud config={crudConfig(props)} OptComponent={InventoryItemHistoryEntries}
                                                  optUrlSectionNLabel='histories' view="other" id={id}  {...props}/>,
    "/:id/list*": ({id}) => (props) => <Crud config={crudConfig(props)}
                                             OptComponent={(props) => useRoutes(subRoutes)(props)}
                                             optUrlSectionNLabel='list' view="other" id={id}  {...props}/>,

};


const crudConfig = (props) => ({

    compName: 'inventory-items',
    namespace: 'inventoryItems',
    url: '/items/treatment',
    labelField: 'name',
    Record: InventoryItemRecord,
    Schema: InventoryItemSchema,
    ListComponent: List,
    FormComponent: Form,
    api: {
        use: useInventoryItem,
        add: addInventoryItem,
        edit: updateInventoryItem,
        remove: removeInventoryItem
    },
    setOptRecField: "inventoryArea",
    optValueOfOptRecField: area.inventoryArea       // If there is a setOptRecField, I need to specify the value of the parentId or
                                                                        // the value of the optional optValueOfOptRecField
});

