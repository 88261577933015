import React, {useContext} from "react";
import CardBody from "../../material-dashboard/components/Card/CardBody";
import Card from "../../material-dashboard/components/Card/Card";
import GridContainer from "../../material-dashboard/components/Grid/GridContainer";
import {SearchContext} from "../Context/SearchContext";
import FilterSelect from "./OptionSelect";
import FilterText from "./OptionText";
import searchBuilderStyle from "./styles/searchBuilderStyle";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(searchBuilderStyle);

function OptionContainer(props) {
    const classes = useStyles();
    const {initDataFromUrl} = props;
    const {store} = useContext(SearchContext);

    const optionStore = store.get('options');
    let options = [];

    optionStore.forEach(o => {

        let optionComponent;
        const name = o.get('name');

        if (o.get('inputType') === 'multi-select') {

            optionComponent = (
                <FilterSelect
                    fetchFunction={o.get('fetchFunction')}
                    name={name}
                    key={name}
                    option={o}
                    urlData={initDataFromUrl[name]}
                />);

        } else if (o.get('inputType') === 'text') {

            optionComponent = <FilterText key={name} name={name} urlData={initDataFromUrl[name]}/>;

        }

        options.push(optionComponent);

    });

    return (
        <Card className={classes.sbContainer}>
            <CardBody>
                <GridContainer container direction="row" justify="space-around" alignItems="center">
                    {options.map(o => o)}
                </GridContainer>
            </CardBody>
        </Card>
    );
}

export default OptionContainer;