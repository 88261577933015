import React from "react";
import {useTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Button from "../../Lib/material-dashboard/components/CustomButtons/Button";
import {A} from 'hookrouter';
import {makeStyles} from "@material-ui/styles";
import {infoColor} from "../../Lib/material-dashboard/assets/jss/material-dashboard-pro-react";


const useStyles = makeStyles({
    button: {
        backgroundColor: "#396593"
    },
    active: {
        backgroundColor: infoColor[0]
    }
});

export function Navigation(props) {

    const {parentId, path} = props;
    const classes = useStyles();


    const {t} = useTranslation();

    return (
        <Grid container direction="column">
            <Grid item xs={12}>
                <A href={path[0].url + "/" + parentId + "/edit"}>
                    <Button className={path[1].url.includes("/edit") ? classes.active : classes.button}
                            size={"sm"}>{t('BaseData')}</Button>
                </A>
                <A href={path[0].url + "/" + parentId + "/facility-units"}>
                    <Button className={classes.button} size={"sm"}>{t('facilityUnits:facilityUnits')}</Button>
                </A>
            </Grid>
        </Grid>
    );
}