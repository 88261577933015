export default {
  "facilityAccess": "Jogosultság a létesítmények",
  "facilitiesAccesses": "Elérhető létesítmények",
  "principal" : "Felhasználó",
  "principalName" : "Felhasználó",
  "listOfTheFacilities" : "Létesítmények listája",
  "facilities" : "Létesítmények",
  "facilityName" : "Létesítmény",
  "back": "Vissza",
  "save": "Létesítmény hozzáférés mentése",
};