import baseFetch, {useFetchMany, useFetchOne} from "../Lib/baseFetch";
import {FacilityRecord, FacilitySchema} from "../Data/facility";


export function useFacilities(filter) {
  return useFetchMany('/facilities', undefined, FacilityRecord);
}

export function useFacility(facilityId) {
  return useFetchOne('/facilities', facilityId, FacilityRecord, FacilitySchema);
}

export function addFacility(facility, onSuccess, onError) {
    baseFetch('POST', '/facilities', facility,
        data => {
            if (onSuccess) onSuccess(new FacilityRecord(data))
        },
        onError
    );
}

export function updateFacility(facility, onSuccess, onError) {
    baseFetch('PATCH', '/facilities/' + facility.get('id'), facility, onSuccess, onError);
}

export function removeFacility(facility, onSuccess, onError) {
    baseFetch('DELETE', '/facilities/' + facility.get('id'), undefined, onSuccess, onError);
}
