import baseFetch, {useFetchMany, useFetchOne} from "../Lib/baseFetch";
import {InventoryItemGroupRecord, InventoryItemGroupSchema} from "../Data/inventory-item-group";

export function useInventoryItemGroups() {
    return useFetchMany('/inventory-groups', undefined, InventoryItemGroupRecord);
}

export function useInventoryItemGroup(id) {
    return useFetchOne('/inventory-groups', id, InventoryItemGroupRecord, InventoryItemGroupSchema);
}

export function addInventoryItemGroup(inventoryItemGroup, onSuccess, onError) {
    baseFetch('POST', '/inventory-groups', inventoryItemGroup,
        data => {
            if (onSuccess) onSuccess(new InventoryItemGroupRecord(data))
        },
        onError
    );
}

export function updateInventoryItemGroup(inventoryItemGroup, onSuccess, onError) {
    baseFetch('PATCH', '/inventory-groups/' + inventoryItemGroup.get('id'), inventoryItemGroup, onSuccess, onError);
}

export function removeInventoryItemGroup(inventoryItemGroup, onSuccess, onError) {
    baseFetch('DELETE', '/inventory-groups/' + inventoryItemGroup.get('id'), undefined, onSuccess, onError);
}
