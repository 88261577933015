export const ProcedureEn = {
    "procedure": "Procedure",
    "procedures": "Procedures",
    "ACP": "Access Configuration Procedure / ACP",
    "PEP": "Permission Evaluation Procedure / PEP",
    "action": "Action",
    "back": "Back to list",
    "save": "Save procedure",
    "add": "Add procedure",
    "Name": 'Név',
    'type': 'Type'
};

export const ProcedureHu = {
    "procedure": "Eljárás",
    "procedures": "Eljárások",
    "procedure-invalid": "Érvénytelen mező, legalább 1, legfeljebb 10000 karakternek kell lennie.",
    "ACP": "Hozzáférés a konfigurációhoz / ACP",
    "PEP": "Engedélyezési eljárás / PEP",
    "action": "Művelet",
    "back": "Vissza a listához",
    "save": "Eljárás mentése",
    "add": "Eljárás hozzáadása",
    "name": "Név",
    "name-invalid": "Érvénytelen mező, legalább 1, legfeljebb 60 karakternek kell lennie.",
    "type": "Tipus"
};