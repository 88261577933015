import React, {memo} from "react";
import useForm from "../../../../Lib/form/useForm";
import ContentBox from "../../../../Components/ContentBox/ContentBox";
import ValidatedSubmit from "../../../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import debug from "../../../../Lib/debug";
import {useInventoryItems} from "../../../../Api/inventory-items";
import Section from "../../../../Lib/form/Components/Section/Section";
import {useInventoryAreas} from "../../../../Api/inventory-areas";
import {useFacilityUnits} from "../../../../Api/facility-units";
import {useItemUsers} from "../../../../Api/item-users";
import Search from "../../Search";
import {GroupEntryRecord, GroupEntrySchema} from "../../../../Data/group-entry";
import {useSearchForItemsInTheGroup} from "../../../../Api/item-groups-entries";
import {useTranslation} from "react-i18next";

const optionData = [
    {name: 'inventoryAreas', fetchFunction: useInventoryAreas, inputType: 'multi-select'},
    {name: 'inventoryItems', fetchFunction: useInventoryItems, inputType: 'multi-select'},
    {name: 'facilityUnits', fetchFunction: useFacilityUnits, inputType: 'multi-select'},
    {name: 'itemUsers', fetchFunction: useItemUsers, inputType: 'multi-select'},
];
export default memo(({id, record, view, api}) => {
    record.merge({"group": parseInt(id, 10)});
    const {t} = useTranslation()

    const form = useForm({
        recordType: GroupEntryRecord,
        recordSchema: GroupEntrySchema,
        namespace: 'inventoryItemGroupEntries',
        record
    });

    debug("RENDER", "useForm", () => [" -> Form_Of_Inventory_Item_Group_Entry", view, id, path, record]);

    let path = [{
        label: t("inventoryItemGroups:inventoryItemGroups"),
        url: "/inventory/groups"
    }, {label: record.groupName, url: "/inventory/groups/" + record.group + "/edit"}]

    return (
        <ContentBox path={path} form={form}>
            <Section>
                <Search optionData={optionData} group={record.group} form={form}
                        api={useSearchForItemsInTheGroup}/>
            </Section>
            <ValidatedSubmit view={view} api={api}/>

        </ContentBox>
    );
})
