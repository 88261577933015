import i18next from "i18next";
import {getAncestorRow, getRecord} from "./util";

export const updatableInputField = (type, accessor, record, tableStorage) => {

    const {styles} = tableStorage.tableConfig;

    const label = i18next.getResource('en', 'general', 'edit');
    const val = record[accessor];
    const id = record.id;

    const inputField = `<input type="${type}" value="${val}" class="${styles.inputField}" data-record="${id}" data-accessor="${accessor}">`;

    const cancelBtn = `<button type="button" class="${styles.cancelBtn}">X</button>`;
    const submitBtn = `<button type="button" class="${styles.submitBtn}" disabled="true">OK</button>`;

    return `<div class="${styles.dataBox}">${inputField}<span class="${styles.btnBox}">${cancelBtn}${submitBtn}</span></div>`;
};

export const inputFieldStyles = {
    dataBox: {
        width: '15rem',
        height: 'inherit',
        // display: 'flex',
        // justifyContent: 'space-between',
        padding: '.25rem 0',
    },
    btnBox: {
        display: 'none',
        width: '4rem',
    },
    cancelBtn: {
        height: '1.75rem',
        width: '1.75rem',
        padding: 0,
        borderRadius: 4,
        color: '#fff',
        border: 'unset',
        backgroundColor: '#f44336 ',
        marginLeft: '.25rem',
        cursor: 'pointer',
    },
    submitBtn: {
        height: '1.75rem',
        width: '1.75rem',
        padding: 0,
        borderRadius: 4,
        color: '#fff',
        border: 'unset',
        backgroundColor: '#4caf50',
        marginLeft: '.25rem',
        cursor: 'pointer',
        '&:disabled': {
            backgroundColor: ' #bce1d0 ',
            cursor: 'initial',
        }
    },
    inputField: {
        display: 'inline-block',
        backgroundColor: 'transparent',
        height: '1.75rem',
        width: '11rem',
        paddingTop: '.1875rem',
        // marginRight: '-3.75rem',
        border: 'unset',
        overflow: 'hidden',
        color: 'inherit',
        textOverflow: 'ellipsis',
        borderBottom: '1px solid black',
        '&:focus': {
            outline: 'unset',
            outlineOffset: 'unset',
        }
    },
};

export const handleTextInputUpdate = (api, schema, inputEl, tableStorage) => {

    const originalVal = inputEl.value;

    showButtons(inputEl, api, tableStorage, originalVal);

    inputEl.onblur = e => handleBlur(e, originalVal);
    inputEl.oninput = () => validateField(inputEl, schema);
    inputEl.onkeydown = (e) => {
        if (e.key === 'Enter') {
            handleSubmit(inputEl, api, tableStorage);
        } else if (e.key === 'Escape') {
            handleCancel(inputEl, originalVal);
        }
    }

};

const validateField = (inputEl, recordSchema) => {
    const accessor = inputEl.getAttribute('data-accessor');
    const submitBtn = inputEl.parentElement.querySelectorAll('button').item(1);
    let status = 'valid';
    try {
        recordSchema.validateSyncAt(accessor, {[accessor]: inputEl.value});
    } catch (validationErrors) {
        status = 'invalid';
    }

    if (status === 'valid') {
        inputEl.parentElement.setAttribute('style', 'border-bottom-color: #4caf50; color: #4caf50;');
        submitBtn.removeAttribute('disabled');
    } else if (status === 'invalid') {
        inputEl.parentElement.setAttribute('style', 'border-bottom-color: #f44336; color: #f44336;');
        submitBtn.setAttribute('disabled', 'true');
    }
};

const showButtons = (inputEl, api, tableStorage, originalVal) => {
    const parent = inputEl.parentElement;
    const el = parent.querySelector('span');

    el.setAttribute('style', 'display: inline-block;');

    for (let btn of parent.getElementsByTagName('button')) {

        const innerText = btn.innerText;
        btn.onclick = innerText === 'OK' ?
            () => handleSubmit(inputEl, api, tableStorage) :
            () => handleCancel(inputEl, originalVal);

    }
};

const hideButtons = (inputEl) => {
    const parent = inputEl.parentElement;
    const el = parent.querySelector('span');

    el.setAttribute('style', 'display: none');
    for (let btn of parent.getElementsByTagName('button')) {
        btn.onclick = null;
        if (btn.innerText === 'OK') btn.setAttribute('disabled', 'true');
    }
};

const handleBlur = (event, originalVal) => {
    const relatedTarget = event.relatedTarget;
    const tag = relatedTarget ? relatedTarget.tagName : undefined;
    const text = relatedTarget ? relatedTarget.innerText : undefined;

    if (!(tag === 'BUTTON' && (text === 'OK' || text === 'X'))) {
        handleCancel(event.target, originalVal)
    }
};

const handleSubmit = (inputEl, api, tableStorage) => {
    const recordId = parseInt(inputEl.getAttribute('data-record'));
    const accessor = inputEl.getAttribute('data-accessor');
    const {data, rows} = tableStorage;
    const {index, record} = getRecord(recordId, data);
    const newRecord = record.set(accessor, inputEl.value);

    api(newRecord,
        () => {
            handleCancel(inputEl);
            data[index] = newRecord;
            rows[recordId] = getAncestorRow(inputEl);
            inputEl.blur();
        },
        () => {
            // TODO
            // inputEl.parentElement.setAttribute('style', 'border-bottom-color: #4caf50; border-bottom-width: 2px; color: #4caf50;');
        }
    );
};

const handleCancel = (inputEl, val) => {
    if (val) inputEl.value = val;
    inputEl.onblur = null;
    inputEl.oninput = null;
    inputEl.onkeydown = null;
    inputEl.parentElement.removeAttribute('style');
    hideButtons(inputEl)
};


