import React, {memo, useState} from "react";
import ContentBox from "../../../../Components/ContentBox/ContentBox";
import debug from "../../../../Lib/debug";
import {FilterField} from "../../../../Components/Table/FilterField";
import {SimplexionTable} from "../../../../Lib/simplexion-table/SimplexionTable";
import {makeStyles} from "@material-ui/core";
import {makeTableStyles} from "../../../../Components/Table/tableStyles";
import {baseTableStyles} from "../../../../Lib/simplexion-table/baseStyle";
import {updatableCheckBoxStyle} from "../../../../Lib/simplexion-table/templates/checkbox-input";
import {inputFieldStyles} from "../../../../Lib/simplexion-table/templates/text-input";
import {useRfidTagHistoryEntries} from "../../../../Api/rfid-tags";
import LinearProgress from "@material-ui/core/LinearProgress";
import Section from "../../../../Lib/form/Components/Section/Section";
import {customActions} from "../../../../Lib/simplexion-table/templates/action-buttons";

const useTableStyles = makeStyles(makeTableStyles(baseTableStyles, updatableCheckBoxStyle, inputFieldStyles));

const List = memo((props) => {

    debug("RENDER", "useForm", () => [" -> List_Of_Rfid_Tag_History_Entries", props]);

    const {path, parentId} = props;

    const {loadingStatus, data} = parentId ? useRfidTagHistoryEntries(parentId) : [];

    const tableClasses = useTableStyles();

    const [state, setState] = useState('');

    const handleFilter = val => setState(val);

    const tableConfig = {
        id: 'tag-history-entries-table',
        nameSpace: 'tagHistoryEntries',
        stickyHeader: true,
        sortable: true,
        styles: tableClasses,
        columns: [
            {
                accessor: 'id',
            },
            {
                accessor: 'tagSerial',
            },
            {
                accessor: 'taskName',
            },
            {
                accessor: 'inventoryItemName',
            },
            {
                accessor: 'facilityUnitName',
            },
            {
                accessor: 'translStatus',
            },
            {
                accessor: 'action',
                renderer: (...args) => customActions(true, false, path[2].url + '/', ...args),
                sortable: false,
            }
        ]
    };

    return (
        <ContentBox path={path}>
            <Section>
                <FilterField handler={handleFilter}/>
                {loadingStatus > 0 ? <LinearProgress/> : null}
                <SimplexionTable tableConfig={tableConfig} data={data} loadingStatus={loadingStatus}
                                 filterValue={state}/>
            </Section>
        </ContentBox>
    );
});

export default List;