export default {
  "itemUser": "Eszköz használója",
  "itemUsers": "Eszköz használók",
  "name": "Név",
  "name-invalid": "A név érvénytelen. Minimum 1 és maximum 60 karakter lehet.",
  "add": "Eszköz használó hozzáadása",
  "back": "Vissza a listához",
  "save": "Eszköz használó mentése",
  "action": "Műveletek",
  "id": "Azonosító",
  "status": "Státusz",
  "translStatus": "Státusz",
  "ACTIVE": "Aktív",
  "INACTIVE": "Inaktív",
  "erpId": "Forrás.NET #",
};