export default {
    "transfer": "Tárolási nyilatkozat",
    "transfers": "Tárolási nyilatkozatok",
    "add": "Tárolási nyilatkozat hozzáadása",
    "back": "Vissza a listához",
    "save": "Tárolási nyilatkozat mentése",
    "action": "Műveletek",
    "id": "Azonosító",
    "createdAt": "Létrehozás dátuma",
    "transferorPrincipal": "Átadó felhasználó",
    "transferorPrincipalName": "Átadó felhasználó",
    "recipientPrincipal": "Átvevő felhasználó",
    "recipientPrincipalName": "Átvevő felhasználó",
    "transferringFacility": "Átadó létesítmény",
    "transferringFacilityName": "Átadó létesítmény",
    "receivingFacility": "Átvevő létesítmény",
    "receivingFacilityName": "Átvevő létesítmény",
    "inventoryItems": "Eszközök Forrás.net azonosítói",
    "inventoryItems-invalid": "Eszközök Forrás.net azonosítóknak fenntartott mező érvénytelen",
    "pdfFile": "Fájlnév",
    "pdfFileName": "Fájlnév",
    "download": "Letöltés",
    "successfulDownload": "A fájl sikeresen le lett töltve",
    "pdfFileIsBeingMade": "A PDF fájl generálása folyamatban.....",

};