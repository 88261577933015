import React from "react";
import Dashboard from "../Views/Dashboard/Dashboard";
import ViewsList from "../Views/ViewsList/ViewsList";
import Facilities from "../Views/Facilities/Facilities";
import Tasks from "../Views/Tasks/Tasks";
import InventoryItems from "../Views/Inventory/InventoryItems/InventoryItems";
import Groups from "../Lib/auth/Views/Groups/Groups";
import DevTool from "../Views/DevTool/DevTool";
import FacilityTypes from "../Views/MasterData/FacilityTypes/FacilityTypes";
import {roles} from "./roles";
import Roles from "../Lib/auth/Views/Roles/Roles";
import Login from "../Views/Account/Login";
import Accounts from "../Views/Account/Accounts";
import MobileClients from "../Views/MasterData/MobileClients/MobileClients";
import RfidTags from "../Views/MasterData/RfidTags/RfidTags";
import ItemOwners from "../Views/MasterData/ItemOwners/ItemOwners";
import Logout from "../Views/Account/Logout";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ItemUsers from "../Views/MasterData/ItemUsers/ItemUsers";
import InventoryAreas from "../Views/Inventory/InventoryAreas/InventoryAreas";
import InventoryItemGroups from "../Views/Inventory/InventoryItemGroups/InventoryItemGroups";
import Procedures from "../Lib/auth/Views/Procedures/Procedures";
import CreationRules from "../Lib/auth/Views/CreationRules/CreationRules";
import ExpiredSession from "../Views/Account/ExpiredSession";
import ResetStart from "../Views/Account/ResetStart";
import NskForrasJobs from "../Views/NskForrasJob/NskForrasJobs";
import SystemSettings from "../Lib/auth/Views/SystemSettings/SystemSettings";
import ResetPassword from "../Views/Account/ResetPassword";
import Transfers from "../Views/Transfers/Transfers";
import NskForrasJobRfids from "../Views/NskForrasJobRfid/NskForrasJobRfids";

const adminRoles = [roles.BUSINESS_ADMIN, roles.SECURITY_OFFICER, roles.DEVELOPER]
const adminRolesWithTaskAdmin = [roles.BUSINESS_ADMIN, roles.SECURITY_OFFICER, roles.DEVELOPER, roles.TASK_ADMIN]

/**
 * Configuration of the navigation tree and site routing, filtered by methods below
 * at the time of initial page load and login/logout.
 */

export const config = [
    {
        path: "/login",
        name: "header:login",
        render: () => <Login/>,
        roles: [roles.ANONYMOUS]
    },
    {
        path: "/dashboard",
        name: "header:dashboard",
        render: () => <Dashboard/>,
        roles: [roles.ANONYMOUS, roles.SITE_MEMBER]
    },
    {
        path: "/facilities*",
        name: "facilities:facilities",
        render: () => <Facilities/>,
        roles: [roles.SITE_MEMBER]
    },
    {
        path: "/items",
        name: "inventoryItems:inventoryItems",
        collapse: true,
        state: "itemsCollapse",
        roles: [roles.SITE_MEMBER],
        views: [
            {
                path: "/items/treatment*",
                name: "inventoryItems:navigationNameOfItem",
                render: () => <InventoryItems/>,
                roles: [roles.SITE_MEMBER]
            },
            // {
            //     path: "/items/found*",
            //     name: "inventoryItems:listOfItemsFound",
            //     render: () => <FoundList/>,
            //     roles: [roles.SITE_MEMBER]
            // },
            {
                path: "/nsk-forras-jobs*",
                name: "nskForrasJobs:nskForrasJobs",
                render: () => <NskForrasJobs/>,
                roles: adminRoles
            },
            {
                path: "/nsk-forras-job-rfids*",
                name: "nskForrasJobRfids:menuName",
                render: () => <NskForrasJobRfids/>,
                roles: adminRoles
            },
            {
                path: "/transfers*",
                name: "transfers:transfers",
                render: () => <Transfers/>,
                roles: adminRoles
            }
        ]
    },
    {
        path: "/areas*",
        name: "inventoryAreas:inventoryAreas",
        render: () => <InventoryAreas/>,
        roles: adminRolesWithTaskAdmin
    },
    {
        path: "/viewslist*",
        name: "viewslist:viewslist",
        render: () => <ViewsList/>,
        roles: adminRolesWithTaskAdmin
    },
    {
        path: "/inventory",
        name: "header:inventory",
        collapse: true,
        state: "inventoryCollapse",
        roles: adminRolesWithTaskAdmin,
        views: [
            {
                path: "/inventory/groups*",
                name: "inventoryItemGroups:inventoryItemGroups",
                render: () => <InventoryItemGroups/>,
                roles: adminRolesWithTaskAdmin
            },
            {
                path: "/inventory/tasks*",
                name: "tasks:tasks",
                render: () => <Tasks/>,
                roles: adminRolesWithTaskAdmin
            }
        ]
    },
    {
        path: "/mdm",
        name: "header:masterDataManagement",
        collapse: true,
        state: "masterDataCollapse",
        roles: adminRoles,
        views: [
            {
                path: "/mdm/facility-types*",
                name: "facilityTypes:facilityTypes",
                render: () => <FacilityTypes/>,
                roles: adminRoles
            },
            {
                path: "/mdm/tags*",
                name: "tags:tags",
                render: () => <RfidTags/>,
                roles: adminRoles
            },
            {
                path: "/mdm/item-owners*",
                name: "itemOwners:itemOwners",
                render: () => <ItemOwners/>,
                roles: adminRoles
            },
            {
                path: "/mdm/item-users*",
                name: "itemUsers:itemUsers",
                render: () => <ItemUsers/>,
                roles: adminRoles
            },
            {
                path: "/mdm/mobile-clients*",
                name: "mobileClients:mobileClients",
                render: () => <MobileClients/>,
                roles: adminRoles
            },
        ]
    },
    {
        path: "/admin",
        name: "header:administration",
        collapse: true,
        state: "adminCollapse",
        roles: adminRoles,
        views: [
            {
                path: "/admin/accounts*",
                name: "accounts:accounts",
                render: () => <Accounts/>,
                roles: adminRoles
            },
            {
                path: "/admin/groups*",
                name: "groups:groups",
                render: () => <Groups/>,
                roles: adminRoles,
                hideMenu: true
            },
            {
                path: "/admin/roles*",
                name: "roles:roles",
                render: () => <Roles/>,
                roles: adminRoles
            },
            {
                path: "/admin/creation-rules*",
                name: "creationRules:creationRules",
                render: () => <CreationRules/>,
                roles: [roles.SECURITY_OFFICER, roles.DEVELOPER]
            },
            {
                path: "/admin/procedures*",
                name: "procedures:procedures",
                render: () => <Procedures/>,
                roles: [roles.SECURITY_OFFICER, roles.DEVELOPER]
            },
            {
                path: "/admin/system-settings*",
                name: "systemSettings:systemSettings",
                render: () => <SystemSettings/>,
                roles: [roles.SECURITY_OFFICER, roles.DEVELOPER]
            },
            {
                path: "/admin/devtool",
                name: "header:devTool",
                render: () => <DevTool/>,
                roles: [roles.DEVELOPER]
            },
        ]
    },
    {
        path: "/accounts/reset",
        name: "accounts:accounts",
        render: () => <ResetStart/>,
        roles: [roles.ANONYMOUS],
        hideMenu: true
    },
    {
        path: "/accounts*",
        name: "accounts:edit",
        render: () => <Accounts/>,
        roles: [roles.SITE_MEMBER],
        hideMenu: true
    },
    {
        path: "/accounts/:id/reset-password/:key",
        name: "accounts:reset-finish-title",
        render: ({id, key}) => <ResetPassword id={id} resetKey={key}/>,
        roles: [roles.ANONYMOUS],
        hideMenu: true
    },
    {
        path: "/renounce",
        name: "header:logout",
        icon: ExitToAppIcon,
        render: () => <Logout/>,
        roles: [roles.SITE_MEMBER]
    },
    {
        path: "/expired-session",
        name: "Expired session",
        render: () => <ExpiredSession/>,
        roles: [roles.ANONYMOUS, roles.SITE_MEMBER],
        hideMenu: true
    }
];
