import {Record} from 'immutable';
import {bool, mixed, number, object, string} from "yup";
import {GeneralStatus} from "./GeneralStatus";

export const AccountRecord = Record({

    id: 0,
    principalId: 0,
    principalName: '',
    credentials: '',
    verification: '',
    emailAddress: '',
    fullName: '',
    displayName: '',
    language: 'hu',
    phoneNumber: '',
    job: '',
    status: 'ACTIVE',
    contactName: '',
    contactPhone: '',
    contactMail: '',
    enabled: true,
    translStatus: ""


});


export const AccountSchema = object().shape({
    id: number().min(0).default(0),
    principalId: number().min(0).default(0),
    principalName: string().required().min(3).max(100).default(""),
    credentials: string().required().min(8).max(50).default(""),
    verification: string().required().min(8).max(50).default(""),
    emailAddress: string().required().email().default(""),
    fullName: string().required().min(5).max(100).default(""),
    displayName: string().min(0).max(100).default(""),
    language: string().min(2).max(2).default("hu"),
    phoneNumber: string().required().min(5).max(30).default(""),
    job: string().required().min(2).max(30).default(""),
    status: mixed().required().oneOf(GeneralStatus).default("ACTIVE"),
    contactName: string().required().min(5).max(100).default(""),
    contactPhone: string().required().min(5).max(30).default(""),
    contactMail: string().required().email().default(""),
    enabled: bool().required().default(true),
    translStatus: string().nullable().default("")

});

export const ResetRecord = Record({
    principalName: '',
    emailAddress:''
});

export const ResetSchema = object().shape({
    principalName: string().required().min(3).max(50),
    emailAddress: string().required().email().default(""),
});

export const PasswordRecord = Record({
    oldPassword: '',
    newPassword: '',
    verification: ''
});

export const PasswordSchema = object().shape({
    oldPassword: string().required().min(8).max(50).default(""),
    newPassword: string().required().min(8).max(50).default(""),
    verification: string().required().min(8).max(50).default("")
});

export const ResetPasswordRecord = Record({
    newPassword: '',
    verification: '',
    key: ''
});

export const ResetPasswordSchema = object().shape({
    newPassword: string().required().min(8).max(50).default(""),
    verification: string().required().min(8).max(50).default(""),
    key: string().default(""),
});