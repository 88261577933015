import {handleTextInputUpdate} from "./text-input";
import {handleCheckBoxUpdate} from "./checkbox-input";

export const updateInputField = (updateApi, validationSchema, e, tableStorage) => {

    const inputElement = e.target;
    const inputElementTag = inputElement.tagName;
    const inputElementType = inputElement.type;

    if (inputElementTag === 'INPUT') {
        switch (inputElementType) {
            case 'text':
                handleTextInputUpdate(updateApi, validationSchema, inputElement, tableStorage);
                break;
            case 'checkbox':
                e.preventDefault();
                handleCheckBoxUpdate(updateApi, inputElement, tableStorage);
                break;
            default:
                break;
        }
    }
};

export const getAncestorRow = element => {
    const parent = element.parentElement;
    const parentTag = parent.tagName;
    if (parentTag === 'TR') return parent.outerHTML;
    return getAncestorRow(parent);
};

export const getRecord = (id, data) => {
    const index = data.findIndex(record => record.id === parseInt(id));
    return {index: index, record: data[index]}
};