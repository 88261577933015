import baseFetch, {useFetchMany} from "../Lib/baseFetch";
import {NskForrasJobRecord} from "../Data/nsk-forras-job";

export function useNskForrasJobs(filter, revision) {
  return useFetchMany('/nsk-forras-jobs', filter, NskForrasJobRecord, revision);
}

export function addNskForrasJob(onSuccess, onError) {
  baseFetch('POST', '/nsk-forras-jobs', {  },
      onSuccess,
      onError
  );
}

export function itemChanges(onSuccess, onError) {
  baseFetch('PATCH', '/nsk-forras-jobs', {  },
      onSuccess,
      onError
  );
}