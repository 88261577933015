import React from "react";
import List from "./List";
import {useRoutes} from "hookrouter";
import Form from "./Form";
import {
    addInventoryItemGroup,
    removeInventoryItemGroup,
    updateInventoryItemGroup,
    useInventoryItemGroup
} from "../../../Api/item-groups";
import Crud from "../../../Lib/Crud";
import {InventoryItemGroupRecord, InventoryItemGroupSchema} from "../../../Data/inventory-item-group";
import InventoryItemGroupEntries from "./InventoryItemGroupEntries/InventoryItemGroupEntries";

export default () => useRoutes(subRoutes);

const subRoutes = {
    "/": () => <Crud config={crudConfig} view="list"/>,
    "/add": () => <Crud config={crudConfig} view="add"/>,
    "/:id/edit": ({id}) => <Crud config={crudConfig} view="edit" id={id}/>,
    "/:id/remove": ({id}) => <Crud config={crudConfig} view="remove" id={id}/>,
    "/:id/entries*": ({id}) => <Crud config={crudConfig} OptComponent={InventoryItemGroupEntries}
                                     optUrlSectionNLabel='entries' view="other" id={id}/>,
};

const crudConfig = {

    compName: 'inventoryItemGroups',
    namespace: 'inventoryItemGroups',
    Record: InventoryItemGroupRecord,
    Schema: InventoryItemGroupSchema,
    api: {
        use: useInventoryItemGroup,
        add: addInventoryItemGroup,
        edit: updateInventoryItemGroup,
        remove: removeInventoryItemGroup
    },
    url: '/inventory/groups',
    labelField: 'name',
    ListComponent: List,
    FormComponent: Form

};
