import React, {memo, useState} from "react";
import ContentBox from "../../../Components/ContentBox/ContentBox";
import {FilterField} from "../../../Components/Table/FilterField";
import {SimplexionTable} from "../../../Lib/simplexion-table/SimplexionTable";
import {makeStyles} from "@material-ui/core";
import {makeTableStyles} from "../../../Components/Table/tableStyles";
import {baseTableStyles} from "../../../Lib/simplexion-table/baseStyle";
import {updatableCheckBoxStyle} from "../../../Lib/simplexion-table/templates/checkbox-input";
import {inputFieldStyles} from "../../../Lib/simplexion-table/templates/text-input";
import {useRfidTagTodayUploads} from "../../../Api/rfid-tags";
import LinearProgress from "@material-ui/core/LinearProgress";
import Section from "../../../Lib/form/Components/Section/Section";
import Button from "@material-ui/core/Button";

const useTableStyles = makeStyles(makeTableStyles(baseTableStyles, updatableCheckBoxStyle, inputFieldStyles));

const List = memo((props) => {

    const {path, parentId} = props;

    const {loadingStatus, data} = useRfidTagTodayUploads(parentId);

    const tableClasses = useTableStyles();

    const [state, setState] = useState('');

    const handleFilter = val => setState(val);

    const tableConfig = {
        id: 'tag-todays-upload-table',
        nameSpace: 'tags',
        stickyHeader: true,
        sortable: true,
        styles: tableClasses,
        columns: [
            {
                accessor: 'tag',
            },
            {
                accessor: 'tagSerial',
            },
            {
                accessor: 'translStatus',
            }
        ]
    };

    return (
        <ContentBox path={path}>
            <Section title="Mai napon feltöltött tagek">
                <FilterField handler={handleFilter}/>
                {loadingStatus > 0 ? <LinearProgress/> : null}
                <SimplexionTable tableConfig={tableConfig} data={data} loadingStatus={loadingStatus}
                                 filterValue={state}/>
            </Section>
            <Button variant="contained" onClick={() => window.history.back()} style={{"marginRight": "20px"}}>Vissza az előző oldalra</Button>
        </ContentBox>
    );
});

export default List;