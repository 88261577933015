import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './Main/i18n';
import App from './Main/App';
import * as serviceWorker from './serviceWorker';
import {get} from './Lib/auth/Api/session';
import SessionData from "./Lib/auth/Records/SessionData";
import Immutable from "immutable";
import {build} from "./Lib/routing";
import {config} from "./Main/routes";
import {roles} from "./Main/roles";

function render(sessionData) {

    const session = sessionData.get('roles') ? (sessionData.get('roles').includes(roles.SITE_MEMBER.toLowerCase()) ? sessionData : new SessionData()) : new SessionData();

    const {navTree, routingMap} = build(config, session.get('roles'), session);

    const authStoreInit = Immutable.Map({
        session: session
    });

    const appStoreInit = Immutable.Map({
        routingMap: routingMap,
        navTree: navTree,
        user: session
    });

    ReactDOM.render(
        <App authStoreInit={authStoreInit} appStoreInit={appStoreInit}/>,
        document.getElementById('root')
    );
}

get(
    sessionData => render(sessionData),
    () => render(new SessionData())
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
