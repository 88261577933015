import {Record} from 'immutable';
import {array, bool, date, mixed, number, object, string} from "yup";
import {GeneralStatus} from "./GeneralStatus";

export const InventoryItemRecord = Record({
    id: 0,
    erpId: "",
    hasAccessories: false,
    erpIdAccessory: "",
    mainPart: 0,
    name: "",
    description: "",
    note: "",
    unit: "db",
    quantity: 1,
    facility: null,
    facilityUnit: 0,
    inventoryArea: 0,
    lastInventoryTime: null,
    lastInventoryAccount: 0,
    status: "ACTIVE",
    tag: 0,
    itemOwner: 0,
    pictures: [],
    itemUser: 0,
    barCodes: "",
    locked: false,
    itemCategory: 0,
    idAndMainPartId: "",
    formattedLastInventoryTime: "",
    translStatus: "",
    catalog: false,
    waste: false

});

export const InventoryItemSchema = object().shape({
    id: number().required().min(0).default(0),
    erpId: string().nullable().default(""),
    hasAccessories: bool().required().default(false),
    erpIdAccessory: string().nullable().default(""),
    mainPart: number().nullable().default(0),//.transform((v, o) => o === null ? 0 : v), //lazy(value => !value ? number().setValue(0) : number().min(1).default(0)),
    name: string().required().min(2).max(120).default(''),
    description: string().nullable().max(10000).default(''),
    unit: string().required().min(1).max(100).default('db'),
    quantity: number().required().default(1),
    facility: number().nullable().default(0),
    facilityUnit: number().required().min(1).default(0),
    inventoryArea: number().nullable().default(0),
    lastInventoryTime: date().nullable(),
    lastInventoryAccount: number().nullable().default(0),
    status: mixed().required().oneOf(GeneralStatus).default("ACTIVE"),
    tag: number().nullable().default(0),
    itemOwner: number().nullable().default(0),
    pictures: array().nullable().test("maxSize3pcs", "There are too many pictures in the list, max. size 3 pcs", function (arr) {
        return Array.isArray(arr) ? Boolean(arr.length <= 3) : true;
    }),
    // pictures: array().nullable().max(3).default([]), it doesn't work every time
    itemUser: number().nullable().default(0),
    barCodes: string().nullable().max(180).default(''),
    locked: bool().required().default(false),
    itemCategory: number().nullable().default(0),
    idAndMainPartId: string().nullable().default(""),
    formattedLastInventoryTime: string().nullable().default(""),
    translStatus: string().nullable().default(""),
    catalog: bool().required().default(false),
    waste: bool().required().default(false)

});