import React from "react";
import List from "./List";
import {useRoutes} from "hookrouter";
import {RfidTagHistoryEntryRecord, RfidTagHistoryEntrySchema} from "../../../../Data/rfid-tag-history-entry";
import Form from "./Form";
import {
    addRfidTagHistoryEntry,
    removeRfidTagHistoryEntry,
    updateRfidTagHistoryEntry,
    useRfidTagHistoryEntry
} from "../../../../Api/rfid-tags";
import Crud from "../../../../Lib/Crud";

export default (props) => useRoutes(subRoutes)(props);

const subRoutes = {
    "/": () => (props) => <Crud config={crudConfig(props)} view="list" {...props}/>,
    "/add": () => (props) => <Crud config={crudConfig(props)} view="add" {...props}/>,
    "/:id/edit": ({id}) => (props) => <Crud config={crudConfig(props)} view="edit" id={id} {...props}/>,
    "/:id/remove": ({id}) => (props) => <Crud config={crudConfig(props)} view="remove" id={id} {...props}/>,
};

const crudConfig = (props) => ({

    compName: 'tagHistoryEntries',
    namespace: 'tagHistoryEntries',
    Record: RfidTagHistoryEntryRecord,
    Schema: RfidTagHistoryEntrySchema,
    api: {
        use: useRfidTagHistoryEntry,
        add: addRfidTagHistoryEntry,
        edit: updateRfidTagHistoryEntry,
        remove: removeRfidTagHistoryEntry
    },
    url: props.path[0].url + "/"  + props.parentId + '/histories',
    labelField: 'serial',
    ListComponent: List,
    FormComponent: Form,
    setOptRecField: "tag",
    parentId: props.parentId,
});


