import {Record} from 'immutable';
import {number, object, string} from "yup";

export const TransferRecord = Record({
    id: 0,
    createdAt: "",
    transferorPrincipal: 0,
    transferorPrincipalName: "",
    recipientPrincipal: 0,
    recipientPrincipalName: "",
    transferringFacility: 0,
    transferringFacilityName: "",
    receivingFacility: 0,
    receivingFacilityName: "",
    inventoryItems: "",
    pdfFile: 0,
    pdfFileName: ""

});

export const TransferSchema = object().shape({
    id: number().required().min(0).default(0),
    createdAt: string().nullable().default(""),
    transferorPrincipal: number().required().min(1).default(0),
    transferorPrincipalName: string().nullable().default(""),
    recipientPrincipal: number().required().min(1).default(0),
    recipientPrincipalName: string().nullable().default(""),
    transferringFacility: number().required().min(1).default(0),
    transferringFacilityName: string().nullable().default(""),
    receivingFacility: number().required().min(1).default(0),
    receivingFacilityName: string().nullable().default(""),
    inventoryItems: string().required().default(""),
    pdfFile: number().nullable(),
    pdfFileName:  string().nullable().default(""),

});