import React from "react";
import List from "./List";
import {useRoutes} from "hookrouter";
import Form from "./Form";
import Crud from "../../Lib/Crud";
import {addTransfer, removeTransfer, useDownloadFile, useTransfer} from "../../Api/transfers";
import {TransferRecord, TransferSchema} from "../../Data/transfer";

export default () => useRoutes(subRoutes);

const subRoutes = {
    "/": () => <Crud config={crudConfig} view="list"/>,
    "/add": () => <Crud config={crudConfig} view="add"/>,
    "/:id/edit": ({id}) => <Crud config={crudConfig} view="edit" id={id}/>,
    "/:id/remove": ({id}) => <Crud config={crudConfig} view="remove" id={id}/>
};

const crudConfig = {
    compName: 'Transfers',
    namespace: 'transfers',
    url: '/transfers',
    labelField: 'pdfFileName',
    Record: TransferRecord,
    Schema: TransferSchema,
    ListComponent: List,
    FormComponent: Form,
    api: {
        use: useTransfer,
        add: addTransfer,
        edit: useDownloadFile,
        remove: removeTransfer
    }
};
