import React from "react";
import {useRoutes} from "hookrouter";
import {addFacilityType, removeFacilityType, updateFacilityType, useFacilityType} from "../../../Api/facility-types";
import List from "./List";
import Form from "./Form";
import Crud from "../../../Lib/Crud";
import {FacilityTypeRecord, FacilityTypeSchema} from "../../../Data/facility-type";

export default () => useRoutes(subRoutes);

const subRoutes = {
  "/": () => <Crud config={crudConfig} view="list"/>,
  "/add": () => <Crud config={crudConfig} view="add"/>,
  "/:id/edit": ({id}) => <Crud config={crudConfig} view="edit" id={id}/>,
  "/:id/remove": ({id}) => <Crud config={crudConfig} view="remove" id={id}/>
};

const crudConfig = {
  compName: 'FacilityTypes',
  namespace: 'facilityTypes',
  url: '/mdm/facility-types',
  labelField: 'name',
  Record: FacilityTypeRecord,
  Schema: FacilityTypeSchema,
  ListComponent: List,
  FormComponent: Form,
  api: {
    use: useFacilityType,
    add: addFacilityType,
    edit: updateFacilityType,
    remove: removeFacilityType
  }
};

