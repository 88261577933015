export const CreationRuleEn = {
    "creationRule": "creationRule",
    "name": "Név",
    "name-invalid": "Érvénytelen mező, legalább 1, legfeljebb 60 karakternek kell lennie.",
    "allowPep": "allowPep ",
    "allowPermission": "allowPermission",
    "allowPermission-invalid": "Érvénytelen mező, legalább 1, legfeljebb 60 karakternek kell lennie.",
    "assignPep": "assignPep",
    "description": "description",
    "description-invalid": "Érvénytelen mező, legalább 1, legfeljebb 10000 karakternek kell lennie.",
};

export const CreationRuleHu = {
    "creationRules": "Szabályok létrehozása",
    "creationRule": "Szabály létrehozása",
    "name": "Név",
    "name-invalid": "Érvénytelen mező, legalább 1, legfeljebb 60 karakternek kell lennie.",
    "allowPep": "Engedélyezett engedélyezési eljárás",
    "allowPermission": "Engedélyezett hozzájárulás",
    "allowPermission-invalid": "Érvénytelen mező, legalább 1, legfeljebb 60 karakternek kell lennie.",
    "assignPep": "Hozzárendelt engedélyezési eljárás",
    "description": "Leírás",
    "description-invalid": "Érvénytelen mező, legalább 1, legfeljebb 10000 karakternek kell lennie.",

    "action": "Művelet",
    "id": "Azonosító",
    "add": "Szabály hozzáadása",
    "back": "Vissza a listához",
    "save": "Szabály mentése",
};