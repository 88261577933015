export default {
    "facilityUnits": "Létesítmény egységek",
    "facilityUnit": "Létesítmény egység",
    "add": "Létesítmény egység hozzáadása",
    "edit": "Létesítmény egység szerkesztése",
    "remove": "Létesítmény egység törlése",
    "name": "Név",
    "name-invalid": "A név érvénytelen. Minimum 2 és maximum 120 karakter lehet.",
    "facility": "Létesítmény",
    "back": "Vissza a listához",
    "save": "Létesítmény egység mentése",
    "action": "Műveletek",
    "id": "Belső #",
    "designation": "Helyiség",
    "designation-invalid": "A mező érvénytelen. Minimum 2 és maximum 120 karakter lehet.",
    "parentUnit": "Létesítmény egység amelyikhez tartozik",
    "tag": "Tag",
    "picture": "Kép",
    "status": "Státusz",
    "translStatus": "Státusz",
    "ACTIVE": "Aktív",
    "INACTIVE": "Inaktív",
    "description": "Leírás",
    "description-invalid": "Érvénytelen mező, legalább 1, legfeljebb 10000 karakternek kell lennie.",
    "erpId": "Forrás.net #",
    "barCode": "Vonalkód",
};