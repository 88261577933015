import React from "react";
import List from "../List";
import {useRoutes} from "hookrouter";
import Form from "./Form";
import Crud from "../../../../Lib/Crud";
import {createOrDeleteEntries, useInventoryItemGroupEntry} from "../../../../Api/item-groups-entries";
import {GroupEntryRecord, GroupEntrySchema} from "../../../../Data/group-entry";


export default (props) => useRoutes(subRoutes)(props);

const subRoutes = {
    "/": () => (props) => <Crud config={crudConfig(props)} view="edit" {...props} id={props.parentId}/>,
};

const crudConfig = (props) => ({

    compName: 'inventoryItemGroupEntries',
    namespace: 'inventoryItemGroupEntries',
    Record: GroupEntryRecord,
    Schema: GroupEntrySchema,
    api: {
        use: useInventoryItemGroupEntry,
        edit: createOrDeleteEntries,
    },
    url: props.path[0].url + "/" + props.parentId + '/entries',
    labelField: 'groupName',
    ListComponent: List,
    FormComponent: Form,
    setOptRecField: "group",
    parentId: props.parentId,
});


