import useForm from "../../../form/useForm";
import React, {memo} from "react";
import ValidatedInput from "../../../form/Components/ValidatedInput/ValidatedInput";
import ValidatedSubmit from "../../../form/Components/ValidatedSubmit/ValidatedSubmit";
import {useTranslation} from "react-i18next";
import ContentBox from "../../../../Components/ContentBox/ContentBox";
import Section from "../../../form/Components/Section/Section";
import {RoleRecord, RoleSchema} from "../../Data/Role";
import {Navigation} from "./Navigation";
import ValidatedCheckbox from "../../../form/Components/ValidatedCheckbox/ValidatedCheckbox";
import debug from "../../../debug";

export default memo(({id, record, path, view, api}) => {
    const {t} = useTranslation("roles");

    const form = useForm({
        recordType: RoleRecord,
        recordSchema: RoleSchema,
        namespace: 'roles',
        record: record
    });

    debug("RENDER", "useForm", () => [" -> Form_Of_Roles", id, record, path, view]);


    return (
        <ContentBox path={path} form={form}>

            {view === 'add' ? null : <Navigation parentId={id} parentName={record.name}/>}

            <Section title={t("role")}>
                <ValidatedInput name={"name"}/>
                <ValidatedInput disabled name="grantCount"/>
                <ValidatedInput name="description" multiline={true}/>
                <ValidatedCheckbox name={"locked"} label={t("locked")}/>
                <ValidatedSubmit view={view} api={api}/>
            </Section>
        </ContentBox>
    );
})