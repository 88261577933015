import {Record} from 'immutable';

export const ListOfItemsRecord = Record({
    id: 0,
    erpIds: "",
    hasAccessories: false,
    name: "",
    facilityUnitErpIds: "",
    facilityUnit: "",
    hasRFIDTag: false,
    tagSerial: "",
    translStatus: ""
});
