import React, {memo, useState} from "react";
import ContentBox from "../../../Components/ContentBox/ContentBox";
import {makeStyles} from "@material-ui/core";
import {makeTableStyles} from "../../../Components/Table/tableStyles";
import {baseTableStyles} from "../../../Lib/simplexion-table/baseStyle";
import {updatableCheckBoxStyle} from "../../../Lib/simplexion-table/templates/checkbox-input";
import {inputFieldStyles} from "../../../Lib/simplexion-table/templates/text-input";
import debug from "../../../Lib/debug";
import {FilterField} from "../../../Components/Table/FilterField";
import {SimplexionTable} from "../../../Lib/simplexion-table/SimplexionTable";
import {useForeignItems, useInventoryTickets} from "../../../Api/inventory-tickets";
import LinearProgress from "@material-ui/core/LinearProgress";
import Section from "../../../Lib/form/Components/Section/Section";


const useTableStyles = makeStyles(makeTableStyles(baseTableStyles, updatableCheckBoxStyle, inputFieldStyles));

const List = memo((props) => {

    debug("RENDER", "useForm", () => [" -> List_Of_Inventory_Tickets", props]);

    const {path, parentId} = props;

    const isFoundTable = path && path[2] && path[2].url.includes("foreign-items")

    const {loadingStatus, data} = parentId ? isFoundTable ? useForeignItems(parentId) : useInventoryTickets(parentId) : [];

    const tableClasses = useTableStyles();

    const [state, setState] = useState('');

    let columns = []

    if (isFoundTable) {
        columns.push(
            {accessor: 'inventoryItemErpId'},
            {accessor: 'itemName'},
            {accessor: 'registeredFacilityErpId'},
            {accessor: 'registeredFacilityName'},
            {accessor: 'foundFacilityErpId'},
            {accessor: 'foundFacilityName'},
            {accessor: 'foundFacilityUnitErpId'},
            {accessor: 'foundFacilityUnitName'},
            {accessor: 'tagSerial'},
            {accessor: 'itemStatus'}
        )
    } else {
        columns.push({accessor: 'erpId'},
            {accessor: 'inventoryItemErpId'},
            {accessor: 'itemName'},
            {accessor: 'registeredFacilityUnitErpId'},
            {accessor: 'registeredFacilityUnitName'},
            {accessor: 'foundFacilityUnitErpId'},
            {accessor: 'foundFacilityUnitName'},
            {accessor: 'registeredQuantity'},
            {accessor: 'foundQuantity'},
            {accessor: 'translRegisteredStatus'},
            {accessor: 'translFoundStatus'}
        )
    }

    const handleFilter = val => setState(val);

    const tableConfig = {
        id: 'inventory-tickets-table',
        nameSpace: 'inventoryTickets',
        stickyHeader: true,
        sortable: true,
        styles: tableClasses,
        columns: columns,
    };

    return (
        <ContentBox path={path}>
            <Section>
                <FilterField handler={handleFilter}/>
                {loadingStatus > 0 ? <LinearProgress/> : null}
                <SimplexionTable tableConfig={tableConfig} data={data} loadingStatus={loadingStatus}
                                 filterValue={state}/>
            </Section>
        </ContentBox>
    );
});

export default List;