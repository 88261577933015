import React from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../Lib/form/useForm";
import {ViewsListRecord, ViewsListSchema} from "../../Data/viewslist";
import ContentBox from "../../Components/ContentBox/ContentBox";
import Section from "../../Lib/form/Components/Section/Section";
import ValidatedSelect from "../../Lib/form/Components/ValidatedSelect/ValidatedSelect";
import {getViewsList, useViewsList} from "../../Api/viewslist";
import {useSnackbar} from "notistack";
import {throwsExceptionMessage} from "../../Api/catchException";
import Button from "../../Lib/material-dashboard/components/CustomButtons/Button";


function ViewsList() {

    const {enqueueSnackbar} = useSnackbar();
    const {t} = useTranslation("viewslist");

    const form = useForm({
        recordType: ViewsListRecord,
        recordSchema: ViewsListSchema,
        namespace: 'viewslist',
        record: new ViewsListRecord()
    });


    const send = () => {

        const record = new ViewsListRecord(form.fieldValues.current);
        if (record.id <= 0) return
        const success = (response) => {
            if (response.status === 200) {
                window.location.href = response.url;
                enqueueSnackbar(t('successfulDownload'), {variant: 'success', autoHideDuration: 2500});
            } else error(response.status)
        };
        const error = (error) => {
            enqueueSnackbar(t('catchException:' + throwsExceptionMessage(error)), {
                variant: 'error',
                autoHideDuration: 2500
            });
        }
        getViewsList(record, success, error);
    };

    return (
        <ContentBox form={form}>
            <Section title={t('viewslist')}>
                <ValidatedSelect name="id" recordFieldName={"displayName"} api={useViewsList}/>
            </Section>
            <Button color={"warning"} onClick={send}> {t('viewslistSubmit')} </Button>
        </ContentBox>

    );

}

export default ViewsList;