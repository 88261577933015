import baseFetch, {useFetchMany, useFetchOne} from "../Lib/baseFetch";
import {ItemUserRecord, ItemUserSchema} from "../Data/item-user";


export function useItemUsers(filter) {
    return useFetchMany('/item-users', undefined, ItemUserRecord);
}

export function useItemUser(id) {
    return useFetchOne('/item-users', id, ItemUserRecord, ItemUserSchema);
}

export function addItemUser(itemUser, onSuccess, onError) {
    baseFetch('POST', '/item-users', itemUser,
        data => {
            if (onSuccess) onSuccess(new ItemUserRecord(data))
        },
        onError
    );
}

export function updateItemUser(itemUser, onSuccess, onError) {
    baseFetch('PATCH', '/item-users/' + itemUser.get('id'), itemUser, onSuccess, onError);
}

export function removeItemUser(id, onSuccess, onError) {
    baseFetch('DELETE', '/item-users/' + id, undefined, onSuccess, onError);
}
