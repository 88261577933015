export default {
    "systemSetting": "Rendszer paraméter",
    "systemSettings": "Rendszer paraméterek",
    "add": "Rendszer paraméter hozzáadása",
    "edit": "Rendszer paraméter szerkesztése",
    "remove": "Rendszer paraméter törlése",
    "name": "Név",
    "name-invalid": "Érvénytelen mező, legalább 1, legfeljebb 100 karakternek kell lennie.",
    "value": "Érték",
    "value-invalid": "Érvénytelen mező, legalább 1, legfeljebb 1000 karakternek kell lennie.",
    "description": "Leírás",
    "description-invalid": "Érvénytelen mező, legfeljebb 10000 karakter lehet.",
    "back": "Vissza a listához",
    "save": "Rendszer paraméter mentése",
    "action": "Műveletek",
    "id": "Azonosító",
};