import React, {useContext, useEffect} from "react";
import customSelectStyle from "../../material-dashboard/assets/jss/customSelectStyle";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import GridItem from "../../material-dashboard/components/Grid/GridItem";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import InlineLoader from "../../../Components/Loader/InlineLoader"
import {actions} from "../Data/Actions";
import {SearchContext} from "../Context/SearchContext";
import {makeStyles} from "@material-ui/styles";
import transl from "../../../Views/Inventory/translFunc";
import {useFormField} from "../../form/useFormField";

const useStyles = makeStyles(customSelectStyle);

function OptionSelect(props) {

    const classes = useStyles();
    const {name, option, urlData} = props;
    const ff = useFormField(props, classes);

    const {dispatch} = useContext(SearchContext);
    const data = option.get('fetchFunction')()['data'];
    
    useEffect(() => dispatch(actions.loadOptionsSuccessSelect(name, data, urlData)), [data, dispatch, urlData, name]);

    let statusIcon = option.get('loaded') ? ArrowDropDownIcon : InlineLoader;
    let items = [];

    if (option.get('loaded') && option.get('data').size) {

        option.get('data').forEach(item => items.push(

            <MenuItem
                key={item.get('conditionId')}
                id={item.get('conditionId')}
                value={item.get('conditionId')}
                component='li'
                classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                button={false}
            >
                {item.name}
            </MenuItem>)
        );
    }

    const handleSelect = (event) => {
        const selectedIds = event.target.value;
        const currentTargetId = event.currentTarget.id;

        if (selectedIds.includes(currentTargetId)) {
            dispatch(actions.addConditionSelect(name, currentTargetId))
        } else {
            dispatch(actions.removeCondition(name, currentTargetId));
        }
    };

    return (
        <GridItem xs={12} sm={12} md={3} lg={3}>
            <FormControl
                component='div'
                fullWidth
                className={classes.selectFormControl}
                disabled={!option.get('loaded') || items.length === 0}
            >
                <InputLabel
                    htmlFor={"multiple-select_" + name}
                    className={classes.selectLabel}
                >
                    {transl(name)}
                </InputLabel>
                <Select
                    multiple
                    value={option.get('selected') ? option.selected : []}
                    onChange={(event) => {ff.onChange([parseInt(event.currentTarget.id.match(/\d+/g)[0], 10)]); handleSelect(event)}}
                    MenuProps={{className: classes.selectMenu}}
                    classes={{select: classes.select}}
                    IconComponent={statusIcon}
                    inputProps={{name: "multipleSelect_" + name, id: "multiple-select_" + name,}}
                >
                    {items.map(i => i)}
                </Select>
            </FormControl>
        </GridItem>
    );
}

export default OptionSelect;