import {Record} from 'immutable';
import {array, number, object, string} from "yup";

export const GroupEntryRecord = Record({
    id: 0,
    group: 0,
    inventoryAreas: [],
    inventoryItems: [],
    facilityUnits: [],
    itemUsers: [],
    groupName: ""
});


export const GroupEntrySchema = object().shape({
    id: number().required().min(0).default(0),
    group: number().required().min(1).default(0),
    inventoryAreas: array().of(number().integer()).default([]),
    inventoryItems: array().of(number().integer()).default([]),
    facilityUnits: array().of(number().integer()).default([]),
    itemUsers: array().of(number().integer()).default([]),
    groupName: string().nullable().default("")
});

