import baseFetch, {useFetchMany, useFetchOne} from "../Lib/baseFetch";
import {TransferRecord, TransferSchema} from "../Data/transfer";

export function useTransfers(filter) {
    return useFetchMany('/transfers', undefined, TransferRecord);
}

export function useTransfer(id) {
    return useFetchOne('/transfers', id, TransferRecord, TransferSchema);
}

export function addTransfer(transfer, onSuccess, onError) {
    baseFetch('POST', '/transfers', transfer,
        data => {
            if (onSuccess) onSuccess(new TransferRecord(data))
        },
        onError
    );
}

export function updateTransfer(transfer, onSuccess, onError) {
    baseFetch('PATCH', '/transfers/' + transfer.get('id'), transfer, onSuccess, onError);
}

export function removeTransfer(id, onSuccess, onError) {
    baseFetch('DELETE', '/transfers/' + id, undefined, onSuccess, onError);
}

export function useDownloadFile(transfer, onSuccess, onError) {
    baseFetch('GET', '/attachments/' + transfer.get('pdfFile'), undefined,
        data => {
            if (onSuccess) onSuccess(data)
        },
        onError
    );
}