import React, {memo, useRef} from 'react';
import {FilePond, registerPlugin} from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import baseFetch from "../../../baseFetch";
import {useFormField} from "../../useFormField";
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import {useTranslation} from "react-i18next";

/**
 * apiPath = /images
 */

registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType, FilePondPluginImagePreview);

const ValidatedFileUploader = memo((props) => {

    const {apiPath, multiple, sizeValidation, maxFileSize, maxTotalFileSize, acceptedFileTypes, onSuccess, onAbort, imagePreviewProps} = props;

    let allowImagePreview;
    let imagePreviewHeight;

    if (imagePreviewProps) {
        allowImagePreview = imagePreviewProps.allowImagePreview;
        imagePreviewHeight = imagePreviewProps.imagePreviewHeight;
    }

    const ff = useFormField(props);

    const {t} = useTranslation("fileUpload");

    const ref = useRef(null);

    let fileIds = ff.value ? (Array.isArray(ff.value) ? ff.value : [ff.value]) : [];

    const serverConfig = {
        process: (fieldName, file, metadata, load, error, progress, abort) => {
            // fieldName is the name of the input field
            // file is the actual file object to send
            const formData = new FormData();
            formData.append(fieldName, file, file.name);

            const request = new XMLHttpRequest();
            request.open('POST', '/api/1.0.0' + apiPath);

            // Should call the progress method to update the progress to 100% before calling load
            // Setting computable to false switches the loading indicator to infinite mode
            request.upload.onprogress = (e) => {
                progress(e.lengthComputable, e.loaded, e.total);
            };

            // Should call the load method when done and pass the returned server file id
            // this server file id is then used later on when reverting or restoring a file
            // so your server knows which file to return without exposing that info to the client
            request.onload = function () {
                if (request.status >= 200 && request.status < 300) {
                    // the load method accepts either a string (id) or an object
                    if (multiple) fileIds.push(request.response);
                    else fileIds = [request.response];

                    ff.onChange(multiple ? fileIds : fileIds[0]);

                    if (onSuccess) onSuccess(request.response, file);

                    load(request.responseText);
                } else {
                    if (request.status === 409 || request.status === 410 || request.status === 417) {
                        window.location = "/expired-session";
                    }
                    error();
                }
            };

            request.send(formData);

            // Should expose an abort method so the request can be cancelled
            return {
                abort: () => {
                    // This function is entered if the user has tapped the cancel button
                    request.abort();

                    // Let FilePond know the request has been cancelled
                    abort();
                }
            };
        },
        revert: (uniqueFileId, load, error) => {
            // don't
            baseFetch('DELETE', apiPath + "/" + uniqueFileId, undefined, () => load());

            let filtered = fileIds.filter(id => id !== uniqueFileId);

            multiple && Array.isArray(ff) ? ff.onChange(filtered) : ff.onChange(null)

            if (onAbort) onAbort(uniqueFileId)
        }
    };

    return (
        <FilePond name="file"
                  ref={ref}
                  allowMultiple={multiple}

                  server={serverConfig}

                  allowFileSizeValidation={sizeValidation}
                  acceptedFileTypes={acceptedFileTypes ? acceptedFileTypes : ['*/*']}
                  maxFileSize={maxFileSize || null}
                  maxTotalFileSize={maxTotalFileSize || null}

                  allowFileTypeValidation={Boolean(acceptedFileTypes)}


                  allowImagePreview={allowImagePreview}
                  imagePreviewHeight={imagePreviewHeight}
                  labelIdle={t("labelIdle")}
                  labelInvalidField={t("labelInvalidField")}
                  labelFileLoading={t("labelFileLoading")}
                  labelFileProcessing={t("labelFileProcessing")}
                  labelFileSizeNotAvailable={t('labelFileSizeNotAvailable')}
                  labelFileTypeNotAllowed={t("labelFileTypeNotAllowed")}
                  labelFileWaitingForSize={t('labelFileWaitingForSize')}
                  labelMaxFileSizeExceeded={t("labelMaxFileSizeExceeded")}
                  labelMaxFileSize={t("labelMaxFileSize")}
                  labelFileLoadError={t("labelFileLoadError")}
                  labelFileProcessingComplete={t("labelFileProcessingComplete")}
                  labelFileProcessingAborted={t("labelFileProcessingAborted")}
                  labelFileProcessingError={t("labelFileProcessingError")}
                  labelFileProcessingRevertError={t('labelFileProcessingRevertError')}
                  abelFileProcessingRevertError={t("abelFileProcessingRevertError")}
                  labelFileRemoveError={t("labelFileRemoveError")}
                  labelTapToRetry={t("labelTapToRetry")}
                  labelTapToCancel={t("labelTapToCancel")}
                  labelTapToUndo={t("labelTapToUndo")}
                  fileValidateTypeLabelExpectedTypes={acceptedFileTypes ? ((acceptedFileTypes.toString() === 'image/*') ?
                      t("imageFileValidateTypeLabelExpectedTypes") : (t("fileValidateTypeLabelExpectedTypes") + acceptedFileTypes)) : t("labelFileTypeNotAllowed")}
                  labelButtonRemoveItem={t('labelButtonRemoveItem')}
                  labelButtonAbortItemLoad={t('labelButtonAbortItemLoad')}
                  labelButtonRetryItemLoad={t('labelButtonRetryItemLoad')}
                  labelButtonAbortItemProcessing={t('labelButtonAbortItemProcessing')}
                  labelButtonUndoItemProcessing={t('labelButtonUndoItemProcessing')}
                  labelButtonRetryItemProcessing={t('labelButtonRetryItemProcessing')}
                  labelButtonProcessItem={t('labelButtonProcessItem')}

        />
    );
});

export default ValidatedFileUploader;