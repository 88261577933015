import React from "react";
import {useTranslation} from "react-i18next";
import accountStyle from "./accountStyle";
import {makeStyles} from "@material-ui/styles";
import Section from "../../Lib/form/Components/Section/Section";
import ContentBox from "../../Components/ContentBox/ContentBox";
import {LoginButton} from "./Accounts";

const useStyles = makeStyles(accountStyle);

const ExpiredSession = () => {

    const {t} = useTranslation("accounts");

    const classes = useStyles();

    return (
        <ContentBox title={t("expired-session")}>
            <Section title={t("expired-session")}>
                <br/>
                {t('expired-session-explanation')}
                <LoginButton t={t} classes={classes}/>
            </Section>
        </ContentBox>
    )
};

export default ExpiredSession;