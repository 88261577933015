import React, {useContext, useEffect, useState} from "react";
import SearchBuilder from "../../Lib/SearchBuilder/Components/SearchBuilder"
import Box from "@material-ui/core/Box";
import Button from "../../Lib/material-dashboard/components/CustomButtons/Button";
import {Collapse} from "@material-ui/core";
import OptionContainer from "../../Lib/SearchBuilder/Components/OptionContainer";
import ConditionContainer from "../../Lib/SearchBuilder/Components/ConditionContainer";
import {actions} from "../../Lib/SearchBuilder/Data/Actions";
import {SearchContext} from "../../Lib/SearchBuilder/Context/SearchContext";
import CardBody from "../../Lib/material-dashboard/components/Card/CardBody";
import Card from "../../Lib/material-dashboard/components/Card/Card";
import ListOfItems from "./InventoryItems/ListOfItems";
import transl from "./translFunc";

function ResetButton(props) {
    const {form, optionData, setFilter} = props;
    const {dispatch} = useContext(SearchContext);

    const resetConditions = () => {
        // FIXME don't use fieldValues directly
        dispatch(actions.resetConditions());
        let formValues = form.fieldValues.current;
        optionData.forEach(o => {
            formValues[o.name] = []
        });
        setFilter(null)
    };

    return (
        <Button color='danger' onClick={() => resetConditions()}>
            {transl('resetConditions')}
        </Button>
    );
}

function SearchButton(props) {

    const {store} = useContext(SearchContext);
    const {makeUrl} = props;

    return (
        <Button style={{backgroundColor: "#0c427d"}} onClick={() => makeUrl(store.get('conditions'))}>
            {transl('startSearch')}
        </Button>
    );
}

function Search(props) {

    const {optionData, area, group, form, api} = props;
    const [isCollapsed, handleCollapse] = useState(false);
    let dynamicObject = {};
    const [filter, setFilter] = useState(null);
    let initDataFromUrl = {};

    optionData.forEach(o => {
        dynamicObject[o.name] = [];
        // FIXME don't use fieldValues directly
        let optionValues = form.fieldValues.current[o.name];
        Object.assign(initDataFromUrl, {[o.name]: optionValues});
    });

    useEffect(() => {
        setFilter(dynamicObject);
    }, []);

    const getUrl = conditions => {
        conditions.forEach(c => {
            filter[c.get('optionName')].push(c.get('id'));
        });
        group ? (filter["inventoryItemGroup"] = group) : (filter["inventoryArea"] = area);

        handleCollapse(true)
    };

    const removeAllParameters = () => setFilter(dynamicObject)

    return (
        <Box>
            <SearchBuilder optionData={optionData}>
                <Box>
                    <Button style={{backgroundColor: "#0c427d"}} size='sm' onClick={() => handleCollapse(false)}
                            disabled={!isCollapsed}>
                        {transl('searchConditions')}
                    </Button>
                </Box>
                <Collapse in={!isCollapsed}>
                    <OptionContainer initDataFromUrl={initDataFromUrl}/>
                    <ConditionContainer form={form}/>
                    <Card>
                        <CardBody>
                            <Box display='flex' justifyContent='space-between'>
                                <SearchButton makeUrl={getUrl}/>
                                <ResetButton form={form} setFilter={removeAllParameters} optionData={optionData}/>
                            </Box>
                        </CardBody>
                    </Card>
                </Collapse>
            </SearchBuilder>
            {isCollapsed ? <ListOfItems api={api} filter={filter}/> : null}
        </Box>
    );
}

export default Search;