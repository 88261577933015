export default {
  "inventoryItemGroups": "Eszköz csoportok",
  "inventoryItemGroup": "Eszköz csoport",
  "add": "Eszköz csoport hozzáadása",
  "edit": "Eszköz csoport szerkesztése",
  "remove": "Eszköz csoport törlése",
  "name" : "Név",
  "name-invalid": "Érvénytelen mező, legalább 2, legfeljebb 60 karakternek kell lennie.",
  "query": "Lekérdezés",
  "query-invalid": "Érvénytelen mező, legalább 1, legfeljebb 10000 karakternek kell lennie.",
  "back": "Vissza a listához",
  "save": "Eszköz csoport mentése",
  "action": "Műveletek",
  "entries": "Bejegyzések",
};