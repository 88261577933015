import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../../Lib/form/useForm";
import ContentBox from "../../../Components/ContentBox/ContentBox";
import Section from "../../../Lib/form/Components/Section/Section";
import ValidatedInput from "../../../Lib/form/Components/ValidatedInput/ValidatedInput";
import ValidatedSubmit from "../../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import debug from "../../../Lib/debug"
import {MobileClientRecord, MobileClientSchema} from "../../../Data/mobile-client";
import ValidatedSelect from "../../../Lib/form/Components/ValidatedSelect/ValidatedSelect";
import {usePrincipals} from "../../../Lib/auth/Api/principals";


export default memo(({id, record, path, view, api, hasAdminRoles}) => {
    const {t} = useTranslation('mobileClients');

    const form = useForm({
        recordType: MobileClientRecord,
        recordSchema: MobileClientSchema,
        namespace: 'mobileClients',
        record: record
    });

    debug("RENDER", "useForm", () => [" -> Form_Of_Mobile_Clients", id, record, path, view]);

    return (
        <ContentBox path={path} form={form}>

            <Section title={t('mobileClient')}>
                <ValidatedInput name="serial" disabled={!hasAdminRoles}/>
                <ValidatedSelect name="user" recordFieldName={"fullName"} api={usePrincipals} disabled={!hasAdminRoles}/>
            </Section>

            <ValidatedSubmit view={view} api={api} hideSave={!hasAdminRoles}/>

        </ContentBox>
    );
})