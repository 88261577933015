import React from "react";
import Datetime from "react-datetime";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import {makeStyles} from "@material-ui/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import classNames from "classnames";

import customInputStyle from "../../../material-dashboard/assets/jss/components/customInputStyle.jsx";
import {useFormField} from "../../useFormField";
import Moment from "moment";
import hu from "moment/locale/hu";  // That's what Moment.local needs
// import hu from "moment/locale/hu";  // That's what Moment.local needs


const useStyles = makeStyles(customInputStyle);

// TODO not sure about this timeAsInt, think about it

const getValue = (formValue, timeAsInt) => {
    if (isNaN(formValue) || !timeAsInt) {
        return formValue == null ? '' : formValue;
    } else {
        return Moment({hour: Math.floor(formValue / 100), minute: formValue % 100})
    }
};

const setValue = (ff, value, timeAsInt, setClock) => {
    if (value === '') {
        ff.onChange(null);
        return;
    }

    if (!value.isValid || !value.isValid()) {
        ff.onChange(Moment(value).utc());
        return;
    }

    if (timeAsInt) {
        ff.onChange(value.hours() * 100 + value.minutes())
    } else {
        if (setClock) ff.onChange(Moment(value).utc());
        else ff.onChange(Moment(value).set("hours", 12).utc());         //TODO FIXME -> the default time cannot be 00:00:00
    }
};

function ValidatedDateTime(props) {
    const {disabled, date, time, timeAsInt, timeDependency} = props;
    const classes = useStyles();

    const ff = useFormField(props, classes);

    const periodOfDate = function (current) {
        const start_date = (timeDependency && timeDependency.min && ff.values.current[timeDependency.min]) ? Moment(ff.values.current[timeDependency.min]) : null;
        const end_date = (timeDependency && timeDependency.max && ff.values.current[timeDependency.max]) ? Moment(ff.values.current[timeDependency.max]) : null;
        if (timeDependency && timeDependency.min && start_date) {
            return start_date < current;
        } else if (timeDependency && timeDependency.max && end_date) {
            return end_date > current;
        } else return true
    };

    const labelClasses = classNames(classes.labelRoot, {
        [classes.labelRootError]: ff.status === "invalid",
        [classes.labelRootSuccess]: ff.status === "valid"
    });

    const datetimeClasses = classNames({
        [classes.input]: true,
        [classes.whiteInput]: false,
        [classes.disabled]: disabled,
        [classes.underlineError]: ff.status === "invalid",
        [classes.underlineSuccess]: ff.status === "valid",
        [classes.underline]: true,
        [classes.whiteUnderline]: false
    });

    const formControlClasses = classes.formControl;

    let helpTextClasses = classNames({
        [classes.labelRootError]: ff.status === "invalid",
        [classes.labelRootSuccess]: ff.status === "valid"
    });

    const value = getValue(ff.value, timeAsInt);

    return (
        <FormControl className={formControlClasses} fullWidth>
            <InputLabel
                className={labelClasses}
                shrink={value !== undefined && value !== ''}
                htmlFor={ff.id}>
                {ff.labelText}
            </InputLabel>
            <Datetime
                id={ff.id}
                classNames={datetimeClasses}
                inputProps={{
                    className: "form-control " + classes.dateTimeInput,
                    disabled: ff.disabled,
                }}
                dateFormat={date === false ? false : "YYYY-MM-DD"}
                timeFormat={time === false ? false : "HH:mm"}
                locale={Moment.locale()}
                value={value ? Moment(value, ((!date ? "" : 'YYYY-MM-DD') + (!time ? '' : " HH:mm"))) : ""}
                closeOnSelect={true}
                onChange={(value) => setValue(ff, value, timeAsInt, time)}
                isValidDate={periodOfDate}
            />
            {ff.helperText ?
                <FormHelperText id={ff.id + "-text"} className={helpTextClasses}>
                    {ff.helperText}
                </FormHelperText>
                : null}
        </FormControl>
    );
}

export default ValidatedDateTime;