export default {
  "itemCategory": "Eszköz kategoria",
  "itemCategories": "Eszköz kategoriák",
  "name": "Név",
  "manufactures": "Gyártó",
  "manufactures-invalid": "Érvénytelen mező, legfeljebb 60 karakter lehet.",
  "productNumber": "Termék neve/azonosító",
  "productNumber-invalid": "Érvénytelen mező, legfeljebb 60 karakter lehet",
  "picture": "Kép",
  "name-invalid": "A név érvénytelen. Minimum 1 és maximum 60 karakter lehet.",
  "add": "Eszköz kategoria hozzáadása",
  "back": "Vissza a listához",
  "save": "Eszköz kategoria mentése",
  "action": "Műveletek",
  "id": "Azonosító",
};