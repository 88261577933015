export const handleResize = (id) => {

    const wrapper = document.getElementById(`main-wrapper-${id}`);

    const table = document.getElementById(id);

    const tableHead = table.getElementsByTagName('thead')[0];
    const tableHeaderCells = tableHead.getElementsByTagName('th');

    const stickyWrapper = document.getElementById(`sticky-header-${id}`);
    const stickyHeaderCells = stickyWrapper.getElementsByTagName('th');

    if (!stickyHeaderCells.length) return;

    for (let i = 0; i < tableHeaderCells.length; i++) {

        const th = tableHeaderCells[i];
        const stickyTh = stickyHeaderCells[i];

        const thWidth = th.getBoundingClientRect().width;
        const thHeight = th.getBoundingClientRect().height;

        stickyTh.setAttribute('style', `width: ${thWidth}px; height: ${thHeight}px`);
    }

    if (table.scrollHeight > wrapper.clientHeight) {
        stickyWrapper.style.width = `calc(100% - 17px)`;
    } else {
        stickyWrapper.style.width  = `100%`
    }
};