import {Record} from 'immutable';
import {mixed, number, object, string} from "yup";
import {RfidTagStatus} from "./RfidTagHistoryType";

export const RfidTagRecord = Record({
    id: 0,
    serial: '',
    status: "UNASSIGNED",
    translStatus: "",

});

export const RfidTagSchema = object().shape({
    id: number().required().min(0).default(0),
    serial: string().min(1).max(100).default(''),
    status: mixed().required().oneOf(RfidTagStatus).default("UNASSIGNED"),
    translStatus: string().nullable().default("")

});


export const UploadRfidTagsRecord = Record({
    serialNumberArray: '',
});

export const UploadRfidTagsSchema = object().shape({
    serialNumberArray: string().min(1).default(''),
});

