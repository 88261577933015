import React from 'react';
import {Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";

const styles = theme => ({
  root: {
    margin: theme.spacing(1),
    paddingTop: theme.spacing(1.5),
  },
  margin: {
    margin: theme.spacing(1),
  },
  padding: {
    padding: theme.spacing(1),
  },
  panel: {
    height: '75vh'
  }
});

class DevTool extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      urlInput: '',
      dataInput: '',
      methodType: undefined,
      fetchOutput: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.createLogOnError = this.createLogOnError.bind(this);
    this.createLogOnError = this.createLogOnError.bind(this);
    this.createLogOnSuccess = this.createLogOnSuccess.bind(this);
  }


  render() {

    const {classes} = this.props;

    return (
        <Paper className={classes.root}>
          <Grid container>
            <Grid item xs>
              <form noValidate={true} autoComplete="off">
                <TextField
                    required
                    name='urlInput'
                    fullWidth
                    label='URL'
                    onChange={(event) => this.handleChange(event)}
                    value={this.state.urlInput}
                    variant='outlined'
                    className={classes.padding}/>
                <FormControl component='fieldset' required>
                  <RadioGroup
                      row
                      onChange={(event) => this.handleChange(event)}
                      name='methodType'
                      value={this.state.methodType}>
                    <FormControlLabel value='POST' control={<Radio/>} label='POST' labelPlacement="top"/>
                    <FormControlLabel value='GET' control={<Radio/>} label='GET' labelPlacement="top"/>
                    <FormControlLabel value='PATCH' control={<Radio/>} label='PATCH' labelPlacement="top"/>
                    <FormControlLabel value='DELETE' control={<Radio/>} label='DELETE' labelPlacement="top"/>
                  </RadioGroup>
                </FormControl>
                <TextField
                    name='dataInput'
                    fullWidth
                    label='Data goes here'
                    rows={5}
                    multiline={true}
                    value={this.state.dataInput}
                    onChange={(event) => this.handleChange(event)}
                    variant='outlined'
                    className={classes.padding}
                />
                <Button href='#' type='submit' onClick={this.handleSubmit} className={classes.margin}>Submit</Button>
              </form>
            </Grid>
            <Grid item xs component='pre'>
              <form>
                <TextField
                    component='pre'
                    name='fetchOutput'
                    fullWidth
                    disabled
                    rows={15}
                    multiline={true}
                    value={this.state.fetchOutput}
                    variant='outlined'
                    className={classes.padding}/>
              </form>
            </Grid>
          </Grid>
        </Paper>
    )
  }


  createLogOnSuccess(data, name) {
    let msg = 'POST ' + name + ' success: ' + data.GeneralStatus + ' ' + data.statusText + '\nurl: ' + data.url + '\n';
    this.setState((state) => ({fetchOutput: state.fetchOutput + msg + '\n'}));
  }


  createLogOnError(error, name) {
    let msg = 'POST ' + name + ' error: ' + error.GeneralStatus + ' ' + error.statusText + '\nurl: ' + error.url + '\n';
    this.setState((state) => ({fetchOutput: state.fetchOutput + msg + '\n'}));
  }


  handleChange(event) {

    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    if (value === "") {
      value = undefined;
    }

    this.setState({
      saved: false,
      [name]: value
    });
  }


  handleSubmit(event) {
    event.preventDefault();
    if (this.state.methodType === 'GET') {
      this.fetchData('')
    } else if (this.state.methodType) {
      let jsonArray = '[\n' + this.state.dataInput + '\n]';
      JSON.parse(jsonArray).forEach((d) => this.fetchData(d))
    }
  }


  fetchData(data) {

    fetch('/api/1.0.0' + this.state.urlInput, {
      method: this.state.methodType,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: this.state.methodType !== 'GET' ? JSON.stringify(data) : null
    }).then((response) => {

      let msg;
      if (response.status >= 200 && response.status < 300) {
        msg = this.state.methodType + ' ' + data.name + ' success: ' + response.status + ' ' + response.statusText + '\nurl: ' + response.url + '\n';
      } else {
        msg = this.state.methodType + ' ' + data.name + ' error: ' + response.status + ' ' + response.statusText + '\nurl: ' + response.url + '\n';
      }

      if (response.ok) {

        if (this.state.methodType !== 'GET') {

          this.setState((state) => ({
            fetchOutput: state.fetchOutput + msg + '\n'
          }));

        } else {

          response.json().then(json => {

            this.setState((state) => ({
              fetchOutput: state.fetchOutput + msg + '\n',
              dataInput: JSON.stringify(json, null, 2)
            }));

          }).catch(error => {

            console.log(error);

            this.setState((state) => ({
              fetchOutput: state.fetchOutput + msg + '\nERROR: check console log\n\n',
            }));

          });

        }
      } else {
          console.log(response);

          this.setState((state) => ({
            fetchOutput: state.fetchOutput + msg + '\nERROR: check console log\n\n',
          }));
        }
      }
    );
  }
}

export default withStyles(styles)(DevTool);