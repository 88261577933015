import baseFetch, {useFetchMany, useFetchOne} from "../Lib/baseFetch";
import {InventoryItemHistoryEntryRecord, InventoryItemHistoryEntrySchema} from "../Data/inventory-item-histrory-entry";

export function useInventoryItemHistoryEntries(parentId) {
    return useFetchMany('/inventory-items/' + parentId + "/histories", undefined, InventoryItemHistoryEntryRecord);
}

export function useInventoryItemHistoryEntry(parentId, id) {
    return useFetchOne('/inventory-items/' + parentId + "/histories", id, InventoryItemHistoryEntryRecord, InventoryItemHistoryEntrySchema);
}

export function addInventoryItemHistoryEntry(inventoryItemHistoryEntry, onSuccess, onError) {
    baseFetch('POST', '/inventory-items/' + inventoryItemHistoryEntry.get('item') + '/histories', inventoryItemHistoryEntry,
        data => {
            if (onSuccess) onSuccess(new InventoryItemHistoryEntryRecord(data))
        },
        onError
    );
}

export function updateInventoryItemHistoryEntry(inventoryItemHistoryEntry, onSuccess, onError) {
    baseFetch('PATCH', '/inventory-items/' + inventoryItemHistoryEntry.get('item') + '/histories/' + inventoryItemHistoryEntry.get('id'), inventoryItemHistoryEntry, onSuccess, onError);
}

export function removeInventoryItemHistoryEntry(inventoryItemHistoryEntry, onSuccess, onError) {
    baseFetch('DELETE', '/inventory-items/' + inventoryItemHistoryEntry.get('item') + '/histories/' + inventoryItemHistoryEntry.get('id'), undefined, onSuccess, onError);
}