import React, {useContext} from "react";
import searchBuilderStyle from "./styles/searchBuilderStyle"
import Card from "../../material-dashboard/components/Card/Card"
import CardBody from "../../material-dashboard/components/Card/CardBody";
import {SearchContext} from "../Context/SearchContext";
import ConditionChip from "./ConditionChip";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(searchBuilderStyle);

function ConditionContainer(props) {
    const {form} = props;
    const classes = useStyles();

    const {store} = useContext(SearchContext);
    const conditionStore = store.get('conditions');
    let chips = [];

    conditionStore.forEach((value, key) => {
        const chip = <ConditionChip key={key} chipId={key} chip={value} form={form}/>;
        chips.push(chip);
    });

    return (
        <Card className={classes.sbContainer}>
            <CardBody className={classes.conditionsCardBody}>
                {chips.map(c => c)}
            </CardBody>
        </Card>
    )
}

export default ConditionContainer;