export const PrincipalHu = {
    "principal": "Felhasználó",
    "principals": "Felhasználók",
    "id": "Id",
    "principalName": "Felhasználó név",
    "emailAddress": "Email cím",
    "fullName": "Teljes név",
    "displayName": "Megjelenítendő név",
    "accountId": "Fiók Id",
    "action": "Művelet",
    "back": "Vissza a listához",
    "save": "Felhasználó mentése",
    "add": "Felhasználó hozzáadása",
};

export const PrincipalEn = {
    "principal": "Principal",
    "principals": "Principals",
    "id": "Id",
    "principalName": "Principal name",
    "emailAddress": "Email address",
    "fullName": "Full name",
    "displayName": "Display name",
    "accountId": "Account Id",
};