import React, {useContext, useEffect} from "react";
import {renounce} from "../../Lib/auth/Api/session";
import {AppContext} from "../../Main/AppContext";
import Actions from "../../Lib/auth/AuthActions";
import {navigate} from "hookrouter";
import {AuthContext} from "../../Lib/auth/AuthContext";
import {isLoggedIn} from "../../Lib/auth/auth";

const Logout = () => {

    const authContext = useContext(AuthContext);
    const loggedIn = isLoggedIn(authContext);

    const appContext = useContext(AppContext);
    const dispatch = appContext.get('dispatch');

    useEffect(() => {
        if (loggedIn) {
            renounce(
                () => {
                    dispatch(Actions.sessionRemove());
                    navigate('/dashboard');
                },
                () => {
                    dispatch(Actions.sessionRemove());
                    navigate('/dashboard');
                });
        }
        // TODO check if this suppress is OK
    }, [loggedIn]);

    if (!loggedIn) navigate('/dashboard');

    return <div>&nbsp;</div>;

};

export default Logout;