import {Record} from 'immutable';
import {number, object, string} from "yup";


export const PrincipalRecord = Record({
    id: 0,
    principalName: '',
    emailAddress: '',
    accountId: 0,
    fullName: '',
    displayName: '',
    language: '',
});

export const PrincipalSchema = object().shape({
    id: number().required().min(0).default(0),
    principalName: string().required().min(1).max(100).default(''),
    emailAddress: string().email().required().default(''),
    accountId: number().min(1).default(0),
    fullName: string().required().min(5).max(100).default(''),
    displayName: string().min(2).max(100).default(''),
    language: string().min(2).max(2).default('en')
});

