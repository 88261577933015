import baseFetch, {useFetchMany, useFetchOne} from "../Lib/baseFetch";
import {FacilityUnitRecord, FacilityUnitSchema} from "../Data/facility-unit";
import {InventoryItemRecord} from "../Data/inventory-item";


export function useFacilityUnits(filter) {
        return useFetchMany('/facility-units', filter, FacilityUnitRecord);
}

export function useFacilityUnit(parentId, id) {
    return useFetchOne('/facilities/' + parentId + '/facility-units', id, FacilityUnitRecord, FacilityUnitSchema);
}

export function addFacilityUnit(facilityUnit, onSuccess, onError) {
    baseFetch('POST', '/facilities/' + facilityUnit.get("facility") + '/facility-units', facilityUnit,
        data => {
            if (onSuccess) onSuccess(new FacilityUnitRecord(data))
        },
        onError
    );
}

export function updateFacilityUnit(facilityUnit, onSuccess, onError) {
    baseFetch('PATCH', '/facilities/' + facilityUnit.get("facility") + '/facility-units/' + facilityUnit.get('id'), facilityUnit, onSuccess, onError);
}

export function removeFacilityUnit(facilityUnit, onSuccess, onError) {
    baseFetch('DELETE', '/facility-units/' + facilityUnit.get('id'), undefined, onSuccess, onError);
}

export function searchForItemsInTheFacilityUnit(filter) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
        return useFetchMany('/inventory-items-of-facility-unit', filter, InventoryItemRecord);
}
