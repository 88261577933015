import React, {memo, useState} from "react";
import {A, usePath} from "hookrouter";
import cx from "classnames";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import sidebarStyle from "../../Lib/material-dashboard/assets/jss/components/sidebarStyle.jsx";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/styles";
import List from "@material-ui/core/List";
import Collapse from "@material-ui/core/Collapse";

const useStyles = makeStyles(sidebarStyle);

const SidebarItem = memo((props) => {

    const classes = useStyles();
    const {t} = useTranslation();
    const color = "blue";

    const {route, mini, handleDrawerToggle} = props;

    const [collapsed, setCollapsed] = useState(false);
    const path = usePath();
    const active = path.indexOf(route.path) > -1 ? "active" : "";

    const innerNavLinkClasses = cx(classes.collapseItemLink, {[" " + classes[color]]: active});
    const navLinkClasses = cx(classes.itemLink, {[" " + classes[color]]: active});
    const itemText = cx(classes.itemText, {[classes.itemTextMini]: mini});
    const collapseItemText = cx(classes.collapseItemText, {[classes.collapseItemTextMini]: mini});

    let icon;

    if (route.icon !== undefined) {
        if (typeof route.icon === "string") {
            icon = <Icon className={classes.itemIcon}>{route.icon}</Icon>;
        } else {
            icon = <route.icon className={classes.itemIcon}/>
        }
    } else {
        icon = <span className={classes.collapseItemMini}>{t(route.mini)}</span>
    }


    return (
        <ListItem key={route.name}
                  style={{paddingLeft: route.level ? (route.level * 3) + "0px" : "0"}}
                  className={cx(
                      {[classes.item]: route.icon !== undefined},
                      {[classes.collapseItem]: route.icon === undefined}
                  )}
        >
            <A
                href={route.views ? '#' : route.path}
                className={cx(
                    {[navLinkClasses]: route.icon !== undefined},
                    {[innerNavLinkClasses]: route.icon === undefined}
                )}
                onClick={!route.views ? handleDrawerToggle : e => {
                    e.preventDefault();
                    setCollapsed(!collapsed);
                }}
            >
                {icon}
                <ListItemText
                    primary={t(route.name)}
                    secondary={!route.views ? undefined :
                        <b className={cx(classes.caret, {[classes.caretActive]: collapsed})}/>
                    }
                    disableTypography={true}
                    className={cx(
                        {[itemText]: route.icon !== undefined},
                        {[collapseItemText]: route.icon === undefined}
                    )}
                />
            </A>

            {!route.views ? null :
                <Collapse in={collapsed} unmountOnExit>
                    <List className={classes.list + " " + classes.collapseList}>
                        {route.views.map(r => <SidebarItem key={r.name} route={r} mini={mini}
                                                           handleDrawerToggle={handleDrawerToggle}/>)}
                    </List>
                </Collapse>
            }
        </ListItem>
    );
});

export default SidebarItem;
