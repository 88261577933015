import React, {memo, useEffect, useState} from "react";
import ContentBox from "../../../Components/ContentBox/ContentBox";
import {useTranslation} from "react-i18next";
import Section from "../../../Lib/form/Components/Section/Section";
import Button from "@material-ui/core/Button";
import baseFetch from "../../../Lib/baseFetch";
import {A, navigate} from "hookrouter";


export const ReaderUploadRFIDTags = memo(() => {

    const {t} = useTranslation('tags');

    const paths = [{label: t("tags"), url: "/mdm/tags"}, {label: t("uploadTag"), url: "/mdm/tags/readerUpload"}]

    const urlNs = paths[0].url + "/";

    const [tagCount, setTagCount] = useState(0)
    const [foundTags, setFoundTags] = useState({found: [], created: []})
    const [importedTags, setImportedTags] = useState({found: [], created: undefined})
    const [showList, setShowList] = useState(false)

    function readerTagCount() {
        baseFetch('GET', '/rfid-tags/readerTagCount', undefined,
            data => {
                setTagCount(data.count)
            }
        );
    }

    function resetTagReader() {
        baseFetch('POST', '/rfid-tags/resetTagReader', undefined,
            data => {
                setTagCount(data.count)
                setFoundTags({found: [], created: []})
                setImportedTags({found: [], created: undefined})
                setShowList(false)
            }
        );
    }

    function importTags() {
        baseFetch('POST', '/rfid-tags/importTagsFromReader', undefined,
            data => {
                setImportedTags(data)
            }
        );
    }

    function showTags() {
        baseFetch('GET', '/rfid-tags/showTagsFromReader', undefined,
            data => {
                setFoundTags(data)
                setShowList(true)
            }
        );
    }

    useEffect(
        () => {
            let timer1 = setInterval(() => readerTagCount(), 1000);
            return () => {
                clearInterval(timer1);
            };
        },
        []
    );

    return (
        <ContentBox path={paths}>

            <Section title={t('readerUploadTagInDb')} htmlNotes={t("noteOnReaderUploadForm")}>
                <div style={{"marginTop": "20px"}}>
                    Látott tagek száma: {tagCount} db
                </div>
            </Section>

            <div style={{"marginBottom": "32px", "marginTop" : "20px"}}>
                <Button variant="contained" onClick={() => window.history.back()} style={{"marginRight": "20px"}}>Vissza az előző oldalra</Button>
                <Button variant="contained" color="primary" onClick={() => importTags()} style={{"marginRight": "20px"}}>Tagek feltöltése</Button>
                <A href={urlNs + "TodayUploads"}><Button variant="contained">Mai napon feltöltött tagek</Button></A>
            </div>

            {importedTags.created !== undefined ?
                <Section title={t('readerUploadTagResult')} htmlNotes={t("noteOnReaderUploadTagResult")}>
                    <div style={{"marginTop": "20px"}}>Olvasó által látott tagek: {importedTags.found.length} db</div>
                    <div style={{"marginTop": "20px"}}>A látott tagekből feltöltött (új): {importedTags.created.length} db</div>
                    <div style={{"marginTop": "20px"}}>
                        {importedTags.created.map((serial) => <div>{serial}</div>)}
                    </div>
                </Section> : ""
            }

            <div style={{"marginTop": "32px"}}>
                <Button variant="contained" color="primary" onClick={() => showTags()} style={{"marginRight": "20px"}}>Listázás</Button>
                <Button variant="contained" color="primary" onClick={() => resetTagReader()}>Alapállapotba állítás</Button>
            </div>

            {showList ?
                <Section title={t('readerUploadTagList')} notes={t("noteOnReaderUploadTagList")}>
                    <div style={{"marginTop": "20px"}}>
                        {foundTags.found.map((serial) => <div>{serial}</div>)}
                    </div>
                </Section>
            : null }
        </ContentBox>
    )

});
