import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";
import useForm from "../../Lib/form/useForm";
import {AccountRecord, AccountSchema} from "../../Data/account";
import Button from "../../Lib/material-kit/components/CustomButtons/Button";
import {addAccount, updateAccount} from "../../Api/accounts";
import ContentBox from "../../Components/ContentBox/ContentBox";
import Grid from "@material-ui/core/Grid";
import Section from "../../Lib/form/Components/Section/Section";
import ValidatedInput from "../../Lib/form/Components/ValidatedInput/ValidatedInput";
import ValidatedSubmit from "../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import {A, navigate} from "hookrouter";
import {closeSnack} from "../../Main/util";
import {Lock} from "@material-ui/icons";
import {makeStyles} from "@material-ui/styles";
import accountStyle from "./accountStyle";
import debug from "../../Lib/debug";
import ValidatedRadioGroup from "../../Lib/form/Components/ValidatedRadio/ValidatedRadioGroup";
import ValidatedRadio from "../../Lib/form/Components/ValidatedRadio/ValidatedRadio";
import {GeneralStatus} from "../../Data/GeneralStatus";
import ValidatedCheckbox from "../../Lib/form/Components/ValidatedCheckbox/ValidatedCheckbox";


const useStyles = makeStyles(accountStyle);

/**
 * Displays a list of invalid fields to let the user know why the
 * save button is unavailable.
 */
const ValidationInfo = ({statuses}) => {
    const {t} = useTranslation("accounts");

    const invalids = Object
        .keys(statuses)
        .filter(fieldName => statuses[fieldName] !== "valid")
        .map(fieldName => t(fieldName));

    if (invalids.length === 0) return null;

    return <Section title={t('invalid-fields')} notes={t('invalid-fields-explanation')}>
        <br/>
        {invalids.join(', ')}
    </Section>
};

/**
 * Checks if the password verification is the same as password.
 */
export const passwordValidator = (fieldName, fieldValue, fieldValues) => {
    return fieldValues["credentials"] === fieldValue;
};

export const Form = memo(({view, id, path, record, hasAdminRoles}) => {

    const {t} = useTranslation("accounts");
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const classes = useStyles();

    const form = useForm({
        recordType: AccountRecord,
        recordSchema: AccountSchema,
        namespace: 'accounts',
        record
    });

    debug("RENDER", "useForm", () => [" -> Form_Of_Accounts", view, id, path, record]);

    // FIXME don't use fieldValues and validationStatuses directly
    if (view === "edit") {
        form.fieldValues.current.credentials = "123456789";
        form.fieldValues.current.verification = "123456789";
        form.validationStatuses.current.credentials = "valid";
        form.validationStatuses.current.verification = "valid";
    }

    /**
     * wraps addAccount:
     *   - sets default fields not displayed in the form
     *   - catches 406 error
     */
    const add = (record, onSuccess, onError) => {
        record = record.set('displayName', record.get('fullName'));
        record = record.set('language', 'hu');

        const catch406 = (error) => {
            if (error && error.status === 406) {
                enqueueSnackbar(
                    t('existing-name'),
                    {variant: 'warning', persist: true, action: (key) => closeSnack(t, closeSnackbar, key)}
                );
                onError(error, true);
            } else {
                onError(error);
            }
        };

        const success = () => {
            // eslint-disable-next-line no-unused-expressions
            hasAdminRoles ? navigate("./") : null
            enqueueSnackbar(t('add-success'), {variant: 'success'});
        };

        addAccount(record, success, catch406);
    };

    const update = (record, onSuccess, onError) => {
        record = record.set('displayName', record.get('fullName'));

        const catch406 = (error) => {
            if (error && error.status === 406) {
                enqueueSnackbar(
                    t('existing-name'),
                    {variant: 'warning', persist: true, action: (key) => closeSnack(t, closeSnackbar, key)}
                );
                onError(error, true);
            } else {
                onError(error);
            }
        };

        const success = () => {
            // eslint-disable-next-line no-unused-expressions
            hasAdminRoles ? navigate("../") : null
            enqueueSnackbar(t('general:save.success'), {variant: 'success'});
        };

        updateAccount(record, success, catch406);
    };

    return (
        <ContentBox path={path} form={form} disabled={!hasAdminRoles}>
            <Grid container direction="row" justify="center">
                <Grid item xs={8}>
                    <Section title={t(id ? "edit" : 'add')}>
                        <Grid container direction="row">
                            <Grid item xs style={{padding: "1rem"}}>
                                <ValidatedInput name='principalName' autoComplete="username"/>
                                <ValidatedInput name='fullName' autoComplete="name"/>
                                {view === "add" ?
                                    <React.Fragment>
                                        <ValidatedInput name="credentials" password autoComplete="new-password"/>
                                        <ValidatedInput name="verification" password validator={passwordValidator}/>
                                    </React.Fragment>
                                    :
                                    <div className={classes.buttonContainer}>
                                        <Button className={classes.smallButton} onClick={() => navigate("./password")}>
                                            <Lock className={classes.icons}/><span
                                            style={{marginLeft: 4}}>{t('password-change')}</span>
                                        </Button>
                                    </div>
                                }
                                <ValidatedInput name='emailAddress' autoComplete="email"/>
                                <ValidatedInput name='phoneNumber'/>

                            </Grid>
                            <Grid item xs style={{padding: "1rem"}}>
                                <ValidatedInput name='job'/>
                                <ValidatedInput name='contactName'/>
                                <ValidatedInput name='contactPhone'/>
                                <ValidatedInput name='contactMail' autoComplete="email"/>
                                <div hidden={!hasAdminRoles}>
                                    <ValidatedRadioGroup name="status">
                                        <Grid container>
                                            {GeneralStatus.map(a => <ValidatedRadio name="status" value={a}
                                                                                    key={a}/>)}
                                        </Grid>
                                    </ValidatedRadioGroup>
                                    {view !== "add" ?
                                        <Grid container direction="row" justify="center" alignItems="center">
                                            <Grid item xs>
                                                <A href={"/admin/accounts/" + id + "/facility-accesses"}>
                                                    <Button className={classes.smallButton}>{t('facility-accesses')}
                                                    </Button>
                                                </A>
                                            </Grid>
                                            <Grid item xs style={{paddingLeft: '0.5rem'}}>
                                                <ValidatedCheckbox name={"enabled"} label={t("enabled")}/>
                                            </Grid>
                                        </Grid>
                                        : null
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    </Section>
                </Grid>

                <Grid item xs={8}>
                    <ValidatedSubmit api={view === "add" ? add : update} validationInfo={ValidationInfo}
                                     view={view} hideCancel={!hasAdminRoles}/>
                </Grid>
            </Grid>

        </ContentBox>
    );
});