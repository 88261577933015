export default {
  "inventoryItemGroupEntries": "Eszköz csoport bejegyzések",
  "inventoryItemGroupEntry": "Eszköz csoport bejegyzés",
  "add": "Eszköz csoport bejegyzés hozzáadása",
  "edit": "Eszköz csoport bejegyzés szerkesztése",
  "remove": "Eszköz csoport bejegyzés törlése",
  "group" : "Eszköz csoport",
  "inventoryArea": "Leltár körzet",
  "inventoryItem": "Eszköz",
  "facilityUnit": "Létesítmény egység",
  "itemUser": "Használó",
  "back": "Vissza a listához",
  "save": "Eszköz csoport bejegyzés mentése",
  "action": "Műveletek",
  "id": "Azonosító",
  "itemUserName": "Felhasználó",
  "unitName": "Létesítmény egység",
  "itemName": "Eszköz",
  "areaName": "Leltárkörzet",

};