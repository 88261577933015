import baseFetch, {useFetchMany, useFetchOne} from "../Lib/baseFetch";
import {TaskRecord, TaskSchema} from "../Data/task";

export function useTasks(filter) {
    return useFetchMany('/tasks', undefined, TaskRecord);
}

export function useTask(id) {
    return useFetchOne('/tasks', id, TaskRecord, TaskSchema);
}

export function addTask(task, onSuccess, onError) {
    baseFetch('POST', '/tasks', task,
        data => {
            if (onSuccess) onSuccess(new TaskRecord(data))
        },
        onError
    );
}

export function updateTask(task, onSuccess, onError) {
    baseFetch('PATCH', '/tasks/' + task.get('id'), task, onSuccess, onError);
}

export function removeTask(task, onSuccess, onError) {
    baseFetch('DELETE', '/tasks/' + task.get('id'), undefined, onSuccess, onError);
}

export function useGenerateNDownloadPdf(task, onSuccess, onError) {
    baseFetch('GET', '/tasks/' + task.get('id') + '/generate-pdf', undefined,
        data => {
            if (onSuccess) onSuccess(data)
        },
        onError
    );
}
