export const MembershipHu = {
    "membership": "Tagság",
    "memberships": "Tagságok",
    "id": "Id",
    "groupId": "Csoport Id",
    "principalId": "Felhasználó",
    "principalName": "Felhasználó neve",
    "fullName": "Teljes név",
    "emailAddress": "Email cím",
    "action": "Műveletek",
    "back": "Vissza a listához",
    "save": "Tagság mentése",
    "add": "Tagság hozzáadása",
    "delete": "Tagság megszüntetése",
};

export const MembershipEn = {
    "membership": "Membership",
    "memberships": "Memberships",
    "id": "Id",
    "groupId": "Group Id",
    "principalId": "Principal Id",
    "principalName": "Principal name",
    "fullName": "Full name",
    "emailAddress": "Email address",
    "action": "Action",
    "actions": "Actions",
};