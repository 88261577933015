import {Record} from 'immutable';
import {array, number, object, string} from "yup";

export const AreaEntryRecord = Record({
    id: 0,
    inventoryArea: 0,
    inventoryItems: [],
    facilityUnits: [],
    itemUsers: [],
    inventoryAreaName: ""
});


export const AreaEntrySchema = object().shape({
    id: number().required().min(0).default(0),
    inventoryArea: number().required().min(1).default(0),
    inventoryItems: array().of(number().integer()).default([]),
    facilityUnits: array().of(number().integer()).default([]),
    itemUsers: array().of(number().integer()).default([]),
    inventoryAreaName: string().nullable().default("")
});

