import React from "react";
import {useRoutes} from 'hookrouter';
import List from "./List";
import {addCreationRule, removeCreationRule, updateCreationRule} from "../../../../Lib/auth/Api/creationRules";
import Crud from "../../../Crud";
import {CreationRuleRecord, CreationRuleSchema} from "../../Data/CreationRule";
import {useCreationRule} from "../../Api/creationRules";
import Form from "./Form";

export default () => useRoutes(subRoutes);

const subRoutes = {
    "/": () => <Crud config={crudConfig} view="list"/>,
    "/add": () => <Crud config={crudConfig} view="add"/>,
    "/:id/edit": ({id}) => <Crud config={crudConfig} view="edit" id={id}/>,
    "/:id/remove": ({id}) => <Crud config={crudConfig} view="remove" id={id}/>
};

const crudConfig = {
    compName: 'CreationRules',
    namespace: 'creationRules',
    url: '/admin/creation-rules',
    labelField: 'creationRules',
    Record: CreationRuleRecord,
    Schema: CreationRuleSchema,
    ListComponent: List,
    FormComponent: Form,
    api: {
        use: useCreationRule,
        add: addCreationRule,
        edit: updateCreationRule,
        remove: removeCreationRule,
    }
};


