import React from "react";
// @material-ui/core components
import Box from "@material-ui/core/Box";
import {Add, NavigateNext} from "@material-ui/icons";
import {A} from "hookrouter";
import Button from "../../Lib/material-kit/components/CustomButtons/Button";
import {makeStyles} from "@material-ui/styles";
import {contentBoxStyle} from "./contentBoxStyle";
import FormProvider from "../../Lib/form/Components/FormProvider/FormProvider";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

export const useStyles = makeStyles(contentBoxStyle);

function ContentBox(props) {

    const {path, children, add, form, disabled} = props;

    const classes = useStyles();

    const breadcrumbs = path ? path.map(p => <A href={disabled ? "" : p.url} key={p.url}
                                                className={classes.title}>{p.label}</A>) : [];

    return (
        <Box className={classes.main}>
            {path ?
                <div className={classes.titleContainer}>
                    <Breadcrumbs className={classes.title} separator={<NavigateNext fontSize="small"/>}
                                 aria-label="breadcrumb">{breadcrumbs}</Breadcrumbs>
                    {add ?
                        <A href={add} className={classes.addBtn}>
                            <Button justIcon color={"success"} size={"sm"}><Add/></Button>
                        </A>
                        : null
                    }
                </div>
                : null
            }
            <div>
                {form ?
                    <FormProvider form={form} formClassName={classes.form}>
                        {children}
                    </FormProvider>
                    : children
                }
            </div>
        </Box>
    );
}

export default ContentBox;
